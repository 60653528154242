import { motion } from 'framer-motion'
import React from 'react'

function Syllabus() {
  return (
    <motion.div className='md:px-14 px-7 py-6 font-change'
    initial={{ y:-40,opacity:0 }}
          animate={{ y:0, opacity:1}}
          transition={{ ease: "easeIn", duration: 1 }}>

 <h2 className='text-3xl md:text-3xl text-center font-bold my-4'>Aptitude and Skill Development Syllabus for Engineering Students</h2>
 <br />
 <p className='text-lg text-justify'>This syllabus is designed to provide a framework for engineering students to develop both their technical and non-technical skills. It can be adapted to fit the specific needs of a particular engineering discipline or program.</p>
        <br />

        <p className='font-semibold text-2xl underline underline-offset-8'>Foundational Aptitudes</p>
        <br />
        <br />
        <p className='font-semibold text-lg '>Mathematics </p>
        <ul className='list-disc  md:text-lg  text-base text-justify font-semibold text-black/70'>
        <br />
        <li>Strengthen core mathematical concepts like calculus, linear algebra, and differential equations.</li>
        <br />
        <li>Develop problem-solving skills through application of mathematics to engineering problems.</li>
        <br />
        </ul>

        <p className='font-semibold text-lg '>Science </p>
        <ul className='list-disc  md:text-lg  text-base text-justify font-semibold text-black/70'>
        <br />
        <li>Review foundational science concepts in physics, chemistry, and biology relevant to the chosen engineering field.</li>
        <br />
        <li>Understand how scientific principles underpin engineering design.</li>
        <br />
        </ul>

        <p className='font-semibold text-lg '>Computer Science  </p>
        <ul className='list-disc  md:text-lg  text-base text-justify font-semibold text-black/70'>
        <br />
        <li>Gain proficiency in programming languages like Python, C++, or Java.</li>
        <br />
        <li>Learn basic algorithms and data structures commonly used in engineering applications.</li>
        <br />
        </ul>
<br />
        <p className='font-semibold text-2xl underline underline-offset-8'>Technical Skills</p>
        <br />
        <br />
        <p className='font-semibold text-lg '>Engineering Design</p>
        <ul className='list-disc  md:text-lg  text-base text-justify font-semibold text-black/70'>
        <br />
        <li>Develop the ability to define problems, brainstorm solutions, and design effective engineering systems.</li>
        <br />
        <li>Learn computer-aided design (CAD) software and other relevant engineering design tools.</li>
        <br />
        </ul>

        <br />
        <p className='font-semibold text-lg '>Specific Engineering Skills</p>
        <ul className='list-disc  md:text-lg  text-base text-justify font-semibold text-black/70'>
        <br />
        <li>Depending on the engineering discipline, this could include courses in areas like mechanics, thermodynamics, circuits, materials science, etc.</li>
        <br />
        <li>Develop an understanding of the core principles and practices specific to the chosen engineering field.</li>
        <br />
        </ul>

        <br />
        <p className='font-semibold text-2xl underline underline-offset-8'>Soft Skills</p>
        <br />
        <br />
        <p className='font-semibold text-lg '>Communication</p>
        <ul className='list-disc  md:text-lg  text-base text-justify font-semibold text-black/70'>
        <br />
        <li>Hone written and verbal communication skills to effectively present technical information.</li>
        <br />
        <li>Learn to write technical reports, proposals, and emails.</li>
        <br />        
        <li>Develop presentation skills for clear and concise communication of ideas.</li>
        <br />
        </ul> 

        <p className='font-semibold text-lg '>Teamwork</p>
        <ul className='list-disc  md:text-lg  text-base text-justify font-semibold text-black/70'>
        <br />
        <li>Foster collaboration and teamwork skills through group projects and activities.</li>
        <br />
        <li>Learn how to effectively work within a team environment to achieve common goals.</li>
        <br />        
        </ul> 

        <p className='font-semibold text-lg '>Problem-Solving</p>
        <ul className='list-disc  md:text-lg  text-base text-justify font-semibold text-black/70'>
        <br />
        <li>Develop critical thinking and analytical skills to solve complex engineering problems.</li>
        <br />
        <li>Learn to approach problems creatively and systematically.</li>
        <br />        
        </ul> 

        <p className='font-semibold text-lg '>Lifelong Learning</p>
        <ul className='list-disc  md:text-lg  text-base text-justify font-semibold text-black/70'>
        <br />
        <li>Instill the importance of continuous learning and adaptation in the ever-evolving engineering field.</li>
        <br />
        <li>Develop strategies for staying updated with new technologies and advancements in the chosen engineering discipline.</li>
        <br />        
        </ul> 

        <p className='font-semibold text-lg '>Lifelong Learning</p>
        <ul className='list-disc  md:text-lg  text-base text-justify font-semibold text-black/70'>
        <br />
        <li>Instill the importance of continuous learning and adaptation in the ever-evolving engineering field.</li>
        <br />
        <li>Develop strategies for staying updated with new technologies and advancements in the chosen engineering discipline.</li>
        <br />        
        </ul> 

        <br />
        <p className='font-semibold text-2xl underline underline-offset-8'>Additional Considerations</p>
        <br />
        <br />
        <p className='font-semibold text-lg '>Industry Exposure</p>
        <ul className='list-disc  md:text-lg  text-base text-justify font-semibold text-black/70'>
        <br />
        <li>Include guest lectures, internships, or industry visits to provide students with real-world engineering experience.</li>
        <br />
        </ul> 

        <p className='font-semibold text-lg '>Professional Development</p>
        <ul className='list-disc  md:text-lg  text-base text-justify font-semibold text-black/70'>
        <br />
        <li>Offer workshops or seminars on topics like resume writing, interview skills, and professional ethics.</li>
        <br />
        </ul> 

        <p className='font-semibold text-lg '>Elective Courses</p>
        <ul className='list-disc  md:text-lg  text-base text-justify font-semibold text-black/70'>
        <br />
        <li>Allow students to explore areas of interest and develop specialized skills through elective courses.</li>
        <br />
        </ul>
        
<br />
        <p className='font-semibold text-2xl underline underline-offset-8'>Assessment</p>
        <br />
        <ul className='list-disc  md:text-lg  text-base text-justify font-semibold text-black/70'>
        <br />
        <li>A combination of coursework, projects, presentations, exams, and self-reflection can be used to assess student progress in developing their aptitudes and skills.</li>
        <br />
        </ul> 

         <br />
         <br />
        <p className='md:text-lg text-base text-justify text-red-700'>Note: This is a general framework, and the specific content and emphasis of the syllabus will vary depending on the engineering program, student needs, and industry demands.</p>
    </motion.div>
  )
}

export default Syllabus