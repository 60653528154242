import { motion } from "framer-motion";
import "./Table.css";

const data = [
  {
    id: 1,
    name: "Chitrangi Deepak Mestry",
    class: "BE COMPUTER",
    company: "JAVA BY KIRAN",
    role: "Software Trainee",
    salary: "3.5 LPA",
    photo: "/assets/T&p/records/chitrangi.png",
  },
  {
    id: 1,
    name: "Shivram Kishor Kinalekar",
    class: "BE COMPUTER",
    company: "JAVA BY KIRAN",
    role: "Software Trainee",
    salary: "3.5 LPA",
    photo: "/assets/T&p/records/shivram.png",
  },
  {
    id: 1,
    name: "Mandar Pratap Naik",
    class: "DIPLOMA MECHANICAL",
    company: "JOHN DEERE",
    role: "Technical Trainee",
    salary: "2 LPA",
    photo: "/assets/T&p/records/mandar.png",
  },
  {
    id: 1,
    name: "Shreyas Purushottam Kadam",
    class: "DIPLOMA MECHANICAL",
    company: "JOHN DEERE",
    role: "Technical Trainee",
    salary: "2 LPA",
    photo: "/assets/T&p/records/shreyas.png",
  },
  {
    id: 1,
    name: "Sumit Bhisaji Parab",
    class: "DIPLOMA MECHANICAL",
    company: "JOHN DEERE",
    role: "Technical Trainee",
    salary: "2 LPA",
    photo: "/assets/T&p/records/sumit.png",
  },
  {
    id: 1,
    name: "Ramchandra Prashant Sawant",
    class: "DIPLOMA MECHANICAL",
    company: "JOHN DEERE",
    role: "Technical Trainee",
    salary: "2 LPA",
    photo: "/assets/T&p/records/ramchandra.png",
  },
  {
    id: 1,
    name: "Tejas Prabhakar Korgaonkar",
    class: "DIPLOMA MECHANICAL",
    company: "JOHN DEERE",
    role: "Technical Trainee",
    salary: "2 LPA",
    photo: "/assets/T&p/records/tejas.png",
  },
  {
    id: 1,
    name: "Aniket Gopal Angane",
    class: "DIPLOMA MECHANICAL",
    company: "JOHN DEERE",
    role: "Technical Trainee",
    salary: "2 LPA",
    photo: "/assets/T&p/records/aniket.png",
  },
  {
    id: 1,
    name: "Jivaba Shrikrishna Palav",
    class: "DIPLOMA MECHANICAL",
    company: "JOHN DEERE",
    role: "Technical Trainee",
    salary: "2 LPA",
    photo: "/assets/T&p/records/jivaba.png",
  },
  {
    id: 1,
    name: "Manish Shantaram Shinde",
    class: "DIPLOMA MECHANICAL",
    company: "KSPG AUTOMOTIVE",
    role: "Trainee Operating Engineering",
    salary: "2 LPA",
    photo: "/assets/T&p/records/manish.png",
  },
  {
    id: 1,
    name: "Sumit Bhisaji Parab",
    class: "DIPLOMA MECHANICAL",
    company: "KSPG AUTOMOTIVE",
    role: "Trainee Operating Engineering",
    salary: "2 LPA",
    photo: "/assets/T&p/records/sumit.png",
  },
  {
    id: 1,
    name: "Ramchandra Prashant Sawant",
    class: "DIPLOMA MECHANICAL",
    company: "KSPG AUTOMOTIVE",
    role: "Trainee Operating Engineering",
    salary: "2 LPA",
    photo: "/assets/T&p/records/ramchandra.png",
  },
  {
    id: 1,
    name: "Nikhil Sandesh Raorane",
    class: "DIPLOMA MECHANICAL",
    company: "KSPG AUTOMOTIVE",
    role: "Trainee Operating Engineering",
    salary: "2 LPA",
    photo: "/assets/T&p/records/nikhil.png",
  },
  {
    id: 1,
    name: "Joy Wilson D'Souza",
    class: "DIPLOMA MECHANICAL",
    company: "KSPG AUTOMOTIVE",
    role: "Trainee Operating Engineering",
    salary: "2 LPA",
    photo: "/assets/T&p/records/joy.png",
  },
  {
    id: 1,
    name: "Manish Shantaram Shinde",
    class: "DIPLOMA MECHANICAL",
    company: "OAK TECHNOLOGIES",
    role: "Technical Trainee",
    salary: "1.8 LPA",
    photo: "/assets/T&p/records/manish.png",
  },
  {
    id: 1,
    name: "Roman Rafiqahmad Khan",
    class: "DIPLOMA MECHANICAL",
    company: "TATA MOTORS",
    role: "Body shop Advisor",
    salary: "1.8 LPA",
    photo: "/assets/T&p/records/roman.png",
  },
  {
    id: 1,
    name: "Prathmesh Yashwant Palav",
    class: "DIPLOMA MECHANICAL",
    company: "TATA MOTORS",
    role: "Body shop Advisor",
    salary: "1.8 LPA",
    photo: "/assets/T&p/records/prathmesh.png",
  },
  {
    id: 1,
    name: "Saurabh Shashikant Panchal",
    class: "DIPLOMA MECHANICAL",
    company: "TATA MOTORS",
    role: "Body shop Advisor",
    salary: "1.8 LPA",
    photo: "/assets/T&p/records/saurabh.png",
  },
  {
    id: 1,
    name: "Siddhali Ladu Parulekar",
    class: "DIPLOMA MECHANICAL",
    company: "Q-SPIDER",
    role: "Software Trainee",
    salary: "",
    photo: "/assets/T&p/records/siddhali.png",
  },
  {
    id: 1,
    name: "Pravin Gawade",
    class: "DIPLOMA MECHANICAL",
    company: "Q-SPIDER",
    role: "Software Trainee",
    salary: "",
    photo: "/assets/T&p/records/pravin.png",
  },
  {
    id: 1,
    name: "Ravindra Arvind Gawade",
    class: "DIPLOMA MECHANICAL",
    company: "Q-SPIDER",
    role: "Software Trainee",
    salary: "",
    photo: "/assets/T&p/records/ravindra.png",
  },
  {
    id: 1,
    name: "Rohit Ravindra Parab",
    class: "DIPLOMA MECHANICAL",
    company: "Q-SPIDER",
    role: "Software Trainee",
    salary: "",
    photo: "/assets/T&p/records/rohit.png",
  },
  {
    id: 1,
    name: "Priyanka Subhash Parab",
    class: "BE COMPUTER",
    company: "Q-SPIDER",
    role: "Software Trainee",
    salary: "",
    photo: "/assets/T&p/records/priyanka.png",
  },
  {
    id: 1,
    name: "Mayuri Bhikaji Ghadi",
    class: "BE COMPUTER",
    company: "Q-SPIDER",
    role: "Software Trainee",
    salary: "",
    photo: "/assets/T&p/records/mayuri.png",
  },
  {
    id: 1,
    name: "Hemant Arvind Gosavi",
    class: "BE COMPUTER",
    company: "Q-SPIDER",
    role: "Software Trainee",
    salary: "",
    photo: "/assets/T&p/records/hemant.png",
  },
  {
    id: 1,
    name: "Sudesh Siddharth Jamsandekar",
    class: "BE COMPUTER",
    company: "Q-SPIDER",
    role: "Software Trainee",
    salary: "",
    photo: "/assets/T&p/records/sudesh.png",
  },
  {
    id: 1,
    name: "Prasad Prakash Wairkar",
    class: "BE CIVIL",
    company: "Q-SPIDER",
    role: "Software Trainee",
    salary: "",
    photo: "/assets/T&p/records/prasad.png",
  },
  {
    id: 1,
    name: "Tanmay Deepak Padwal",
    class: "TE MECHANICAL",
    company: "TATA MOTORS",
    role: "Technical Trainee",
    salary: "",
    photo: "/assets/T&p/records/tanmay.png",
  },
  {
    id: 1,
    name: "Ashish Ashok Ghogale",
    class: "DIPLOMA MECHANICAL",
    company: "TATA MOTORS",
    role: "Body shop Advisor",
    salary: "",
    photo: "/assets/T&p/records/ashish.png",
  },
  {
    id: 1,
    name: "Datta Tukaram Parab",
    class: "TE MECHANICAL",
    company: "TATA MOTORS",
    role: "Technical Trainee",
    salary: "",
    photo: "/assets/T&p/records/datta.png",
  },
  {
    id: 1,
    name: "Shubham Suhas Chavan",
    class: "TE MECHANICAL",
    company: "TATA MOTORS",
    role: "Technical Trainee",
    salary: "",
    photo: "/assets/T&p/records/shubham.png",
  },
  {
    id: 1,
    name: "Prathmesh Namdev Parab",
    class: "TE MECHANICAL",
    company: "TATA MOTORS",
    role: "Technical Trainee",
    salary: "",
    photo: "/assets/T&p/records/prathmesh.png",
  },
  {
    id: 1,
    name: "Faizan Iqbal Shahgodad",
    class: "TE MECHANICAL",
    company: "TATA MOTORS",
    role: "Technical Trainee",
    salary: "",
    photo: "/assets/T&p/records/faizan.png",
  },
  {
    id: 1,
    name: "Vinod Chandrashekar Kanekar",
    class: "BE CIVIL",
    company: "JAN'S PVT.LTD.",
    role: "Jr.Civil Engineer",
    salary: "2.5 LPA",
    photo: "/assets/T&p/records/vinod.png",
  },
  {
    id: 1,
    name: "Shankar Sakhram Ghadge",
    class: "BE CIVIL",
    company: "JAN'S PVT.LTD.",
    role: "Jr.Civil Engineer",
    salary: "2.5 LPA",
    photo: "/assets/T&p/records/shankar.png",
  },
  {
    id: 1,
    name: "Harsh Ghadigaonkar",
    class: "TE MECHANICAL",
    company: "TATA MOTORS",
    role: "Technical Trainee",
    salary: "",
    photo: "/assets/T&p/records/harsh.png",
  },
  {
    id: 1,
    name: "Mohit Naik",
    class: "TE MECHANICAL",
    company: "TATA MOTORS",
    role: "Technical Trainee",
    salary: "",
    photo: "/assets/T&p/records/mohit.png",
  },
  {
    id: 1,
    name: "Nilesh Vinod Bachhav",
    class: "DIPLOMA MECHANICAL",
    company: "BAJAJ AUTO",
    role: "Technical Trainee",
    salary: "2.8LPA",
    photo: "/assets/T&p/records/nilesh.png",
  },
  {
    id: 1,
    name: "Darshan Dinesh Palav",
    class: "DIPLOMA MECHANICAL",
    company: "BAJAJ AUTO",
    role: "Technical Trainee",
    salary: "2.8LPA",
    photo: "/assets/T&p/records/darshan.png",
  },
  {
    id: 1,
    name: "Aniket Gopal Angane",
    class: "DIPLOMA MECHANICAL",
    company: "BAJAJ AUTO",
    role: "Technical Trainee",
    salary: "2.8LPA",
    photo: "/assets/T&p/records/aniket.png",
  },
  {
    id: 1,
    name: "Tejas Dattatray Thakur",
    class: "DIPLOMA MECHANICAL",
    company: "BAJAJ AUTO",
    role: "Technical Trainee",
    salary: "2.8LPA",
    photo: "/assets/T&p/records/tejas.png",
  },
  {
    id: 1,
    name: "Joy Wilson D'Souza",
    class: "DIPLOMA MECHANICAL",
    company: "BAJAJ AUTO",
    role: "Technical Trainee",
    salary: "2.8LPA",
    photo: "/assets/T&p/records/joy.png",
  },
];

const Table = () => {
  return (
    <motion.div className="table-container rounded-md overflow-x-auto z-[5]  mx-auto font-change"
    initial={{ y:-40,opacity:0 }}
          animate={{ y:0, opacity:1}}
          transition={{ ease: "easeIn", duration: 1 }}>
      <table className="min-w-full divide-y z-[5] ">
        <thead>
          <tr>
            <th className="px-6 py-3 text-left text-md font-medium text-white uppercase tracking-wider">
              Sr. No.
            </th>
            <th className="px-6 py-3 text-left text-md font-medium text-white uppercase tracking-wider">
              Student Name
            </th>
            <th className="px-6 py-3 text-left text-md font-medium text-white uppercase tracking-wider">
              Class
            </th>
            <th className="px-6 py-3 text-left text-md font-medium text-white uppercase tracking-wider">
              Name of Company
            </th>
            <th className="px-6 py-3 text-left text-md font-medium text-white uppercase tracking-wider">
              Job Role
            </th>
            <th className="px-6 py-3 text-left text-md font-medium text-white uppercase tracking-wider">
              Salary Scale
            </th>
            <th className="px-6 py-3 text-left text-md font-medium text-white uppercase tracking-wider">
              Photo
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {data.map((student, index) => (
            <tr
              key={student.id}
              className={index % 2 === 0 ? "bg-blue-100" : "bg-blue-50"}
            >
              <td className="px-6 py-4 whitespace-nowrap">{index + 1}</td>
              <td className="px-6 py-4 whitespace-nowrap">{student.name}</td>
              <td className="px-6 py-4 whitespace-nowrap">{student.class}</td>
              <td className="px-6 py-4 whitespace-nowrap">{student.company}</td>
              <td className="px-6 py-4 whitespace-nowrap">{student.role}</td>
              <td className="px-6 py-4 whitespace-nowrap">{student.salary}</td>
              <td className="px-6 py-2 whitespace-nowrap">
                <img
                  src={student.photo}
                  alt={student.name}
                  className="h-[90px] w-[85px] rounded-md object-cover shadow-md"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </motion.div>
  );
};

export default Table;
