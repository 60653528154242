import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
function AboutDmech() {
  return (
    <div className="md:px-20 px-8 py-10 font-change">
       <h1 className='text-4xl underline underline-offset-8 font-bold text-orange-500  mb-8 text-center'>DIPLOMA IN MECHANICAL ENGINEERING</h1>
      <div className="mb-14 mx-auto flex justify-center text-sm md:text-base">
        <Link
          to="/AboutDmech"
          className="md:px-8 py-3 px-4 bg-blue-600 text-white rounded-l-lg "
        >
          About US
        </Link>
        <Link
          to="/Dmechpospo"
          className="md:px-8 py-3 px-4 bg-blue-900 hover:bg-blue-800 text-white "
        >
          PO PSO PEO
        </Link>
        <Link
          to="/Dmechlabo"
          className="md:px-8 py-3 px-4 bg-blue-900 hover:bg-blue-800 text-white rounded-r-lg"
        >
          Laboratories
        </Link>
      </div>
      <motion.div className="font-semibold"
      initial={{ y:-40,opacity:0 }}
      animate={{ y:0, opacity:1}}
      transition={{ ease: "easeIn", duration: 1 }}>
        <h2 className="text-2xl md:text-3xl text-justify font-bold ">
          Department at a Glance
        </h2>
        <br />
        <p className="md:text-lg text-base text-justify text-black/70">
          {" "}
          &ensp;&ensp;Mechanical engineering is the discipline that applies
          engineering,physics and material science principles to design analyze,
          manufacture, and maintain mechanical systems It is the branch of
          engineering that involves the design, production, and operation of
          machinery. It is one of the oldest and broadest of the engineering
          disciplines. The mechanical engineering field requires an
          understanding of core areas including mechanics, kinematics,
          thermodynamics, materials science, structural analysis, and
          electricity. In addition to these core principles, mechanical
          engineers use tools such as computer-aided design (CAD), and product
          life cycle management to design and analyze manufacturing plants,
          industrial equipment and machinery, heating and cooling systems,
          transport systems, aircraft, watercraft, robotics, medical devices,
          weapons, and others.
        </p>
        <br />
        <h2 className="text-2xl md:text-3xl text-justify font-bold ">Vision</h2>
        <br />
        <p className="md:text-lg text-base text-justify text-black/70">
          To be nationally recognized for excellence in education in the field
          of Mechanical Engineering.
        </p>
        <br />
        <h2 className="text-2xl md:text-3xl text-justify font-bold ">
          Mission
        </h2>
        <br />
        <p className="md:text-lg text-base text-justify text-black/70">
          To impart value based technical education and promote research with
          emphasis on respect amongst the stakeholders.
        </p>
      </motion.div>
    </div>
  );
}

export default AboutDmech;
