import React from 'react'

function Committe() {
  return (
    <div  className="md:px-14 px-3 flex justify-center items-center flex-col">
    <h1 id='committee' className="text-2xl underline underline-offset-8 font-bold my-3 text-center">
       Anti Ragging Committee
    </h1>
    <embed
      className="md:w-[80%] w-[90%] rounded-md h-[40vh] lg:h-[90vh] my-5"
      src="/assets/statutary/ARC.pdf"
      type="application/pdf"
    />

    <h1 className="text-2xl underline underline-offset-8 font-bold my-3 text-center">
    College Canteen Committee
    </h1>
    <embed
      className="md:w-[80%] w-[90%] rounded-md h-[40vh] lg:h-[90vh] my-5"
      src="/assets/statutary/CollegeCanteenCommittee.pdf"
      type="application/pdf"
    />

<h1 className="text-2xl underline underline-offset-8 font-bold my-3 text-center">
    College Development Committee
    </h1>
    <embed
      className="md:w-[80%] w-[90%] rounded-md h-[40vh] lg:h-[90vh] my-5"
      src="/assets/statutary/CollegeDevelopmentCommittee.pdf"
      type="application/pdf"
    />

<h1 className="text-2xl underline underline-offset-8 font-bold my-3 text-center">
   Committee for SC ST
    </h1>
    <embed
      className="md:w-[80%] w-[90%] rounded-md h-[40vh] lg:h-[90vh] my-5"
      src="/assets/statutary/CommitteeforSCST.pdf"
    />

<h1 className="text-2xl underline underline-offset-8 font-bold my-3 text-center">
   Internal Complaint Committee
    </h1>
    <embed
      className="md:w-[80%] w-[90%] rounded-md h-[40vh] lg:h-[90vh] my-5"
      src="/assets/statutary/ICC.pdf"
      type="application/pdf"
    />

<h1 className="text-2xl underline underline-offset-8 font-bold my-3 text-center">
Intellectual Property Right Cell
    </h1>
    <embed
      className="md:w-[80%] w-[90%] rounded-md h-[40vh] lg:h-[90vh] my-5"
      src="/assets/statutary/IntellectualPropertyRightCell.pdf"
      type="application/pdf"
    />

<h1 className="text-2xl underline underline-offset-8 font-bold my-3 text-center">
Internal Quality Assurance Cell
    </h1>
    <embed
      className="md:w-[80%] w-[90%] rounded-md h-[40vh] lg:h-[90vh] my-5"
      src="/assets/statutary/InternalQualityAssuranceCell.pdf"
      type="application/pdf"
    />

<h1 className="text-2xl underline underline-offset-8 font-bold my-3 text-center">
OBC Minority Committee
    </h1>
    <embed
      className="md:w-[80%] w-[90%] rounded-md h-[40vh] lg:h-[90vh] my-5"
      src="/assets/statutary/OBCMinorityCommittee.pdf"
      type="application/pdf"
    />

<h1 className="text-2xl underline underline-offset-8 font-bold my-3 text-center">
Students Grievance Redressal Committee
    </h1>
    <embed
      className="md:w-[80%] w-[90%] rounded-md h-[40vh] lg:h-[90vh] my-5"
      src="/assets/statutary/StudentsGrievanceRedressalCommittee.pdf"
      type="application/pdf"
    />
</div>
  )
}

export default Committe