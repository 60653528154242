import React from "react";
import Aboutcomp from "./Aboutcomp";
function Computer() {
  return (
    <section>
        <Aboutcomp/>
    </section>
  );
}

export default Computer;

