import React, { useState } from 'react';
import { Button, Input, Textarea } from "@material-tailwind/react";
import { Toaster, toast } from "react-hot-toast";
import { motion } from 'framer-motion';

function Payment() {
    const [openModal, setOpenModal] = useState(false);
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState(false);

    const onCloseModal = () => {
        setOpenModal(false);
        setEmailError(false);
        setPhoneNumberError(false);
    };

    const onSubmit = async (event) => {
        event.preventDefault();

        // Check if phone number is valid
        if (!validatePhoneNumber(phoneNumber)) {
            setPhoneNumberError(true);
            return;
        }

        const formData = new FormData(event.target);
        formData.append("access_key", "c4f81f7f-b865-441c-9cf6-0421f6361745");

        const object = Object.fromEntries(formData);
        const json = JSON.stringify(object);

        const res = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: json,
        }).then((res) => res.json());

        if (res.success) {
            toast.success("Your response has been successfully submitted. We will review it promptly and notify you accordingly.");
            setTimeout(() => { }, 3000);
            onCloseModal();
        }
    };


    const validatePhoneNumber = (phoneNumber) => {
        // Regular expression for phone number validation
        const re = /^[0-9]{10}$/;
        return re.test(String(phoneNumber));
    };

    return (
        <motion.section className='flex flex-col justify-center items-center px-5'
            initial={{ y: -40, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ ease: "easeIn", duration: 1 }}>
            <div className='flex md:flex-row flex-col justify-center items-center my-8 w-[100%]'>

                <div className='bg-gray-200 px-3 py-2 rounded-md h-auto w-auto mx-4 '>
                    <img src="/assets/payment/qr1.png" alt="" />
                </div>
                <div className='flex flex-col items-center justify-center mx-4 space-y-16 w-[100%] md:w-auto'>
                    <div className='bg-gray-200 md:text-xl px-3 py-3 rounded-md space-y-3 font-semibold w-[100%] mt-10 md:mt-0'>
                        <h4 className='text-center font-bold md:text-2xl'>Degree Bank Details</h4>
                        <p className='text-black/80'>Bank Name: Union Bank Of India</p>
                        <p className='text-black/80'>Account Name: METROPOLITAN INSTITUTE OF TECHNOLOGY & MANAGEMENT</p>
                        <p className='text-black/80'>Account Number : 520101249909839</p>
                        <p className='text-black/80'>IFSC / Branch code : UBIN0568643</p>
                    </div>

                    <div className=' px-6 py-3 rounded-md font-change'>
                        <h1 className='text-xl my-4 text-red-600 font-semibold '>Follow This Steps :</h1>
                        <ul className='list-disc list-inside text-black/90 space-y-3'>
                            <li>After completing the payment, ensure that all details in the form below are filled out accurately.</li>
                            <li>Please ensure that all required fields in the form are filled out correctly before submission.</li>
                            <li>It is mandatory to collect a hard copy of your receipt from the college upon payment completion.</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className='flex md:flex-row flex-col justify-center items-center my-8 w-[100%]'>

<div className='bg-gray-200 px-3 py-2 rounded-md h-auto w-auto mx-4 '>
    <img src="/assets/payment/qr.png" alt="" />
</div>
<div className='flex flex-col items-center justify-center mx-4 space-y-16 w-[100%] md:w-auto'>
    <div className='bg-gray-200 md:text-xl px-3 py-3 rounded-md space-y-3 font-semibold w-[100%] mt-10 md:mt-0'>
        <h4 className='text-center font-bold md:text-2xl'>Diploma Bank Details</h4>
        <p className='text-black/80'>Bank Name: Union Bank of India</p>
        <p className='text-black/80'>Account Name:  Principal Metropolitan Institute Of Technology And Management</p>
        <p className='text-black/80'>Account Number : 520101262436775</p>
        <p className='text-black/80'>IFSC / Branch code : UBIN0568643</p>
    </div>

    <div className=' px-6 py-3 rounded-md font-change'>
        <h1 className='text-xl my-4 text-red-600 font-semibold '>Follow This Steps :</h1>
        <ul className='list-disc list-inside text-black/90 space-y-3'>
            <li>After completing the payment, ensure that all details in the form below are filled out accurately.</li>
            <li>Please ensure that all required fields in the form are filled out correctly before submission.</li>
            <li>It is mandatory to collect a hard copy of your receipt from the college upon payment completion.</li>
        </ul>
    </div>
</div>
</div>

            <div className='bg-[#f2f0f0] h-[1px] w-full shadow-2xl mt-10'></div>

            <form onSubmit={onSubmit} className="space-y-8 lg:w-[50%] w-[90%] my-8 border border-black px-4 py-4 rounded-xl shadow-2xl">
                <h1 className='text-center text-xl'>Online Payment Form</h1>
                <Input
                    label="Your name"
                    type="text"
                    name="name"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    className='!outline-none !ring-0' // Ensure this class is applied to remove the blue outline
                />
                <Input
                    label="Branch"
                    type="text"
                    name="Branch"
                    placeholder='Degree / Diploma'
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    className='!outline-none !ring-0' // Ensure this class is applied to remove the blue outline
                />
                <Input
                    label="Course Name"
                    type="text"
                    name="Course Name"
                    placeholder='Computer / Civil / Mechanical / EXTC'
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    className='!outline-none !ring-0' // Ensure this class is applied to remove the blue outline
                />
                <Input
                    label="Year of Study"
                    type="text"
                    name="Year of Study"
                    placeholder="FE / SE / TE / BE"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    className='!outline-none !ring-0' // Ensure this class is applied to remove the blue outline
                />

                <Input
                    label="Email"
                    type="email"
                    name="email"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    className='!outline-none !ring-0' // Ensure this class is applied to remove the blue outline
                />
                {emailError && (
                    <p className="text-red-500">Please enter a valid email.</p>
                )}
                <Input
                    label="Phone Number"
                    type="tel"
                    name="phone"
                    required
                    error={phoneNumberError}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    className='!outline-none !ring-0' // Ensure this class is applied to remove the blue outline
                />
                {phoneNumberError && (
                    <p className="text-red-500">Please enter a valid phone number.</p>
                )}

               <Input
                    label="Enter amount"
                    type="number"
                    name="Amount"
                    placeholder='eg. 10000 /-'
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    className='!outline-none !ring-0' // Ensure this class is applied to remove the blue outline
                />

                <Input
                    label="Transaction ID"
                    type="number"
                    name="Transaction ID"
                    placeholder='eg. 467346382763'
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    className='!outline-none !ring-0' // Ensure this class is applied to remove the blue outline
                />

                <Textarea label="Description" name="message" className='!outline-none !ring-0' />
                <Button className="bg-[#090337] text-white w-full" type="submit">
                    Submit
                </Button>
            </form>
        </motion.section>
    )
}

export default Payment;
