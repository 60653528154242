import { motion } from 'framer-motion'
import React from 'react'

function Circular() {
  return (
    <motion.div className='p-16 text-center font-change space-y-6'
    initial={{ y:-40,opacity:0 }}
          animate={{ y:0, opacity:1}}
          transition={{ ease: "easeIn", duration: 1 }}>
        <h1 className='text-3xl md:text-4xl text-center font-bold'>Circular / Notice / Instructions</h1>
        <div className='bg-[#f2f0f0] h-[1px] w-full shadow-2xl mt-10'></div>
        <div className="degree ">
         <h2 className='text-2xl md:text-2xl my-3 font-bold underline underline-offset-8'>Degree</h2>
         <a className='text-black/80 text-lg' href="">Degree Exam circular</a>
        </div>
        <div className="diploma">
        <h2 className='text-2xl md:text-2xl my-3  font-bold underline underline-offset-8'>Diploma</h2>
        <a className='text-black/80 text-lg' href="">Diploma Exam circular</a>
        </div>
        <div className="bsc">
        <h2 className='text-2xl md:text-2xl my-3  font-bold underline underline-offset-8'>B.sc</h2>
        <a className='text-black/80 text-lg' href="">B.sc Exam circular</a>
        </div>
    
    </motion.div>
  )
}

export default Circular