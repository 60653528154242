import React from 'react'
import { Table } from "flowbite-react";
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

function Extclabo() {
    const data = [
        {
          imgelink:
            "/assets/Courses/engineering/extc/1.jpg",
        },
        {
          imgelink:
            "/assets/Courses/engineering/extc/2.jpg",
        },
        {
          imgelink:
            "/assets/Courses/engineering/extc/3.jpg",
        },
        {
          imgelink:
            "/assets/Courses/engineering/extc/4.jpg",
        },
        {
          imgelink:
            "/assets/Courses/engineering/extc/5.jpg",
        },       
        
        {
          imgelink:
            "/assets/Courses/engineering/extc/6.jpg",
        },
        {
          imgelink:
            "/assets/Courses/engineering/extc/7.jpg",
        },
        {
          imgelink:
            "/assets/Courses/engineering/extc/8.jpg",
        },
        {
          imgelink:
            "/assets/Courses/engineering/extc/9.jpg",
        },
        {
          imgelink:
            "/assets/Courses/engineering/extc/10.jpg",
        },      
          {
          imgelink:
            "/assets/Courses/engineering/extc/11.jpg",
        },        
        {
          imgelink:
            "/assets/Courses/engineering/extc/12.jpg",
        },
      ];
     
      const [active, setActive] = React.useState(
        "/assets/Courses/engineering/extc/1.jpg",
      );
    
  return (
    <div className='md:px-20 px-8 py-10 font-change'>
            <h1 className='text-4xl underline underline-offset-8 font-bold text-orange-500  mb-8 text-center'>ELECTRONICS & TELECOMUNICATION ENGINEERING</h1>
    <div className='mb-14 mx-auto flex justify-center text-sm md:text-base'>
<Link to='/Aboutextc' className='md:px-8 px-4 py-3 bg-blue-900 hover:bg-blue-800 text-white rounded-l-lg'>About US</Link>
<Link to='/Extcpospo' className='md:px-8 py-3 px-4 bg-blue-900 hover:bg-blue-800 text-white '>PO PSO PEO</Link>
<Link to='/Extclabo' className='md:px-8 py-3 px-4 bg-blue-600 text-white rounded-r-lg'>Laboratories</Link>
    </div>
    <h2 className='text-2xl md:text-3xl text-center font-bold my-6'>Laboratories</h2>
    <motion.div className="overflow-x-auto my-7 border border-gray-200 rounded-xl"
    initial={{ y:-40,opacity:0 }}
    animate={{ y:0, opacity:1}}
    transition={{ ease: "easeIn", duration: 1 }}>
      <Table striped>
        <Table.Head>
          <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg ">Sr.No.</Table.HeadCell>
          <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg">Name of Laboratory</Table.HeadCell>
          <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg">Specifications</Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y text-sm md:text-base font-semibold">
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
  <Table.Cell>1</Table.Cell>
  <Table.Cell>Digital Signal Processing Lab</Table.Cell>
  <Table.Cell>Digital Oscilloscope, Function Generators, DSP Kits, MATLAB Software</Table.Cell>
</Table.Row>
<Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
  <Table.Cell>2</Table.Cell>
  <Table.Cell>Communication</Table.Cell>
  <Table.Cell>Antenna Trainer Kits, Spectrum Analyzers, Microwave Test Benches, Signal Generators</Table.Cell>
</Table.Row>
<Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
  <Table.Cell>3</Table.Cell>
  <Table.Cell>Microprocessor</Table.Cell>
  <Table.Cell>Microprocessor Kits, Logic Analyzers, Microcontroller Kits, EEPROM Programmers</Table.Cell>
</Table.Row>
<Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
  <Table.Cell>4</Table.Cell>
  <Table.Cell>Electronic Circuits</Table.Cell>
  <Table.Cell>Oscilloscopes, Function Generators, Breadboards, Power Supplies, Multimeters</Table.Cell>
</Table.Row>
<Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
  <Table.Cell>5</Table.Cell>
  <Table.Cell>Embedded Systems</Table.Cell>
  <Table.Cell>ARM Development Boards, Raspberry Pi, Sensors, LCD Displays</Table.Cell>
</Table.Row>
<Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
  <Table.Cell>6</Table.Cell>
  <Table.Cell>Networks</Table.Cell>
  <Table.Cell>Routers, Switches, Network Cables, LAN Testers, Wi-Fi Access Points</Table.Cell>
</Table.Row>
<Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
  <Table.Cell>7</Table.Cell>
  <Table.Cell>Optical Communication</Table.Cell>
  <Table.Cell>Fiber Optic Kits, Optical Power Meters, OTDRs, Optical Sources</Table.Cell>
</Table.Row>

        </Table.Body>
      </Table>
    </motion.div>

<motion.div className='md:w-[60%] w-[90%] mx-auto'
initial={{ y:-40,opacity:0 }}
animate={{ y:0, opacity:1}}
transition={{ ease: "easeIn", duration: 1 }}>
    <h2 className='text-2xl md:text-3xl text-center font-bold my-6'>Laboratories Photos</h2>
      <div className="grid gap-4">
      <div>
        <img
          className="h-auto w-full max-w-full rounded-lg object-cover object-center md:h-[480px]"
          src={active}
          alt=""
        />
      </div>
      <div className="grid grid-cols-5 gap-4">
        {data.map(({ imgelink }, index) => (
          <div key={index}>
            <img
              onClick={() => setActive(imgelink)}
              src={imgelink}
              className="h-20 max-w-full cursor-pointer rounded-lg object-cover object-center"
              alt="gallery-image"
            />
          </div>
        ))}
      </div>
    </div>
</motion.div>

    </div>
  )
}
export default Extclabo