import React from "react";
import { Link} from "react-router-dom";
import "../navbar_items/styles/items.css";

function Student(props) {
  return (
    <>

        <div className="group inline-block text-base ">
          <button className="outline-none focus:outline-none  px-3 py-2 text-start xlc:text-center rounded-sm flex items-center min-w-[6rem]  hover:bg-blue-800">
            <span className="pr-1 font-semibold  flex-1">Student</span>
            <span>
            <svg
              className="fill-current h-4 w-4 transform group-hover:-rotate-180 xlc:hidden block
        transition duration-150 ease-in-out"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </span>
          </button>
          <ul
            className="bg-white border rounded-sm transform scale-0 group-hover:scale-100 absolute 
  transition duration-300 ease-in-out origin-top w-[50%] xlc:w-[10%] xlc:min-w-32 mt-1 flex flex-col z-10"
          >
            <Link
            onClick={props.onclick}
              to=""
              className="rounded-sm relative px-3 py-3 rounded-t bg-blue-100 text-black  hover:bg-blue-800 hover:text-white shadow-lg"
            >
              Student Welfare
            </Link>
            <Link
            onClick={props.onclick}
              to=""
              className="rounded-sm relative px-3 py-3 rounded-t bg-blue-100 text-black  hover:bg-blue-800 hover:text-white shadow-lg"
            >
              Student Clubs
            </Link>
            <Link
            onClick={props.onclick}
              to="/Committee"
              className="rounded-sm relative px-3 py-3 rounded-t bg-blue-100 text-black  hover:bg-blue-800 hover:text-white shadow-lg"
            >
              Anti Ragging
            </Link>

            <li className="rounded-sm relative px-3 py-3 rounded-t bg-blue-100 text-black  hover:bg-blue-800 hover:text-white shadow-lg">
              <button className="w-full text-left flex items-center outline-none focus:outline-none">
                <span className="pr-1 flex-1">Syllabus</span>
                <span className="mr-auto">
                  <svg
                    className="fill-current h-4 w-4
            transition duration-150 ease-in-out"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </span>
              </button>
              <ul
                className="bg-white border rounded-sm absolute top-0 right-0 
  transition duration-300 ease-in-out origin-top-left
  md:min-w-[25rem] min-w-[10rem] 
  "
              >
                <li className="px-1 py-3 rounded-t bg-blue-100 text-black  hover:bg-blue-800 hover:text-white shadow-lg">
                  <a href="https://muquestionpapers.com/syllabus/be" onClick={props.onclick} className="px-3 py-3" target="_blank">
                    Engineering
                  </a>
                </li>
                <li className="px-1 py-3 rounded-t bg-blue-100 text-black  hover:bg-blue-800 hover:text-white shadow-lg">
                  <a href="https://muquestionpapers.com/syllabus/science" onClick={props.onclick} className="px-3 py-3" target="_blank">
                    Diploma
                  </a>
                </li>
                <li className="px-1 py-3 rounded-t bg-blue-100 text-black  hover:bg-blue-800 hover:text-white shadow-lg" >
                  <a href="" onClick={props.onclick} className="px-3 py-3" target="_blank">
                    B.Sc.
                  </a>
                </li>
              </ul>
            </li>

            <a
            onClick={props.onclick}
              href="https://muquestionpapers.com/"
              target="_blank"
              className="rounded-sm relative px-3 py-3 rounded-t bg-blue-100 text-black  hover:bg-blue-800 hover:text-white shadow-lg"
            >
              Downloads (Question papers)
            </a>
          </ul>
        </div>
    </>
  );
}

export default Student;
