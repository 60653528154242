import { motion } from 'framer-motion'
import React from 'react'
import { Link } from 'react-router-dom'

function Aboutextc() {
  return (
    <div className='md:px-20 px-8 py-10 font-change'>
      <h1 className='text-4xl underline underline-offset-8 font-bold text-orange-500  mb-8 text-center'>ELECTRONICS & TELECOMUNICATION ENGINEERING</h1>
        <div className='mb-14 mx-auto flex justify-center text-sm md:text-base'>
    <Link to='/Aboutextc' className='md:px-8 py-3 px-4 bg-blue-600 text-white rounded-l-lg '>About US</Link>
    <Link to='/Extcpospo' className='md:px-8 py-3 px-4 bg-blue-900 hover:bg-blue-800 text-white '>PO PSO PEO</Link>
    <Link to='/Extclabo' className='md:px-8 py-3 px-4 bg-blue-900 hover:bg-blue-800 text-white rounded-r-lg'>Laboratories</Link>
        </div>
        <motion.div className='font-semibold'
        initial={{ y:-40,opacity:0 }}
        animate={{ y:0, opacity:1}}
        transition={{ ease: "easeIn", duration: 1 }}>
          <h2 className='text-2xl md:text-3xl text-justify font-bold '>Department at a Glance</h2>
          <br />
          <p className="md:text-lg text-base text-justify text-black/70"> &ensp;&ensp;With the explosion of electronic media, telecom industry has become one of the fast growing sectors in every corner of the world. This field plays a major role in our day to day life and its technology has helped us to see, hear and communicate over vast distances. In this era of information and communication, more digitally enhanced communication devices like mobiles. Internet and satellites are paving way to expansion for the wider reach of communication technology. As a result, there is a great demand for trained professionals in this industry and hence the importance of Telecommunication Engineering.
          <br />
          Telecommunication, a major field within Electronic engineering. is concerned with all types of information and transmission networks.
          <br />
          It is the exchange of information over a distance, through electronic means and deals with communication systems such as cables, radio, satellite communications, TV, radar, navigational communication equipments etc. Telecommunication engineering is involved with the designing, manufactuning and operation of telecommunication devices.
</p>
          <br />
          <h2 className='text-2xl md:text-3xl text-justify font-bold '>Vision</h2>
          <br />
          <p className="md:text-lg text-base text-justify text-black/70">To be nationally recognized for excellence in 
education in the field of Electronics and Telecommunication Engineering.</p>
          <br />
          <h2 className='text-2xl md:text-3xl text-justify font-bold '>Mission</h2>
          <br />
          <p className="md:text-lg text-base text-justify text-black/70">To impart value based technical education by 
maintaining mutual respect and imbibing culture of research, innovation and team work.</p>
        </motion.div>
        </div>
  )
}

export default Aboutextc