import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
function Bscpospo() {
  return (
    <div className='md:px-20 px-8 py-10 font-change'>
            <h1 className='text-4xl underline underline-offset-8 font-bold text-orange-500  mb-8 text-center'>B.Sc (COMPUTER SCIENCE)</h1>
    <div className='mb-14 mx-auto flex justify-center text-sm md:text-base'>
    <Link to='/Aboutcs' className='md:px-8 py-3 px-4 bg-blue-900 hover:bg-blue-800 text-white rounded-l-lg '>About US</Link>
        <Link to='/Bscpospo' className='md:px-8 py-3 px-4  bg-blue-600  text-white rounded-r-lg'>PO PSO PEO</Link>
    </div>

    <br />
    <motion.div
        initial={{ y:-40,opacity:0 }}
        animate={{ y:0, opacity:1}}
        transition={{ ease: "easeIn", duration: 1 }}>
        <h2 className='text-2xl md:text-3xl text-justify font-bold '>Program Outcomes (PO)</h2>
        <br />
        <p className='font-semibold text-lg '>Upon successful completion of Programme graduates in Computer Engineering will demonstrate:</p>
        <ul className='list-decimal list-inside md:text-lg  text-base text-justify font-semibold text-black/70'>
        <br />
        <li>An ability to apply knowledge of Mathematics, Science and Engineering.</li>
        <br />
        <li>Ability to analyze the problem statement, for design and implement to achieve desired result.</li>
        <br />
        <li>Ability to design a system or process to meet rapidly changing computing problems and information system environment considering the constraints such as economic, environmental, social, political, ethical, health and safety.</li>                
        <br />
        <li>Ability to function on multidisciplinary teams.</li>               
        <br />
        <li>Ability to identify, formulate, and solve engineering problems.</li>
        <br />            
        <li>Ability to understanding of professional and ethical responsibility.</li>
        <br />
        <li>Ability to communicate effectively.</li>
        <br />
        <li>Ability to understand the impact of engineering solutions in a global, economic, environmental and societal context.</li>
        <br />
        <li> Recognition of the need for, and an ability to engage in life-long learning.</li>
        <br />
        <li>Ability to solve contemporary issues.</li>
        <br />
        <li>Ability to use modern software tools and techniques necessary for Computing practice.</li>
        <br />
    </ul>

    <h2 className='text-2xl md:text-3xl text-justify font-bold'>Program Educational Objectives (PEOs)</h2>
        <br />
        <ul className='md:text-lg text-base text-justify font-semibold text-black/70'>
        <br />
        <li><span className='text-black font-bold'>PEO1:</span> &ensp;Apply the Computer Science domain specific knowledge and skills in the growing software and related industries.</li>
        <br />
        <li><span className='text-black font-bold'>PEO2:</span> &ensp;Demonstrate leadership, professional ethics, project management and finance related attributes as employees or employers.</li>
        <br />
        <li><span className='text-black font-bold'>PEO3:</span> &ensp;Demonstrate strong communication skills and ability to function effectively as an individual and part of a team.</li>
        <br />
    </ul>
    </motion.div>
  </div>
  )
}

export default Bscpospo