import React from "react";
import { motion } from "framer-motion";
function Principle() {
  return (
    <motion.section
    initial={{ y:-40,opacity:0 }}
    animate={{ y:0, opacity:1}}
    transition={{ ease: "easeIn", duration: 1 }}
    >
    <div className="font-change px-8 py-8 text-center space-y-10 my-6">
      <h1 className="text-3xl underline underline-offset-8 font-bold md:hidden">
      Principal's Message
      </h1>

      <div className="grid md:grid-cols-2 space-y-10 md:space-y-0">
        <div className="bg-gray-200  flex justify-center items-center flex-col px-5 py-4 rounded-xl m-auto mt-[3rem] relative">
          <img
            src="assets/Contents/dhanal.jpeg"
            className="rounded-md w-[20rem] md:w-[23rem]"
            alt=""
          />
          <span className="mt-3 text-start">
            <h3 className="italic font-bold text-xl">Dr.S.V.Dhanal</h3>
            <p className="italic text-lg">Principal (Engineering Degree)</p>
          </span>
        </div>

        <div className="space-y-10">
          <h1 className="text-4xl underline underline-offset-8 font-bold hidden md:block">
          Principal's Message
          </h1>
          <p className="md:text-lg text-base text-justify md:px-5 font-medium">
            Dear Students!
            <br />
            <br />
            &emsp;Welcome to Metropolitan Institute of Technology & Management
            (MITM).MITM is an institution of academic excellence & achievement,
            and established in year 2011. At MITM, we are imparting the
            engineering education in rural environment to serve the society.
            <br />

            &emsp;We want our students should be imparted an all-round engineering
            education so that they become multidimensional rather than
            unidimensional. Hence, apart from delivering excellent academic
            content and development of skills through practical experiences, the
            students are encouraged to develop interpersonal skills through
            interaction with knowledgeable resource persons. Various modules are
            chalked out to make them "industry ready". Placement of young
            energetic budding students is of prime importance to all of us. A
            systematic development and overall confidence building through
            bridge courses is unique of our institution.
            <br />

            &emsp;Our faculty work hard throughout the year with the students to
            provide them high quality educational experience in the form of
            debates, group discussions, workshops, project exhibition, seminars
            and technical poster competitions.
            <br />

            Our best wishes to the new students joining MITM College.
          </p>
        </div>
      </div>
    </div>

   {/* another priciple */}

    <div className="font-change px-8 py-8 text-center space-y-10 my-6">
      <h1 className="text-3xl underline underline-offset-8 font-bold md:hidden">
      Principal's Message
      </h1>

      <div className="grid md:grid-cols-2 space-y-10 md:space-y-0">

      <div className="bg-gray-200  flex justify-center items-center flex-col px-5 py-4 rounded-xl m-auto mt-[3rem] relative md:hidden">
          <img
            src="assets/Contents/khushe.jpeg"
            className="rounded-md w-[20rem] md:w-[23rem]"
            alt=""
          />
          <span className="mt-3 text-start">
            <h3 className="italic font-bold text-xl">Prof. V. Kushe</h3>
            <p className="italic text-lg">Principal (Polytechnic)</p>
          </span>
        </div>

        <div className="space-y-10">
          <h1 className="text-4xl underline underline-offset-8 font-bold hidden md:block">
          Principal's Message
          </h1>
          <p className="md:text-lg text-base text-justify md:px-5 font-medium">
            Dear Students!
            <br />
            <br />
            &emsp;We welcome you to Diploma Program of Metropolitan Institute of Technology & Management (MITM). The main focus of the Institution is to empower students with sound knowledge, wisdom, experience and training both at the academic level of engineering and in the highly competitive global industrial market.
            <br />
            &emsp;We are conducting various activities in the institutions such as Career Counselling program, Personality Development program, Job Fair, Entrepreneurship Development program, Technical Events & Competitions for overall development of Students. We also arrange various industrial visits & industrial training for students to expose them to industrial environment.
            <br />
            &emsp;The infrastructure facilities and state-of-the-art laboratories combined with a galaxy of competent, talented and dedicated faculty contribute to an enjoyable and an easy learning experience.
            <br />
            You are assured that you will be proud of yourself as a confident and successful engineer after three years at MITM.
            <br />
            We wish all students a successful and rewarding career in engineering field.
          </p>
        </div>

        <div className="bg-gray-200  flex justify-center items-center flex-col px-5 py-4 rounded-xl m-auto mt-[3rem] relative hidden md:block">
          <img
            src="assets/Contents/khushe.jpeg"
            className="rounded-md w-[20rem] md:w-[23rem]"
            alt=""
          />
          <span className="mt-3 text-start">
            <h3 className="italic font-bold text-xl">Prof. V. Kushe</h3>
            <p className="italic text-lg">Principal (Polytechnic)</p>
          </span>
        </div>
      </div>
    </div>
    </motion.section>
  );
}

export default Principle;
