import { motion } from 'framer-motion'
import React from 'react'

function Mou() {
  return (
    <div>
            <motion.div className='md:px-20 px-8 py-10 font-change'
            initial={{ y:-40,opacity:0 }}
            animate={{ y:0, opacity:1}}
            transition={{ ease: "easeIn", duration: 1 }}>
       <h1 className='md:text-4xl text-3xl font-bold mb-8 underline underline-offset-8'>Memorandum of Understanding (MoU)</h1>  
       <ul className='list-disc list-inside md:text-xl  text-base text-justify font-semibold text-black/70'>
        <br />
        <li>Sorting Hat Technologies Private Limited</li>
        <br />
        <li>Testbook Edu Solutions Pvt Ltd</li>
        <br />
        <li>SAPALE AUTO SERVICE PVT .LTD</li>                
        <br />
        <li>Gaurav Pawaskar Government Contractor</li>               
        <br />
        <li>GAD AUTOMOBILE</li>
        <br />            
        <li>A-Tech Consultancy</li>
        <br />
        <li>SINDHU COIR INDUSTRIS</li>
        <br />
        <li>Purshottam Industries, kudal</li>
        <br />
        <li> Amison Fisheris Ltd Malvan , Sindhudurg</li>
        <br />
        <li>Krushnai Contruction , Malvan, Sindhudurg</li>
        <br />
        <li> Hindustan polymer</li>
        <br />
        <li>lead ,Deshpande Foundation , Hubali , Karnataka</li>
        <br />
        <li>One Rebar Civil Engineering Solution ,sindhudurg</li>
        <br />
        <li>Tesseract Solution Private LTD</li>
        <br />
    </ul>
    </motion.div>
    </div>
  )
}

export default Mou