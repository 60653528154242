import React from 'react'
import { Table } from "flowbite-react";
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
function Dmechlabo() {
    const data = [
        {
          imgelink:
            "/assets/Courses/diploma/mechanical/1.jpg",
        },
        {
          imgelink:
            "/assets/Courses/diploma/mechanical/2.jpg",
        },
        {
          imgelink:
            "/assets/Courses/diploma/mechanical/3.jpg",
        },
        {
          imgelink:
          "/assets/Courses/diploma/mechanical/4.jpg",
        },
        {
          imgelink:
          "/assets/Courses/diploma/mechanical/5.jpg",
        },
        {
            imgelink:
            "/assets/Courses/diploma/mechanical/6.jpg",
          },
          {
            imgelink:
            "/assets/Courses/diploma/mechanical/7.jpg",
          },
          {
            imgelink:
            "/assets/Courses/diploma/mechanical/8.jpg",
          },
          {
            imgelink:
            "/assets/Courses/diploma/mechanical/9.jpg",
          },
          {
            imgelink:
            "/assets/Courses/diploma/mechanical/10.jpg",
          },
          {
            imgelink:
            "/assets/Courses/diploma/mechanical/11.jpg",
          },
          {
            imgelink:
            "/assets/Courses/diploma/mechanical/12.jpg",
          },
          {
            imgelink:
            "/assets/Courses/diploma/mechanical/13.jpg",
          },
          {
            imgelink:
            "/assets/Courses/diploma/mechanical/20.jpg",
          },
          {
            imgelink:
            "/assets/Courses/diploma/mechanical/21.jpg",
          },
          {
            imgelink:
            "/assets/Courses/diploma/mechanical/22.jpg",
          },
          {
            imgelink:
            "/assets/Courses/diploma/mechanical/23.jpg",
          },
          {
            imgelink:
            "/assets/Courses/diploma/mechanical/24.jpg",
          },
          {
            imgelink:
            "/assets/Courses/diploma/mechanical/25.jpg",
          },
          {
            imgelink:
            "/assets/Courses/diploma/mechanical/26.jpg",
          },
          {
            imgelink:
            "/assets/Courses/diploma/mechanical/27.jpg",
          },
          {
            imgelink:
            "/assets/Courses/diploma/mechanical/28.jpg",
          },
          {
            imgelink:
            "/assets/Courses/diploma/mechanical/29.jpg",
          },
          {
            imgelink:
            "/assets/Courses/diploma/mechanical/30.jpg",
          },
          {
            imgelink:
            "/assets/Courses/diploma/mechanical/31.jpg",
          },
          {
            imgelink:
            "/assets/Courses/diploma/mechanical/32.jpg",
          },
          {
            imgelink:
            "/assets/Courses/diploma/mechanical/33.jpg",
          },
          {
            imgelink:
            "/assets/Courses/diploma/mechanical/34.jpg",
          },

      ];
     
      const [active, setActive] = React.useState(
        "/assets/Courses/diploma/mechanical/5.jpg",
      );
    
  return (
    <div className='md:px-20 px-8 py-10 font-change'>
       <h1 className='text-4xl underline underline-offset-8 font-bold text-orange-500  mb-8 text-center'>DIPLOMA IN MECHANICAL ENGINEERING</h1>
    <div className='mb-14 mx-auto flex justify-center text-sm md:text-base'>
<Link to='/AboutDmech' className='md:px-8 px-4 py-3 bg-blue-900 hover:bg-blue-800 text-white rounded-l-lg'>About US</Link>
<Link to='/Dmechpospo' className='md:px-8 py-3 px-4 bg-blue-900 hover:bg-blue-800 text-white '>PO PSO PEO</Link>
<Link to='/Dmechlabo' className='md:px-8 py-3 px-4 bg-blue-600 text-white rounded-r-lg'>Laboratories</Link>
    </div>
    <h2 className='text-2xl md:text-3xl text-center font-bold my-6'>Laboratories</h2>
    <motion.div className="overflow-x-auto my-7 border border-gray-200 rounded-xl"
    initial={{ y:-40,opacity:0 }}
    animate={{ y:0, opacity:1}}
    transition={{ ease: "easeIn", duration: 1 }}>
      <Table striped>
        <Table.Head>
          <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg ">Sr.No.</Table.HeadCell>
          <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg">Name of Laboratory</Table.HeadCell>
          <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg">Specifications</Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y text-sm md:text-base font-semibold">
          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell >1</Table.Cell>
            <Table.Cell>Concrete Technology Lab</Table.Cell>
            <Table.Cell>Thermal Conductivity of Metal Rod, Thermal Conductivity of Insulating Powder,Unsteady State Heat Transfer, Heat Transfer by Natural Convection,Emissivity Measurement Apparatus, Heat Transferred through Composite Walls,Parallel & Counter Flow Heat Exchangers</Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell >2</Table.Cell>
            <Table.Cell>Metrology & Quality Engineering Lab</Table.Cell>
            <Table.Cell>Profile Projector, Monochromatic Light Unit, Sine Center,Gear Tooth Vernier, Dial Indicator with Stand</Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell>3</Table.Cell>
            <Table.Cell>Mechatronics Lab</Table.Cell>
            <Table.Cell>PLC Trainer KIT, Pneumatic Trainer KIT, Hydraulic Trainer KIT, Air Compressor,PID Controller, D.C. Motor Control</Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell >4</Table.Cell>
            <Table.Cell>Refrigeration and Air Conditioning Lab</Table.Cell>
            <Table.Cell>Refrigeration Cycle Test RIG, Ice Plant Test RIG,Window Air Conditioning Test RIG</Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell >5</Table.Cell>
            <Table.Cell>CAD/CAM/CAE Lab</Table.Cell>
            <Table.Cell>Computers</Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell>6</Table.Cell>
            <Table.Cell>Dynamics of Machinery Lab</Table.Cell>
            <Table.Cell>Universal Governor, Motorized Gyroscope, Static and Dynamic Balancing,Whirling of Shaft, Universal Vibration Apparatus</Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell>7</Table.Cell>
            <Table.Cell>Engineering Mechanics Lab</Table.Cell>
            <Table.Cell>Parallelogram of force Apparatus, Polygon of force Apparatus, Parallel force Apparatus Dial Type,Bell Crank Lever Apparatus, Friction Slide apparatus, Universal force table apparatus,Combined coil and belt friction apparatus, Moment of inertia of fly wheel</Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell>8</Table.Cell>
            <Table.Cell>Material Testing Lab</Table.Cell>
            <Table.Cell>Electronic Universal Testing Machine, Hardness, Testing Machine, Torsion Testing Machine,Impact Testing Machine, Double Shear Test Apparatus, Mechanical Extensometer,rinocular Microscope, Double Disc Polishing Machine, Jominy End Quench Apparatus, Muffle Furnace</Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell>9</Table.Cell>
            <Table.Cell>Mechanical Measurement & Control Lab</Table.Cell>
            <Table.Cell>Strain Gauge Apparatus, Bourdon Tube Pressure Gauge, Thermocouple Calibration Setup,LVDT Calibration Setup, Dead Weight Pressure Gauge</Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell>10</Table.Cell>
            <Table.Cell>Internal Combustion Engines Lab</Table.Cell>
            <Table.Cell>Three Cylinder Four Stroke Petrol Engine,Single Cylinder Diesel Engine</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </motion.div>

<motion.div className='md:w-[60%] w-[90%] mx-auto'
initial={{ y:-40,opacity:0 }}
animate={{ y:0, opacity:1}}
transition={{ ease: "easeIn", duration: 1 }}>
    <h2 className='text-2xl md:text-3xl text-center font-bold my-6'>Laboratories Photos</h2>
      <div className="grid gap-4">
      <div>
        <img
          className="h-auto w-full max-w-full rounded-lg object-cover object-center md:h-[480px]"
          src={active}
          alt=""
        />
      </div>
      <div className="grid grid-cols-5 gap-4">
        {data.map(({ imgelink }, index) => (
          <div key={index}>
            <img
              onClick={() => setActive(imgelink)}
              src={imgelink}
              className=" max-w-full cursor-pointer rounded-lg object-cover object-center"
              alt="gallery-image"
            />
          </div>
        ))}
      </div>
    </div>
</motion.div>

    </div>
  )
}

export default Dmechlabo