import React from 'react'
import { Table } from "flowbite-react";
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
function Labo() {
    const data = [
        {
          imgelink:
            "/assets/Courses/engineering/computer/1.jpg",
        },
        {
          imgelink:
            "/assets/Courses/engineering/computer/2.jpg",
        },
        {
          imgelink:
            "/assets/Courses/engineering/computer/3.jpg",
        },
        {
          imgelink:
            "/assets/Courses/engineering/computer/4.jpg",
        },
        {
          imgelink:
            "/assets/Courses/engineering/computer/5.jpg",
        },
      ];
     
      const [active, setActive] = React.useState(
        "/assets/Courses/engineering/computer/1.jpg",
      );
    
  return (
    <div className='md:px-20 px-8 py-10 font-change'>
      <h1 className='text-4xl underline underline-offset-8 font-bold text-orange-500  mb-8 text-center'>COMPUTER ENGINEERING</h1>
    <div className='mb-14 mx-auto flex justify-center text-sm md:text-base'>
<Link to='/Aboutcomp' className='md:px-8 px-4 py-3 bg-blue-900 hover:bg-blue-800 text-white rounded-l-lg'>About US</Link>
<Link to='/Pospo' className='md:px-8 py-3 px-4 bg-blue-900 hover:bg-blue-800 text-white '>PO PSO PEO</Link>
<Link to='/Laboratory' className='md:px-8 py-3 px-4 bg-blue-600 text-white rounded-r-lg'>Laboratories</Link>
    </div>
    <h2 className='text-2xl md:text-3xl text-center font-bold my-6'>Laboratories</h2>
    <motion.div className="overflow-x-auto my-7 border border-gray-200 rounded-xl"
    initial={{ y:-40,opacity:0 }}
    animate={{ y:0, opacity:1}}
    transition={{ ease: "easeIn", duration: 1 }}>
      <Table striped>
        <Table.Head>
          <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg ">Sr.No.</Table.HeadCell>
          <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg">Name of Laboratory</Table.HeadCell>
          <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg">Specifications</Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y text-sm md:text-base font-semibold">
          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell >1</Table.Cell>
            <Table.Cell>Linux Lab</Table.Cell>
            <Table.Cell>The students receive hands on practical experience in the field of Operating System, System Administration, System Engineering, Hacking and Security.
Software’s: Ubuntu Operating System, Kali Linux OS, Anaconda, FlawFinder, Wapiti, Proydcrypt, OpenStego, AutoSpy/FTK Imager, Virtual Box</Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell >2</Table.Cell>
            <Table.Cell>Database Lab</Table.Cell>
            <Table.Cell>The Lab is dedicated for Database related practicals. High configuration computer systems installed with softwares like Oracle Server, MySQL are used by the students to execute their Database related projects and assignments.</Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell>3</Table.Cell>
            <Table.Cell>Project Lab</Table.Cell>
            <Table.Cell>The lab is dedicated for the students to develop their Minor and Major projects. All project related software such as Visual Studio, Java etc. are installed in this lab to fulfil the requirements of development.</Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell >4</Table.Cell>
            <Table.Cell>Programming Lab</Table.Cell>
            <Table.Cell>The lab is installed with all important editors like CodeBlock, C++ provides a dual operating system environment where the students can learn to execute C and C++ programs in all types of environments The lab also provides requisite environment for Design and Analysis of Algorithms for solving complex problems in the field of computer science. Students execute all data structure and other algorithm related practicals in the lab. The latest platforms/compilers are provided to the students to run their programs.</Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell >5</Table.Cell>
            <Table.Cell>Web Technology Lab</Table.Cell>
            <Table.Cell>The lab is dedicated for the students to develop their Web Applications Projects. All Web Technology related Experiments are performed & required software’s installed in this lab to fulfil the requirements of development.</Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell>6</Table.Cell>
            <Table.Cell>Network Lab</Table.Cell>
            <Table.Cell>The Lab is Equipped with latest networking related devices, the lab facilitates students to perform Computer Network practicals. All important Network Simulators like Cisco Packet Tracer, NS2, NS3 Wireshark, MRTG Discovery Tool are installed to provide solution to the practical problems faced by computer scientists.</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </motion.div>

<motion.div className='md:w-[60%] w-[90%] mx-auto'
initial={{ y:-40,opacity:0 }}
animate={{ y:0, opacity:1}}
transition={{ ease: "easeIn", duration: 1 }}>
    <h2 className='text-2xl md:text-3xl text-center font-bold my-6'>Laboratories Photos</h2>
      <div className="grid gap-4">
      <div>
        <img
          className="h-auto w-full max-w-full rounded-lg object-cover object-center md:h-[480px]"
          src={active}
          alt=""
        />
      </div>
      <div className="grid grid-cols-5 gap-4">
        {data.map(({ imgelink }, index) => (
          <div key={index}>
            <img
              onClick={() => setActive(imgelink)}
              src={imgelink}
              className=" max-w-full cursor-pointer rounded-lg object-cover object-center"
              alt="gallery-image"
            />
          </div>
        ))}
      </div>
    </div>
</motion.div>

    </div>
  )
}

export default Labo