import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

const images = [
  "/assets/preloader/main.svg",
  "/assets/preloader/1.svg",
  "/assets/preloader/2.svg",
  "/assets/preloader/3.svg",
  "/assets/preloader/4.svg",
  "/assets/preloader/5.svg",
];

const Preloader = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 800);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 4800);

    return () => clearTimeout(timer);
  }, []);

  const hidePreloader = () => {
    setIsLoading(false);
  };

  return (
    <div>
      {isLoading && (
        <motion.div className="fixed bg-white top-0 left-0 w-full h-full flex justify-center flex-col z-50">
          <div>
            <div className="p-4 rounded-md flex flex-col justify-center items-center">
              <div className="flex justify-center">
                <div className="flex justify-center items-center">
                  <motion.img
                    src={images[index]}
                    className="rounded-full border-4 border-white md:w-40 md:h-40 w-32 h-32"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    animate={{
                      opacity: [1, 0],
                      transition: { duration: 1, delay: 4 },
                    }}
                  />
                </div>
              </div>
              <div>
                <motion.h1
                  className="font-change md:text-2xl "
                  animate={{
                    opacity: [1, 0],
                    transition: { duration: 1, delay: 4 },
                  }}
                >
                  Metropolitan Institute of Technology & Management
                </motion.h1>
              </div>
            </div>
            <div className="fixed bottom-0 right-0 m-4">
              <button
                onClick={hidePreloader}
                className=" border border-gray-400 hover:bg-gray-100 hover:scale-105 duration-300 md:py-2 md:px-4 py-2 px-3 rounded mt-4 text-sm md:text-base"
              >
                Hide Preloader
              </button>
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
};


export default Preloader;
