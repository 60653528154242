import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
function Info() {
  return (
    <>
    <section className="lg:h-[30rem] h-auto flex justify-center items-center flex-col lg:flex-row lg:space-x-32 font-change mt-16">
      <motion.div className="flex flex-col md:flex-row  md:justify-center md:items-center bg-blue-100  py-10 md:py-8  md:px-8 rounded-lg shadow-xl"
      initial={{ y:-100,opacity:0}}
      animate={{ y:0, opacity:1}}
      transition={{ ease: "easeIn", duration: 2 }}
      >
      <div className=" my-6 px-3 mt-[-3rem] flex flex-col justify-start  w-[20rem] h-[10rem] py-2 space-y-4">
        <div className="aboutheader flex flex-col md:items-start justify-center items-center">
          <h3 className=" text-[2em]  font-semibold">About <span className="text-blue-600">MITM</span></h3>
          <div className="w-[7.5em] h-[0.17em] ml-[0.2em] bg-blue-600"></div>
        </div>
        <p className="text-justify text-slate-700">MITM fosters quality education and research. Situated in Maharashtra, it offers modern facilities for a conducive learning environment.</p>
        <div className="flex space-x-3 mt-[2.3rem] justify-center md:justify-start">
          <Link to="/Aboutus" type="button" className="border border-gray-600 px-2 py-1 rounded-lg hover:bg-gray-200  hover:scale-110 duration-300">Read more</Link>
          <button type="button" className="border border-slate-700 px-2 py-1 rounded-lg bg-blue-900 hover:bg-blue-800 text-white hover:scale-110 duration-300">Newsletter</button>
        </div>
      </div>
      <div className="mt-12 md:mt-3 lg:m-0 flex justify-center items-center">
        <iframe width="300" height="200" className="rounded-lg shadow-xl mt-[1rem]"  src="https://www.youtube.com/embed/iVdz2IpO7AI?si=HF7idy-SNpYxiJab" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
    </motion.div>

    <motion.div className="lg:w-[27rem] lg:h-[18rem] md:w-[25.5rem] md:h-[17rem] w-[19.5rem] h-[18rem] m-6 mt-[5rem] lg:m-0 p-1  bg-blue-100 lg:px-4 md:py-6 rounded-lg shadow-xl"
    initial={{ y:-100,opacity:0 }}
    animate={{ y:0, opacity:1}}
    transition={{ ease: "easeIn", duration: 2 }}
    >
      <div className="de-header ml-2">
        <h3 className=" sm:text-[2em] text-[1.8rem] font-semibold">Director's <span className="text-blue-600">CORNER</span></h3>
        <div className="w-[7.5em] h-[0.17em] ml-[0.2em] bg-blue-600"></div>
      </div>
      <div className="flex space-x-4 pl-2 justify-center items-center">
      <div>
        <img src="/assets/Contents/kadam.jpg" className="w-[7rem] md:w-[9rem] mt-3 rounded-md " alt=""/>
      </div>
      <div className="w-[50%] md:text-justify space-y-2 text-sm md:text-base md:p-2">
        <h2 className="font-semibold text-lg">Mr.Ketan Kadam</h2>
        <p className="text-slate-700 text-sm text-justify">I'm delighted to witness our engineering community's pursuit of excellence. Let's innovate together, shaping a future of integrity and collaboration. Warm regards, Mr. Ketan Kadam, Director, MITM.</p>
        
      </div>
    </div>
    </motion.div>

    

    </section>
    </>
  )
}

export default Info