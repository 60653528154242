import React from 'react'

function Links() {
  return (
    <section>
    <div class="links m-6 text-center font-change mt-16">
      <div class="relative flex justify-center items-center ">
        <h3 class=" text-[2em] font-semibold">IMPORTANT <span class="text-blue-600">LINKS</span></h3>
        <div class="w-[7.5em] h-[0.17em] mt-16 bg-blue-600 absolute"></div>
      </div>
      <div class="lg:flex my-6 grid grid-cols-2 md:grid-cols-3  space-y-6 lg:space-y-0">
      <a href="https://msbte.org.in/" target='_blank' class="item1 m-auto  lg:w-[12rem] lg:h-[12rem] w-[8rem] h-[8rem] bg-white rounded-full shadow-xl flex justify-center items-center hover:scale-110 duration-300">
        <img src="assets/links/msbt.jpg" class="lg:w-[8rem] lg:hover:w-[8.5rem] w-[4rem] hover:w-[4.5rem]" alt=""/>
      </a>

      <a href='https://mu.ac.in/portal/' target='_blank' class="item1 m-auto  lg:w-[12rem] lg:h-[12rem] w-[8rem] h-[8rem] bg-white rounded-full shadow-xl flex justify-center items-center  hover:scale-110 duration-300">
        <img src="assets/links/m_u.jpg" class="lg:w-[8rem] lg:hover:w-[8.5rem] w-[4rem] hover:w-[4.5rem]" alt=""/>
      </a>

      <a href='https://dte.maharashtra.gov.in/' target='_blank' class="item1 m-auto  lg:w-[12rem] lg:h-[12rem] w-[8rem] h-[8rem] bg-white rounded-full shadow-xl flex justify-center items-center  hover:scale-110 duration-300">
        <img src="assets/links/dte.jpg" class="lg:w-[8rem] lg:hover:w-[8.5rem] w-[4rem] hover:w-[4.5rem]" alt=""/>
      </a>

      <a href='https://mahadbt.maharashtra.gov.in/' target='_blank' class="item1 m-auto  lg:w-[12rem] lg:h-[12rem] w-[8rem] h-[8rem] bg-white rounded-full shadow-xl flex justify-center items-center  hover:scale-110 duration-300">
        <img src="assets/links/maha_e.jpg" class="lg:w-[8rem] lg:hover:w-[8.5rem] w-[4rem] hover:w-[4.5rem]" alt=""/>
      </a>

      <a href='https://www.aicte-india.org/index.php' target='_blank' class="item1 m-auto lg:w-[12rem] lg:h-[12rem] w-[8rem] h-[8rem] bg-white rounded-full shadow-xl flex justify-center items-center  hover:scale-110 duration-300">
        <img src="assets/links/aict.jpg" class="lg:w-[8rem] lg:hover:w-[8.5rem] w-[4rem] hover:w-[4.5rem]" alt=""/>
      </a>

      <a href='https://swayam.gov.in/' target='_blank' class="item1 m-auto  lg:w-[12rem] lg:h-[12rem] w-[8rem] h-[8rem] bg-white rounded-full shadow-xl flex justify-center items-center  hover:scale-110 duration-300">
        <img src="assets/links/swayam.png" class="lg:w-[8rem] lg:hover:w-[8.5rem] w-[4rem] hover:w-[4.5rem]" alt=""/>
      </a>
    </div>
    </div>
    </section>
  )
}

export default Links