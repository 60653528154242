import React, { useState } from "react";
import { Input, Textarea, Button, Checkbox } from "@material-tailwind/react";
import { Toaster, toast } from "react-hot-toast";
import { motion } from "framer-motion";

function Almaconnect() {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [graduationYear, setGraduationYear] = useState("");
  const [program, setProgram] = useState("");
  const [cityState, setCityState] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [result, setResult] = React.useState("");

  const validateEmail = (email) => {
    // Email validation regex
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "5b5f91bd-72a8-42c0-8032-baac39f27a71");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    });

    const data = await response.json();

    if (data.success) {
      toast.success("Form submitted successfully! Thank you for registering!");
      event.target.reset(); // Reset the form
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };

  return (
    <div className="relative lg:h-[150vh] md:h-[70vh] h-[180vh] font-change mt-1"
    >
         <Toaster position="top-center" reverseOrder={false} />
      {/* Background image with blur and dark overlay */}
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage: `url('/assets/logos/bg-Alumni.jpg')`,
          filter: "blur(4px)", // Adjust the blur intensity as needed
        }}
      ></div>
      <div className="absolute inset-0 bg-black opacity-50"></div>{" "}
      {/* Dark overlay */}
      {/* Content inside the container */}
      <motion.div className="absolute inset-0 flex justify-start items-center "
          initial={{ y:-40,opacity:0 }}
          animate={{ y:0, opacity:1}}
          transition={{ ease: "easeIn", duration: 1 }}>
        <div className="bg-white/95 shadow-2xl px-5 py-5 lg:w-[40vw] md:my-5 my-3 md:mx-14 mx-6 rounded-md">
          <h1 className="md:text-3xl text-2xl font-bold text-center">
            Alumni Registration Form
          </h1>
          <br />
          <p className="md:text-lg text-black/55 font-semibold">
            &ensp;&ensp;Welcome back to MITM, Sindhudurg. We're thrilled to
            connect with you and keep you updated on all things alumni. Please
            take a few minutes to fill out this form and reconnect with your
            alma mater.
          </p>
          <br />
          <form className=" space-y-4" onSubmit={onSubmit}>
            <Input
              label="Full Name:"
              name="Full name :"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
            />
            <Input
              label="Email Address:"
              value={email}
              name="Email Address:"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <Input
              label="Phone Number (optional):"
              value={phone}
              name="Phone Number :"
              onChange={(e) => setPhone(e.target.value)}
            />
            <Input
              label="Year of Graduation:"
              name="Year of Graduation:"
              value={graduationYear}
              onChange={(e) => setGraduationYear(e.target.value)}
              required
            />
            <Input
              label="Degree/Program:"
              name="Degree/Program:"
              value={program}
              onChange={(e) => setProgram(e.target.value)}
              required
            />
            <Input
              label="Current City & State:"
              name="Current City & State:"
              value={cityState}
              onChange={(e) => setCityState(e.target.value)}
              required
            />

            {/* Optional Information */}
            <div className="mt-4 space-y-4">
              <Input
                label="Current Employer (optional):"
                name="Current Employer (optional):"
                placeholder="Enter your current employer"
              />
              <Input label="Job Title (optional):" />
              <Textarea
              className="!outline-none !ring-0"
                label="Share a fond memory of your time at [School Name] (optional):"
              />
              <div className="flex items-center">
                <label className="mr-2">
                  Would you like to be connected with other alumni in your
                  field?{" "}
                </label>
                <Checkbox className="!outline-none !ring-0" type="checkbox" name="Would you like to be connected with other alumni in your
                  field: (Yes)" />
              </div>
              <div className="flex items-center">
                <label className="mr-2">
                  Are you interested in volunteering or mentoring current
                  students?
                </label>
                <Checkbox className="!outline-none !ring-0" type="checkbox"  name="Are you interested in volunteering or mentoring current
                  students: (Yes)"/>
              </div>
            </div>

            {/* Submit button */}
            <div className="flex justify-center items-center"> 
            <Button
              type="submit"
              color="blue"
              buttonType="filled"
              size="regular"
              rounded={false}
              block={false}
              ripple="light"
              className="mt-4 w-full text-lg bg-[#120b33]"
            >
              Submit
            </Button>
            </div>
          </form>
          {alertMessage && (
            <div className="mt-4 text-red-500">{alertMessage}</div>
          )}
          <div className="mt-5 ">
            <h3 className="text-lg font-bold">Privacy</h3>
            <p className="md:text-base text-black/55 ">
              We will never share your personal information with any third party
              without your consent. You can unsubscribe from our communications
              at any time.
            </p>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default Almaconnect;
