import React from 'react'
import { motion } from 'framer-motion'
function Eligibility() {
  return (
    <motion.div className='md:px-[5rem] px-8 font-change py-12 flex md:space-x-8 md:flex-row flex-col'
    initial={{ y:-40,opacity:0 }}
    animate={{ y:0, opacity:1}}
    transition={{ ease: "easeIn", duration: 1 }}
    >
        <div >
          <div>
                <h2 className='text-2xl md:text-3xl text-center font-bold'>Eligibility Criteria for First Year Degree Engineering (B.E./B.Tech)Admission</h2>
            <ul className='list-decimal md:text-lg text-base text-justify'>
                <br />
                <li>Passed HSC or its equivalent examination with Physics and Mathematics as compulsory subjects along with one of the Chemistry or Biotechnology or Biology or Technical or Vocational subjects, and obtained at least 45% marks (at least 40% marks, in case of Backward class categories and Persons with Disability candidates belonging to Maharashtra State only) in the above subjects taken together; and Obtained score in MHT-CET or JEE (Main) 2023.</li>
                <br />
                <p className='text-center text-xl font-semibold'>OR</p>
                <br />
                <li>Passed Diploma in Engineering and Technology and obtained at least 45 % marks (at least 40 % marks, in case of Backward class categories and Persons with Disability candidates belonging to Maharashtra State only);.</li>
                <br />
                <p className='text-center text-xl font-semibold'>OR</p>
                <br />
                <li>Passed B.Sc. Degree from a Recognized University as defined by UGC. and obtained at least 50 % marks (at least 45% marks, in case of Backward class categories and Persons with Disability candidates belonging to Maharashtra State only) and passed XII standard with Mathematics as a subject;</li>
            <br />
            </ul>
            <p className='md:text-lg text-base text-justify text-red-700'>Note: Any other changes in eligibility criteria as declared from time to time by the competent authority.</p>
          </div>

          <div className='bg-[#f2f0f0] h-[1px] w-full shadow-2xl my-4'></div>

          <div>
            <br />
                <h2 className='text-2xl md:text-3xl text-center font-bold'>CRITERIA FOR DIRECT SECOND YEAR ENGINEERING</h2>
            <ul className='list-decimal md:text-lg text-base text-justify'>
                <br />
                <li>Passed Diploma Course in Engineering and Technology with at least 45% marks (40% marks in case of candidates of backward class categories and Persons with Disability belonging to Maharashtra State only) in appropriate branch of Engineering and Technology from an All India Council for Technical Education or Central or State Government approved Institution or its equivalent.</li>
                <br />
                <p className='text-center text-xl font-semibold'>OR</p>
                <br />
                <li>Passed B.Sc. Degree from a University Grants Commission (UGC) or Association of Indian Universities recognized University with at least 45% marks (40% in case of candidates of Backward class categories and Persons with Disability belonging to Maharashtra State only) and passed HSC with Mathematics as a subject.</li>
                <br />
                <li>Provided that students belonging to this category shall clear the subjects of Engineering Graphics/ Engineering Drawing and Engineering Mechanics of the first year Engineering Program along with second year subjects.</li>
            <br />
            </ul>
          </div>

          <div className='bg-[#f2f0f0] h-[1px] w-full shadow-2xl my-4'></div>

          <div>
            <br />
                <h2 className='text-2xl md:text-3xl text-center font-bold'>Eligibility Criteria for Direct Second Year Diploma Engineering</h2>
            <ul className=' md:text-lg text-base text-center'>
                <br />
                <li>Passed 10+2 examination with Physics and Chemistry as compulsory subjects along with Mathematics/Biology subject.</li>
                <br />
                <p className='text-center  text-xl font-semibold'>OR</p>
                <br />
                <li>10+2 Science (with mathematics as one of the Subject)</li>
                <br />
                <p className='text-center  text-xl font-semibold'>OR</p>
                <br />
                <li>10+2 Science with Technical</li>                
                <br />
                <p className='text-center text-xl font-semibold'>OR</p>
                <br />
                <li>10+2 with Vocational.</li>               
                <br />
                <p className='text-center  text-xl font-semibold'>OR</p>
                <br />
                <li>10 th + (2 years ITI) with appropriate Trade.</li>
            <br />
            </ul>
          </div>

          <div className='bg-[#f2f0f0] h-[1px] w-full shadow-2xl my-4'></div>

          <div>
            <br />
                <h2 className='text-2xl md:text-3xl text-center font-bold'>Eligibility Criteria for First Year B.Sc Admission</h2>
            <ul className=' md:text-lg text-base text-justify'>
                <br />
                <li>A candidate for being eligible for admission to the degree course of Bachelor of Science-Information Technology and Bachelor of Science-Computer Science, should have passed XII Standard examination of the Maharashtra Board of Higher Secondary Education or its equivalent with Mathematics as one of the subjects and should have secured not less than 45% marks in aggregate for Open Category students and 40% marks in aggregate in case of Reserved category students.</li>
                <br />
            </ul>
          </div>

          <div className='bg-[#f2f0f0] h-[1px] w-full shadow-2xl my-4'></div>

<div>
  <br />
      <h2 className='text-2xl md:text-3xl text-center font-bold'>Eligibility Criteria for Direct Second Year B.Sc Admission </h2>
  <ul className=' md:text-lg text-base text-justify'>
      <br />
      <li>A student who has passed Diploma in Computer Engineering /Computer Science/Computer Technology/ Information Technology/Electrical, Electronics & Video Engineering and allied branches/Mechanical and allied branches, civil and allied branches of Engineering after S.S.C. (Xth Standard) is eligible for direct admission to the Second Year of the B.Sc. (I.T.) and B.Sc (Computer Science) degree course. However, the Diploma should be recognized by the Board of Technical Education or any other recognized Government Body. Minimum marks required are 45% aggregate for Open Category student and 40% aggregate for student belonging to reserved category .</li>
      <br />
  </ul>
</div>
</div>
<div className='w-[82vw] md:w-[50vw] h-[80%] bg-gray-200 px-3 py-3 rounded-md'>
<iframe src="https://docs.google.com/forms/u/2/d/e/1FAIpQLSe1S-fvJQO0qZ7TaoQpj_UK41qsj8-5lE7CfIgpzK1OBumorQ/viewform" className='w-[75vw] md:max-w-[25vw]' width="450" height="850" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
</div>
    </motion.div>
  )
}

export default Eligibility