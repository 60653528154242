import { motion } from 'framer-motion'
import React from 'react'

function Desk() {
  return (
    <motion.div className="font-change px-8 py-8 text-center space-y-10 my-6"
    initial={{ y:-40,opacity:0 }}
          animate={{ y:0, opacity:1}}
          transition={{ ease: "easeIn", duration: 1 }}>
    
    <h1 className='text-3xl underline underline-offset-8 font-bold md:hidden'>Training & Placement Coordinator Cell</h1>

      <div className="grid md:grid-cols-2 space-y-10 md:space-y-0">
        <div className="bg-gray-200  flex justify-center items-center flex-col px-5 py-4 rounded-xl m-auto mt-[5rem] relative">
          <img
            src="assets/T&p/walke.jpeg"
            className="rounded-md w-[20rem] md:w-[23rem]"
            alt=""
          />
          <span className="mt-3 text-start">
            <h3 className="italic font-bold text-xl">Mrs. Bhagyashri Walke</h3>
            <p className="italic text-lg">
            (Training & Placement Officer)
            </p>
          </span>
        </div>

      <div className="space-y-10">
      <h1 className='text-4xl underline underline-offset-8 font-bold hidden md:block'>Training & Placement Coordinator Cell</h1>
        <p className="md:text-lg text-base text-justify md:px-5 font-medium">
        &emsp;&emsp;All our efforts are aimed at acquiring best possible employability and entrepreneurship skills by our Students. Accordingly we are organizing various Training Programs both by Industry experts as well as Professionals, to equip the Students with soft-skills, Interview techniques, Group Discussion skills, Personality Development skills, Written / Aptitude / on-line test skills etc. This systematic way of molding has clearly reflected in maximizing Placements of our Students in various top MNCs/ Foreign Companies/ Corporates in India and abroad.
          <br />
          <br />
          &emsp;&emsp;In the present era of globalization, close and long -term association with the Industry, Research, Patents, Consultancy, Entrepreneurship, MoUs, Certifications, Accreditations, Faculty Development Programs, live Projects and High Academic performance of the students are of paramount importance to any reputed Technical Institute Training.
          <br />
          <br />
          &emsp;&emsp;Besides the high academic standards, Students need to be properly groomed. They are required to be trained and tailored as per the requirement of the current Industry expectations. We train and mold the Students with the help of professionals / Industry experts, in Aptitude Tests, Soft-skills / Group Discussions / Interview techniques and Personality Development.
          <br />
        </p>
      </div>
      </div>
      
      <div className='bg-[#f2f0f0] h-[1px] w-full shadow-2xl mt-10'></div>
      <div className='font-change  my-7 space-y-20 '>
       
       <div className='space-y-8 text-center'>
        <h1 className='text-3xl underline underline-offset-8 font-bold'>Vision</h1>
        <p className=' md:text-lg text-base'>To enhance employability skills of the students and employment opportunities to meet the expectations of the employers.</p>
       </div>

       <div className='space-y-8'>
       <h1 className='text-3xl underline underline-offset-8 font-bold'>Mission</h1>
         <ul className='list-disc list-inside space-y-6 md:text-lg text-base'>
            <li>To provide skill oriented training to meet the need of the industry.</li>
            <li>To Organize guest lectures, seminars and workshops to enhance employability skills of students by industry experts.</li>
            <li>To Collaborate with various industries to organize recruitment drives.</li>
            <li>To motivate students to develop their overall personality in terms of career planning, higher studies and entrepreneurship.</li>
         </ul>
       </div>

    </div>
    </motion.div>
  )
}

export default Desk