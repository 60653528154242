import React from "react";
import { motion } from "framer-motion";
function Chairman() {
  return (
    <motion.div className="font-change px-8 py-8 text-center space-y-10 my-6"
    initial={{ y:-40,opacity:0 }}
    animate={{ y:0, opacity:1}}
    transition={{ ease: "easeIn", duration: 1 }}
    >
    
    <h1 className='text-3xl underline underline-offset-8 font-bold md:hidden'>President 's Message</h1>

      <div className="grid md:grid-cols-2 space-y-10 md:space-y-0">
        <div className="bg-gray-200  flex justify-center items-center flex-col px-5 py-4 rounded-xl m-auto mt-[5rem] relative">
          <img
            src="assets/Contents/chairman.jpg"
            className="rounded-md w-[20rem] md:w-[23rem]"
            alt=""
          />
          <span className="mt-3 text-start">
            <h3 className="italic font-bold text-xl">Mr. Santosh Pal</h3>
            <p className="italic text-lg">
              President Jayawanti Babu Foundation, Mumbai
            </p>
          </span>
        </div>

      <div className="space-y-10">
      <h1 className='text-4xl underline underline-offset-8 font-bold hidden md:block'>President 's Message</h1>
        <p className="md:text-lg text-base text-justify md:px-5 font-medium">
        &emsp;&emsp;I welcome you all with great pleasure to Metropolitan Institute of
          Technology & Management (MITM).
          <br />
          &emsp;&emsp;Education is the harmonious development of the physical, mental,
          spiritual and social faculties. It is the process of awakening the
          thirst for knowledge and kindling the inquisitive spirit that lead to
          the overall development of students. Students are the very foundation
          of the future of our nation. We, at MITM, are committed in the
          coordinated efforts of the management, parents, teachers and the
          students in building this strong foundation.
          <br />
          &emsp;&emsp;The engineer’s main task is to apply his scientific knowledge to the
          solution of technical problems, then to optimize solution within the
          given material, technological, economic, social and environmental
          constraints for the utility, comfort and advancement of mankind.
          <br />
          &emsp;&emsp;Our Faculty of Engineering is committed to creating an environment
          where people thrive and contribute to improving the quality of life in
          national and global communities, as well as enhancing the wealth
          creation of the nation, through excellence in teaching, research and
          service. We inculcate in the students that acquiring knowledge and
          developing the capability to learn new things each day is of prime
          importance in one’s career success.
          <br />
          &emsp;&emsp;Along with the technical and engineering skills, character building is
          given equal importance, so that the budding engineers can face the
          realities of the world confidently and contribute positively to the
          welfare of the society.
          <br />
          &emsp;&emsp;We aim to lend a gentle guiding hand so that our students recognize
          their inner potential and grow of their own accord into stalwarts of
          tomorrow’s society. An alumni of MITM is sure to be knowledgeable,
          confident and fully equipped to deal with the real world and excel.
          We, at MITM, believe in excellence as a way of life and continuously
          strive to achieve greater heights in the field of technical education.
          Let it be my privilege to welcome you again to the world MITM.
        </p>
      </div>
      </div>
    </motion.div>
  );
}

export default Chairman;
