import { motion } from "framer-motion";
import React from "react";

function AboutCampus() {
  return (
    <motion.div className="md:px-20 px-8 py-10 font-change"
    initial={{ y:-40,opacity:0 }}
          animate={{ y:0, opacity:1}}
          transition={{ ease: "easeIn", duration: 1 }}>
      <div className="font-semibold">
        <h1 className="text-4xl  font-bold mb-8 text-center">About Campus</h1>
        <br />
        <p className="md:text-lg text-base text-justify text-black/70">
          {" "}
          &ensp;&ensp;Located in the heart of the city, our esteemed engineering
          college stands as a beacon of academic excellence and holistic
          development. Spread across a vast expanse of lush greenery, our campus
          encompasses five distinguished branches: Computer Engineering, Civil
          Engineering, Mechanical Engineering, Electronics & Telecommunication
          Engineering, and a dedicated building for our Diploma programs. Each
          branch building is equipped with state-of-the-art laboratories,
          cutting-edge classrooms, and collaborative spaces, fostering an
          environment conducive to innovation and learning.
          <br />
          At the core of our campus infrastructure lies a commitment to
          providing students with unparalleled facilities. Our campus features a
          modern gymnasium equipped with the latest fitness equipment, catering
          to the health and wellness needs of our students and faculty alike.
          Additionally, our canteen serves as a bustling hub of culinary
          delights, offering a diverse range of nutritious meals and snacks to
          keep our campus community energized throughout the day.
          <br />
          One of the hallmarks of our institution is our unwavering dedication
          to connectivity and accessibility. With comprehensive internet
          facilities available across the campus, students have seamless access
          to online resources, research databases, and academic portals,
          enabling them to stay connected and engaged with their studies at all
          times.
          <br />
          For the sports enthusiasts among us, our campus boasts a sprawling
          sports ground, providing ample space for various outdoor activities
          and sporting events. Whether it's football, cricket, or athletics, our
          sports facilities cater to the diverse interests and passions of our
          student body, promoting a healthy and active lifestyle.
          <br />
          Amidst the hustle and bustle of academic pursuits, our campus offers
          serene pockets of tranquility, allowing students to unwind and
          recharge amidst nature's embrace. With abundant greenery, scenic
          walkways, and cozy seating areas scattered throughout the campus,
          students find solace and inspiration in the beauty of their
          surroundings.
          <br />
          Furthermore, our commitment to convenience extends to our parking
          facilities, ensuring hassle-free commuting for students and faculty
          members alike. With designated parking areas strategically located
          around the campus, transportation woes are kept at bay, allowing
          everyone to focus on what truly matters – their academic and personal
          growth.
          <br />
          In essence, our campus embodies the perfect blend of academic rigour,
          modern amenities, and a vibrant community spirit. It is not merely a
          place of learning but a home away from home, where students forge
          lifelong friendships, pursue their passions, and embark on
          transformative journeys of self-discovery. Welcome to our campus,
          where every corner tells a story of innovation, excellence, and
          endless possibilities.
        </p>
      </div>
    </motion.div>
  );
}

export default AboutCampus;
