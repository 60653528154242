import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
function Aboutcivil() {
  return (
    <div className='md:px-20 px-8 py-10 font-change'>
        <h1 className='text-4xl underline underline-offset-8 font-bold text-orange-500  mb-8 text-center'>CIVIL ENGINEERING</h1>
        <div className='mb-14 mx-auto flex justify-center text-sm md:text-base'>
        <Link to='/Aboutcivil' className='md:px-8 py-3 px-4 bg-blue-600 text-white rounded-l-lg '>About US</Link>
        <Link to='/Civilpospo' className='md:px-8 py-3 px-4 bg-blue-900 hover:bg-blue-800 text-white '>PO PSO PEO</Link>
        <Link to='/Civillabo' className='md:px-8 py-3 px-4 bg-blue-900 hover:bg-blue-800 text-white rounded-r-lg'>Laboratories</Link>
        </div>
        <motion.div className="font-semibold"
        initial={{ y:-40,opacity:0 }}
        animate={{ y:0, opacity:1}}
        transition={{ ease: "easeIn", duration: 1 }}>
        <h2 className="text-2xl md:text-3xl text-justify font-bold ">
          Department at a Glance
        </h2>
        <br />
        <p className="md:text-lg text-base text-justify text-black/70">
          {" "}
          &ensp;&ensp;Considered as one of the oldest engineering disciplines,
          Civil Engineering involves planning, designing and executing
          structural works. The profession deals with a wide variety of
          engineering tasks including designing, supervision and construction
          activities of public works like roads, bridges, tunnels, buildings,
          airports, dams, water works, sewage systems, ports etc. and offers a
          multitude of challenging career opportunities. A civil engineer is
          responsible for planning and designing a project, constructing the
          project to the required scale, and maintenance of the product. A civil
          engineer requires not only a high standard of engineering knowledge
          but also supervisory and administrative skills. The planning part of
          their work involves site investigation, feasibility studies, creating
          solutions to complications that may occur and the actual designing of
          structures. They have to work with the guidelines of the local
          government authority and get plans approved by the relevant authority.
        </p>
        <br />
        <h2 className="text-2xl md:text-3xl text-justify font-bold ">Vision</h2>
        <br />
        <p className="md:text-lg text-base text-justify text-black/70">
          To be recognized for excellence in education strengthened with
          Industry-Institute interaction in the field of Civil Engineering.
        </p>
        <br />
        <h2 className="text-2xl md:text-3xl text-justify font-bold ">
          Mission
        </h2>
        <br />
        <p className="md:text-lg text-base text-justify text-black/70">
          To impart value based technical education, fortify research and
          enhance consultancy services with emphasis on mutual respect amongst
          the stakeholders.
        </p>
      </motion.div>
        </div>
  )
}

export default Aboutcivil