import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../styles/navbar.css";
import Course from "./navbar_items/Course";
import Student from "./navbar_items/Student";
import About from "./navbar_items/About";
import Admission from "./navbar_items/Admission";
import Infrastructure from "./navbar_items/Infrastructure";
import Facilities from "./navbar_items/Facilities";
import Alumni from "./navbar_items/Alumni";
import Events from "./navbar_items/Events";
import Placement from "./navbar_items/Placement";
import Exam from "./navbar_items/Exam";
import { motion, AnimatePresence } from 'framer-motion';

function Navbar(props) {
  const [isHidden, setIsHidden] = useState(true);
  const [isMoved, setIsMoved] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const changeClass = () => {
    setIsHidden(!isHidden);
    setIsMoved(!isMoved);
  };



  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = 350;
      if (window.scrollY > scrollThreshold) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleNavbarItemClick = () => {
    setIsHidden(true);
    setIsMoved(false);
    window.scrollTo(0, 0);
  };


  return (
    <section id="navbar">
      <nav
        id="scrollingComponent"
        className={`${
          isScrolled
            ? "fixed top-0 w-[100%] h-[3rem] bg-blue-700 z-10 text-xs shadow-xl"
            : "h-[2.8rem] bg-blue-700 shadow-xl  top-0 transition duration-700 ease-in-out "
        }`}
      >
        <div className="flex items-center justify-start xlc:order-2 m-2 xlc:hidden  z-10 h-6 w-6">
        <div className="hamburger cursor-pointer mt-3" onClick={changeClass}>
            {isMoved ? (
              <svg className="h-6 w-6 text-white hover:scale-110 duration-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            ) : (
              <svg className="h-6 w-6 text-white hover:scale-110 duration-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            )}
          </div>
        </div>

        <ul className="flex flex-row justify-center font-change text-white ">
          {/* Nav Logo */}
          <div id="navlogo" className={`${isScrolled ? "block top-0 fixed xlc:relative " : "hidden "}`}>
            <span className="h-[5rem] w-[5rem]  z-20 shadow-lg bg-blue-700 rounded-full flex justify-center items-center hover:scale-105 duration-300">
              <Link to="/" id="navlogo" className="h-[4.5rem] w-[4.5rem]  z-30 shadow-lg bg-white rounded-full flex justify-center items-center hover:scale-105 duration-300">
                <img src="assets/header/mitm_logo.png" className="h-[4rem] hover:h-[4rem] cursor-pointer" alt="" />
              </Link>
            </span>
          </div>


          {/* Nav Items */}

          <div
            id="navitems"
            className={`${
              isScrolled
                ? `xlc:ml-[2rem] xlc:space-x-12 text-xs xlc:mt-3 mt-3  xlc:block ${isHidden ? "hidden " : ""}`
                : `flex justify-center items-center z-10 xlc:space-x-12 xlc:block ${isHidden ? "hidden " : ""} xlc:bg-none right-0  xl:mt-2`
            }`}

          >
            <Link to="/" className="" onClick={handleNavbarItemClick}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                className="w-6 h-6 mt-1 fill-white cursor-pointer hover:animate-pulse hover:scale-110 duration-300 hidden xlc:block"
                width="50"
                height="50"
                viewBox="0 0 50 50"
              >
                <path d="M 25 1.0507812 C 24.7825 1.0507812 24.565859 1.1197656 24.380859 1.2597656 L 1.3808594 19.210938 C 0.95085938 19.550938 0.8709375 20.179141 1.2109375 20.619141 C 1.5509375 21.049141 2.1791406 21.129062 2.6191406 20.789062 L 4 19.710938 L 4 46 C 4 46.55 4.45 47 5 47 L 19 47 L 19 29 L 31 29 L 31 47 L 45 47 C 45.55 47 46 46.55 46 46 L 46 19.710938 L 47.380859 20.789062 C 47.570859 20.929063 47.78 21 48 21 C 48.3 21 48.589063 20.869141 48.789062 20.619141 C 49.129063 20.179141 49.049141 19.550938 48.619141 19.210938 L 25.619141 1.2597656 C 25.434141 1.1197656 25.2175 1.0507812 25 1.0507812 z M 35 5 L 35 6.0507812 L 41 10.730469 L 41 5 L 35 5 z"></path>
              </svg>
            </Link>

            {/* Subitems */}
            <div
              id="subitems"
              className="flex xl:justify-center xl:items-start flex-col justify-start xlc:flex-row xlc:h-0 space-y-1 md:space-y-2 xlc:space-y-0  md:pt-[2.25rem] xlc:p-0 xlc:mt-[-32px] font-semibold xlc:bg-blue-600 bg-blue-900 xlc:bg-none w-[100vw] xlc:w-auto  menu text-sm transition duration-300 ease-in-out origin-top"
           
           >
              <About onclick={handleNavbarItemClick} />
              <span className="w-full h-[0.5px] bg-gray-600 xlc:hidden" />

              <Admission onclick={handleNavbarItemClick} />
              <span className="w-full h-[0.5px] bg-gray-600 xlc:hidden" />

              <div className=" inline-block text-base ">
                <Link to="/Payments" className= {`${isScrolled ?  "md:hidden outline-none focus:outline-none  px-4 xlc:px-3 py-2 text-start xlc:text-center rounded-sm flex items-center min-w-32 hover:bg-blue-800" : "outline-none focus:outline-none  px-4 xlc:px-3 py-2 text-start xlc:text-center rounded-sm flex items-center min-w-32 hover:bg-blue-800" }`}  onClick={handleNavbarItemClick}>
                  <span className="pr-1 font-semibold  flex-1 ">Payments</span>
                </Link>
              </div>
              <span className="w-full h-[0.5px] bg-gray-600 xlc:hidden" />

              <Course onclick={handleNavbarItemClick} />
              <span className="w-full h-[0.5px] bg-gray-600 xlc:hidden" />

              <Infrastructure onclick={handleNavbarItemClick} />
              <span className="w-full h-[0.5px] bg-gray-600 xlc:hidden" />

              <Facilities onclick={handleNavbarItemClick} />
              <span className="w-full h-[0.5px] bg-gray-600 xlc:hidden" />

              <Alumni onclick={handleNavbarItemClick} />
              <span className="w-full h-[0.5px] bg-gray-600 xlc:hidden" />

              <Student onclick={handleNavbarItemClick} />
              <span className="w-full h-[0.5px] bg-gray-600 xlc:hidden" />

              <Events onclick={handleNavbarItemClick} />
              <span className="w-full h-[0.5px] bg-gray-600 xlc:hidden" />

              <Placement onclick={handleNavbarItemClick} />
              <span className="w-full h-[0.5px] bg-gray-600 xlc:hidden" />

              <Exam onclick={handleNavbarItemClick} />
              <span className="w-full h-[0.5px] bg-gray-600 xlc:hidden" />


              <div className=" inline-block text-ms ">
                <Link to="/Contact" className="outline-none focus:outline-none  px-4 xlc:px-3 py-2 text-start xlc:text-center rounded-sm flex items-center min-w-32 hover:bg-blue-800 "onClick={handleNavbarItemClick}>
                  <span className="pr-1 font-semibold  flex-1 ">Contact</span>
                </Link>
              </div>
              <span className="w-full h-[0.5px] bg-gray-600 xlc:hidden" />

              <div id="search" className={`${isScrolled ? "xl:block hidden" : "hidden"}`}>
                <span className="cursor-pointer hover:bg-blue-800 hover:scale-110 duration-300 mx-3 p-1 w-[2.2rem] h-[2.2rem] hover:rounded-full flex justify-center items-center ">
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50" className="fill-white" onClick={props.search}>
                    <path d="M 21 3 C 11.621094 3 4 10.621094 4 20 C 4 29.378906 11.621094 37 21 37 C 24.710938 37 28.140625 35.804688 30.9375 33.78125 L 44.09375 46.90625 L 46.90625 44.09375 L 33.90625 31.0625 C 36.460938 28.085938 38 24.222656 38 20 C 38 10.621094 30.378906 3 21 3 Z M 21 5 C 29.296875 5 36 11.703125 36 20 C 36 28.296875 29.296875 35 21 35 C 12.703125 35 6 28.296875 6 20 C 6 11.703125 12.703125 5 21 5 Z"></path>
                  </svg>
                </span>
              </div>
            </div>
          </div>


        </ul>
        
        <div id="search" className={`${isScrolled ? "xlc:hidden fixed right-0 top-0 mt-2" : "hidden"}`}>
                <span className="cursor-pointer hover:bg-blue-800 hover:scale-110 duration-300 mx-3 p-1 w-[2.2rem] h-[2.2rem] hover:rounded-full flex justify-center items-center ">
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50" className="fill-white" onClick={props.search}>
                    <path d="M 21 3 C 11.621094 3 4 10.621094 4 20 C 4 29.378906 11.621094 37 21 37 C 24.710938 37 28.140625 35.804688 30.9375 33.78125 L 44.09375 46.90625 L 46.90625 44.09375 L 33.90625 31.0625 C 36.460938 28.085938 38 24.222656 38 20 C 38 10.621094 30.378906 3 21 3 Z M 21 5 C 29.296875 5 36 11.703125 36 20 C 36 28.296875 29.296875 35 21 35 C 12.703125 35 6 28.296875 6 20 C 6 11.703125 12.703125 5 21 5 Z"></path>
                  </svg>
                </span>
              </div>
        <div
          id="loader-line"
          className={`${
            isScrolled ? "loader-line  bg-cyan-600 shadow-xl top-0 fixed w-full mt-12 " : " hidden bg-cyan-600"
          }`}
        ></div>
      </nav>
    </section>
  );
}

export default Navbar;
