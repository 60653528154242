import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
function Aboutbscit() {
  return (
    <div className="md:px-20 px-8 py-10 font-change">
            <h1 className='text-4xl underline underline-offset-8 font-bold text-orange-500  mb-8 text-center'>B.Sc (INFORMATION TECHNOLOGY)</h1>
      <div className="mb-14 mx-auto flex justify-center text-sm md:text-base">
        <Link to='/Aboutbscit' className='md:px-8 py-3 px-4 bg-blue-600 text-white rounded-l-lg '>About US</Link>
        <Link to='/Bscitpospo' className='md:px-8 py-3 px-4 bg-blue-900 hover:bg-blue-800 text-white rounded-r-lg'>PO PSO PEO</Link>
      </div>
      <motion.div className="font-semibold" 
      initial={{ y:-40,opacity:0 }}
      animate={{ y:0, opacity:1}}
      transition={{ ease: "easeIn", duration: 1 }}>
        <h2 className="text-2xl md:text-3xl text-justify font-bold ">
          Department at a Glance
        </h2>
        <br />
        <p className="md:text-lg text-base text-justify text-black/70">
          {" "}
          &ensp;&ensp;Computers – are slowly becoming a quintessential aspect which can be found everywhere; i,e in car engines, watches, microwave ovens, telephones, video games, x-ray machines, ATM’s, touching every facet of our lives. Due to the rapid growth of Information Technology, computer industry has become one of the fastest growing segments of our economy. As a result, there exists a huge demand for Computer science professionals for the development of faster hardware components, new communication systems and software. Here comes the importance of Computer Science. Computer Science involves designing and testing of computer components. It is a branch of B.Sc, which studies elements from both electrical engineering and computer science angles, combining the principle and techniques of these two for the making and development of computer and computer-based systems. Professionals who work with computer hardware and software are termed as Computer Engineers.
        </p>
        <br />
        <h2 className="text-2xl md:text-3xl text-justify font-bold ">Vision</h2>
        <br />
        <p className="md:text-lg text-base text-justify text-black/70">
        To empower the students to be technologically adept, innovative and self-motivated. To motivate students to contribute significantly towards high quality technical education with constantly changing technological world.  
        </p>
        <br />
        <h2 className="text-2xl md:text-3xl text-justify font-bold ">
          Mission
        </h2>
        <br />
        <p className="md:text-lg text-base text-justify text-black/70">
        To impart value added technical and applied research oriented education by inculcating life skills oriented to industries with emphasis on human values.
        </p>
      </motion.div>
    </div>
  )
}

export default Aboutbscit