import React from 'react'
import { motion } from 'framer-motion'
function Vision() {
  return (
    <motion.div className='font-change md:px-14 px-8 py-5 my-7 space-y-20 '
    initial={{ y:-40,opacity:0 }}
    animate={{ y:0, opacity:1}}
    transition={{ ease: "easeIn", duration:1 }}
    >
       
       <div className='space-y-8'>
        <h1 className='text-3xl underline underline-offset-8 font-bold'>Vision</h1>
        <p className='text-justify md:text-lg text-base'>To impart Engineering education in rural environment to serve the society.To impart Engineering education in rural environment to serve the society.</p>
       </div>

       <div className='space-y-8'>
       <h1 className='text-3xl underline underline-offset-8 font-bold'>Mission</h1>
         <ul className='list-disc list-inside space-y-6 md:text-lg text-base'>
            <li>To impart education to achieve excellence in teaching-learning.</li>
            <li>To provide skill oriented training to meet the need of the industry and society.</li>
            <li>To undertake collaborative projects with academia and industries.</li>
            <li>To create an environment conducive to creativity, innovation, team-spirit and entrepreneurial leadership.</li>
            <li>To facilitate effective interactions of students, faculty and management with industry personnel, alumni, academicians of premiere Institutions and other stake holders.</li>
         </ul>
       </div>

    </motion.div>
  )
}

export default Vision