import React from "react";
import { Link } from "react-router-dom";
import "../navbar_items/styles/items.css";

function About(props) {

  return (
      <div className="group inline-block text-base ">
        <button className="outline-none focus:outline-none  px-3 py-2 text-start xlc:text-center rounded-sm flex items-center min-w-[6rem]  hover:bg-blue-800 ">
          <span className="pr-1 font-semibold  flex-1">About us</span>
          <span>
            <svg
              className="fill-current h-4 w-4 transform group-hover:-rotate-180 xlc:hidden block
        transition duration-150 ease-in-out"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </span>
        </button>
        <ul
          className="bg-white border rounded-sm transform scale-0 group-hover:scale-100 absolute 
  transition duration-300 ease-in-out origin-top w-[100%] xlc:w-[10%] xlc:min-w-32 mt-1 flex flex-col z-10"
        >
          <Link
            onClick={props.onclick}
            to="/Aboutus"
            className="rounded-sm relative px-3 py-3 rounded-t bg-blue-100 text-black  hover:bg-blue-800 hover:text-white shadow-lg"
          >
            About MITM
          </Link>
          <Link
          onClick={props.onclick}
            to="Vision"
            className="rounded-sm relative px-3 py-3 rounded-t bg-blue-100 text-black  hover:bg-blue-800 hover:text-white shadow-lg"
          >
            Vision/Mission of institute
          </Link>
          <Link
          onClick={props.onclick}
            to="/Chairman"
            className="rounded-sm relative px-3 py-3 rounded-t bg-blue-100 text-black  hover:bg-blue-800 hover:text-white shadow-lg"
          >
            Chairman's Message
          </Link>
          <Link
          onClick={props.onclick}
            to="/Principle"
            className="rounded-sm relative px-3 py-3 rounded-t bg-blue-100 text-black  hover:bg-blue-800 hover:text-white shadow-lg"
          >
            Principal's Message
          </Link>
          <Link
          onClick={props.onclick}
            to="/Amc"
            className="rounded-sm relative px-3 py-3 rounded-t bg-blue-100 text-black  hover:bg-blue-800 hover:text-white shadow-lg"
          >
            About Management Council
          </Link>

        </ul>
      </div>

  );
}

export default About;
