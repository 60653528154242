import { motion } from 'framer-motion';
import React from 'react'

function Internet() {
    const data = [
        {
          imgelink:
            "/assets/facilities/internet/1.jpg",
        },
        {
          imgelink:
            "/assets/facilities/internet/2.jpg",
        },
        {
          imgelink:
            "/assets/facilities/internet/3.jpg",
        },

      ];
     
      const [active, setActive] = React.useState(
        "/assets/facilities/internet/3.jpg",
      );
  return (
    <motion.div className='md:px-52 px-8 py-10 font-change'
    initial={{ y:-40,opacity:0 }}
          animate={{ y:0, opacity:1}}
          transition={{ ease: "easeIn", duration: 1 }}>
        <h1 className='text-4xl  font-bold mb-8 text-center underline underline-offset-8'>Internt Facilities </h1>
    <div className="grid gap-4 md:w-[80%] mx-auto ">
      <div>
        <img
          className="h-auto   max-w-full rounded-lg object-cover object-center "
          src={active}
          alt=""
        />
      </div>
      <div className="grid grid-cols-5 gap-4">
        {data.map(({ imgelink }, index) => (
          <div key={index}>
            <img
              onClick={() => setActive(imgelink)}
              src={imgelink}
              className=" max-w-full cursor-pointer rounded-lg object-cover object-center"
              alt="gallery-image"
            />
          </div>
        ))}
      </div>
    </div>
    </motion.div>
  )
}

export default Internet