import React, { useRef, useEffect } from 'react';

const NumCount = () => {
  const numCountRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          animateCounters();
          observer.unobserve(entry.target);
        }
      });
    }, { threshold: 0.5 }); // Trigger animation when 50% of the component is visible

    if (numCountRef.current) {
      observer.observe(numCountRef.current);
    }

    return () => {
      if (numCountRef.current) {
        observer.unobserve(numCountRef.current);
      }
    };
  }, []); // Run only once on component mount

  function animateCounters() {
    const counters = document.querySelectorAll('.counter');
    
    counters.forEach(counter => {
      const finalValue = parseInt(counter.getAttribute('data-final-value'));
      const duration =2000; // Animation duration in milliseconds
      const steps = 60; // Number of steps in the animation
      const stepValue = finalValue / steps;

      let currentValue = 0;
      const interval = setInterval(() => {
        currentValue += stepValue;
        counter.innerText = Math.round(currentValue);

        if (currentValue >= finalValue) {
          clearInterval(interval);
          counter.innerText = finalValue; // Ensure the final value is exact
        }
      }, duration / steps);
    });
  }

  return (
    <section ref={numCountRef} id="numCount" className="grid gap-12 lg:grid-cols-3 lg:gap-[9rem] bg-black lg:h-[15rem] h-[20rem] shadow-xl my-6"   style={{ 
        backgroundImage: "url('assets/logos/numbg.png')", 
        backgroundSize: 'cover', // Ensure the background image covers the entire section
        backgroundRepeat: 'no-repeat', // Prevent the background image from repeating
        backgroundAttachment: 'fixed', 
        backgroundPosition: 'center',
       
      }}>
      {/* Block #1 */}
      <article className="m-auto">
        <div className="w-14 h-14 hidden lg:block rounded shadow-lg bg-white flex justify-center items-center rotate-3 mb-6">
          <img src="assets/counternum/student-48.png" alt="" />
        </div>
        <h2>
          <span className="flex ml-4 lg:ml-0 tabular-nums text-white lg:text-5xl text-2xl font-extrabold lg:mb-2 counter" data-final-value="1000">0</span>
          <span className="inline-flex font-bold lg:my-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-blue-300 mb-2 lg:text-3xl">STUDENTS</span>
        </h2>
      </article>
      {/* Block #2 */}
      <article className="m-auto">
        <div className="w-14 h-14 rounded hidden lg:block shadow-lg bg-white flex justify-center items-center -rotate-3 mb-6">
          <img src="assets/counternum/fac.ico" alt="" />
        </div>
        <h2>
          <span className="flex ml-3 lg:ml-0 tabular-nums text-white lg:text-5xl text-2xl font-extrabold lg:mb-2 counter" data-final-value="200">0</span>
          <span className="inline-flex font-bold lg:my-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-blue-300 mb-2 lg:text-3xl">FACULTY</span>
        </h2>
      </article>
      {/* Block #3 */}
      <article className="m-auto">
        <div className="w-14 h-14 hidden lg:block rounded shadow-lg bg-white flex justify-center items-center rotate-3 mb-6">
          <img src="assets/counternum/teacher.ico" alt="" />
        </div>
        <h2>
          <span className="flex   tabular-nums text-white lg:text-5xl text-2xl font-extrabold lg:mb-2 counter" data-final-value="100">0</span>
          <span className="inline-flex font-bold lg:my-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-blue-300 mb-2 lg:text-3xl">STAFF</span>
        </h2>
      </article>
    </section>
  );
};

export default NumCount;
