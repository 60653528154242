import React from 'react';
import {
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
  } from "@material-tailwind/react";
import { motion } from 'framer-motion';

function Convocation() {
    const data = [
        {
          label: "2024",
          value: "2024",
          images: [
            {
              imageLink:
                "/assets/events/convocation/2024/1.jpg",
            },
            {
              imageLink:
              "/assets/events/convocation/2024/2.jpg",
            },
            {
              imageLink:
              "/assets/events/convocation/2024/3.jpg",
            },
            {
              imageLink:
              "/assets/events/convocation/2024/4.jpg",
            },
            {
              imageLink:
              "/assets/events/convocation/2024/5.jpg",
            },
            {
              imageLink:
              "/assets/events/convocation/2024/6.jpg",
            },
            {
                imageLink:
                "/assets/events/convocation/2024/7.jpg",
              },
              {
                imageLink:
                "/assets/events/convocation/2024/8.jpg",
              },
              {
                imageLink:
                "/assets/events/convocation/2024/9.jpg",
              },
              {
                imageLink:
                "/assets/events/convocation/2024/10.jpg",
              },
              {
                imageLink:
                "/assets/events/convocation/2024/11.jpg",
              },
              {
                imageLink:
                "/assets/events/convocation/2024/13.jpg",
              },
              {
                imageLink:
                "/assets/events/convocation/2024/14.jpg",
              },
              {
                imageLink:
                "/assets/events/convocation/2024/15.jpg",
              },
    
          ],
        },
        {
          label: "2023",
          value: "2023",
          images: [
            {
                imageLink:
                  "/assets/events/convocation/2023/1.jpg",
              },
              {
                imageLink:
                "/assets/events/convocation/2023/2.jpg",
              },
              {
                imageLink:
                "/assets/events/convocation/2023/3.jpg",
              },
              {
                imageLink:
                "/assets/events/convocation/2023/4.jpg",
              },
              {
                imageLink:
                "/assets/events/convocation/2023/5.jpg",
              },
              {
                imageLink:
                "/assets/events/convocation/2023/6.jpg",
              },
              {
                  imageLink:
                  "/assets/events/convocation/2023/7.jpg",
                },
                {
                  imageLink:
                  "/assets/events/convocation/2023/7.jpg",
                },
                {
                  imageLink:
                  "/assets/events/convocation/2023/9.jpg",
                },
                {
                  imageLink:
                  "/assets/events/convocation/2023/10.jpg",
                },
                {
                  imageLink:
                  "/assets/events/convocation/2023/11.jpg",
                },
                {
                  imageLink:
                  "/assets/events/convocation/2023/12.jpg",
                },
                {
                  imageLink:
                  "/assets/events/convocation/2023/13.jpg",
                },
                {
                  imageLink:
                  "/assets/events/convocation/2023/14.jpg",
                },
                {
                  imageLink:
                  "/assets/events/convocation/2023/15.jpg",
                },
                {
                  imageLink:
                  "/assets/events/convocation/2023/16.jpg",
                },
                {
                  imageLink:
                  "/assets/events/convocation/2023/17.jpg",
                },
                {
                  imageLink:
                  "/assets/events/convocation/2023/18.jpg",
                },
                {
                  imageLink:
                  "/assets/events/convocation/2023/19.jpg",
                },
                {
                  imageLink:
                  "/assets/events/convocation/2023/20.jpg",
                },
                {
                  imageLink:
                  "/assets/events/convocation/2023/21.jpg",
                },
          ],
        },
        
        {
          label: "2018",
          value: "2018",
          images: [
             {
                imageLink:
                  "/assets/events/convocation/2018/1.jpg",
              },
              {
                imageLink:
                "/assets/events/convocation/2018/2.jpg",
              },
              {
                imageLink:
                "/assets/events/convocation/2018/3.jpg",
              },
              {
                imageLink:
                "/assets/events/convocation/2018/4.jpg",
              },
              {
                imageLink:
                "/assets/events/convocation/2018/5.jpg",
              },
              {
                imageLink:
                "/assets/events/convocation/2018/6.jpg",
              },
              {
                  imageLink:
                  "/assets/events/convocation/2018/7.jpg",
                },
                {
                  imageLink:
                  "/assets/events/convocation/2018/7.jpg",
                },
                {
                  imageLink:
                  "/assets/events/convocation/2018/9.jpg",
                },
                {
                  imageLink:
                  "/assets/events/convocation/2018/10.jpg",
                },
                {
                  imageLink:
                  "/assets/events/convocation/2018/11.jpg",
                },
                {
                  imageLink:
                  "/assets/events/convocation/2018/12.jpg",
                },
          ],
        },
      ];
  return (
    <motion.div className='md:p-16 p-6 font-change'
    initial={{ y:-40,opacity:0 }}
          animate={{ y:0, opacity:1}}
          transition={{ ease: "easeIn", duration: 1 }}>
        <h1 className='text-4xl  font-bold mb-8 text-center underline underline-offset-8'>Convocation</h1>
        <p className='text-base font-semibold md:text-xl my-4 text-black/80'>Most prestigious day for any graduate in convocation ceremony . Here convocation ceremony celebration is part of our culture. Every year we celebrate this ceremony . It’s matter of pride to wear the graduation gown on this to every student who gets graduation from MITM.</p>

    <Tabs value="2024">
    <TabsHeader className='z-[5]'>
      {data.map(({ label, value }) => (
        <Tab key={value} value={value} className='bg-gray-200'>
          {label}
        </Tab>
      ))}
    </TabsHeader>
    <TabsBody className="grid grid-cols-1 gap-4 ">
      {data.map(({ value, images }) => (
        <TabPanel
          className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:grid-cols-3"
          key={value}
          value={value}
        >
          {images?.map(({ imageLink }, index) => (
            <div key={index}>
              <img
                className=" w-full max-w-full rounded-lg object-cover object-center"
                src={imageLink}
                alt="image-photo"
              />
            </div>
          ))}
        </TabPanel>
      ))}
    </TabsBody>
  </Tabs>
  </motion.div>
  )
}

export default Convocation