import React from "react";
import { motion } from "framer-motion";
function Aboutus() {
  return (
    <motion.div className="w-screen h-auto font-change my-8"
    initial={{ y:-40,opacity:0 }}
    animate={{ y:0, opacity:1}}
    transition={{ ease: "easeIn", duration: 1 }}
    >
      <div className=" text-center lg:px-[15rem] px-8 py-5">
        <h1 className="text-4xl font-bold">Welcome to MITM !</h1>
        <br />
        <p className="md:text-lg text-base text-justify">
          &emsp;&emsp;Metropolitan Institute of Technology and Management (MITM)
          has been established in 2011, to cater to the growing needs of higher
          education in the country. One of the primary objectives of the
          Institute is to produce trained manpower to accelerate sustained
          economic growth in a highly competitive global environment. In order
          to face the challenges of globalization and liberalization in the
          field of higher education, the Institute has endowed its faculty and
          students with modern technologies, skills and attitudes so as to make
          them competent for the challenges and to enhance the quality of
          teaching to contribute constantly towards learning & research. It is
          true that conducive environment with updated technological inputs and
          latest information helps not only providing quality education but also
          to provide human touch and dedication to the cause of the nation and
          the society. With an aim to impart quality education and to make the
          Institute as one of the best Centre of Excellence for higher studies
          and research, the Institute has developed excellent physical and
          academic infrastructure, improved teaching methodology, recruitment of
          best talent as faculty, industry interaction, having a research base
          in all areas of studies and creation of brand image among the
          corporate world which is eventually be the place where the students of
          the Institute would find their placements. The aim is to make the
          Institute as one of the best Centre of Excellence for higher studies
          and research. The Institute is situated at Sukhalwad, Near Sindhudurg
          Rly. Stn., Tal. Malvan, Dist. Sindhudurg, Maharashtra which is in
          proximity to the Goa International Airport and industrial area on its
          vast campus spread over more than 10 acres of land with all modern
          educational facilities in its ambience. The institute has Wi-Fi
          enabled campus has state of-art infrastructure equipped with world
          class facilities and labs.
        </p>
      </div>

<div className="flex flex-col md:flex-row justify-center md:px-14 px-8 mb-4 space-y-5 md:space-y-0 md:space-x-5 mt-4">
      <div className="bg-gray-200 px-4 py-3 md:h-[12rem] rounded-md">
        <h2 className="text-xl font-medium">Degree Courses</h2>
        <ul className="list-disc list-inside">
          <br />
          <li className="text-base md:text-lg ">B.E. in Mechanical Engineering</li>
          <li className="text-base md:text-lg ">B.E. in Civil Engineering</li>
          <li className="text-base md:text-lg ">B.E. in Computer Engineering</li>
          <li className="text-base md:text-lg ">
            B.E. in Electronics and Telecommunication Engineering
          </li>
        </ul>
      </div>

      <div className="bg-gray-200 px-4 py-3 md:h-[12rem] rounded-md">
        <h2 className="text-xl font-medium">Diploma Courses</h2>
        <ul className="list-disc list-inside">
          <br />
          <li className="text-base">Diploma in Mechanical Engineering</li>
          <li className="text-base">Diploma in Civil Engineering</li>
        </ul>
      </div>

      <div className="bg-gray-200 px-4 py-3 md:h-[12rem] rounded-md">
        <h2 className="text-xl font-medium">B.Sc. Courses</h2>
        <ul className="list-disc list-inside">
          <br />
          <li className="text-base">B.Sc. Computer Science (CS)</li>
          <li className="text-base">B.Sc. information Technology (IT)</li>
        </ul>
      </div>
      </div>
    </motion.div>
  );
}

export default Aboutus;
