import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';

function Text() {
  const sentences = [
    "METROPOLITAN INSTITUTE OF TECHNOLOGY & MANAGEMENT",
    " ",
    "मेट्रोपॉलिटन इंस्टीट्यूट ऑफ टेक्नोलोजी अँड मॅनेजमेंट"
  ];
  const typingSpeed = 150; // Adjust typing speed as needed

  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);
  const [currentText, setCurrentText] = useState(sentences[0]);
  const controls = useAnimation();

  useEffect(() => {
    const interval = setInterval(() => {
      const sentence = sentences[currentSentenceIndex];
      if (currentText.length < sentence.length) {
        setCurrentText((prevText) => prevText + sentence[currentText.length]);
      } else {
        clearInterval(interval);
        controls.start({ opacity: 0, x: -20 });
        setTimeout(() => {
          controls.set({ opacity: 1, x: 0 });
          setCurrentText('');
          setCurrentSentenceIndex((prevIndex) =>
            prevIndex === sentences.length - 1 ? 0 : prevIndex + 1
          );
        }, 100);
      }
    }, typingSpeed);

    return () => clearInterval(interval);
  }, [currentSentenceIndex, currentText, sentences, typingSpeed, controls]);

  return (
    <section id="location" className="font-change">
      <div className="flex justify-between h-[8rem] items-center">
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={controls}
          transition={{ duration: 0.3 }}
          className="font-medium text-[#d05e32] font-estrabold md:text-[2.5rem] w-[60%] ml-4 text-size text-style"
          style={{ minWidth: '50%', maxWidth: '70%' }} // Adjust width to accommodate longer text
        >
          {currentText}
        </motion.div>
        <div className="flex justify-center w-[50%] items-center text-sm lg:text-2xl">
          <a href="https://maps.app.goo.gl/yyhR499gWnxAUQ6EA">
            <img src="assets/svg/location.svg" className="w-10" alt="" />
          </a>
          <div
            className="m-4 cursor-pointer"
            onClick={() => window.open('https://maps.app.goo.gl/yyhR499gWnxAUQ6EA')}
            data-toggle="tooltip"
            data-placement="bottom"
            title="Click to view location and get directions"
          >
            <i className="text-orange-950">
              <a href="https://maps.app.goo.gl/yyhR499gWnxAUQ6EA" target="_blank" rel="noopener noreferrer" className="font-12 text-orange-950 text-uppercase">MITM College
                <h5 className="font-13 text-black m-0 text-orange-950"> A/P Sukalwad, Near Sindhudurg Railway Station, Oros, Tal. Malvan, Dist. Sindhudurg, Maharashtra, 416534.</h5>
              </a>
            </i>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Text;
