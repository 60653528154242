// ProtectedRoute.js
import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";

const ProtectedRoute = ({ children }) => {
  const { isAuthorized } = useAuth();
  return isAuthorized ? children : <Navigate to="/" />;
};

export default ProtectedRoute;
