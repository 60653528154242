import { motion } from 'framer-motion'
import React from 'react'

function OnlineG() {
  return (
    <motion.div className='flex flex-col md:flex-row justify-evenly mx-8 my-8 '
    initial={{ y:-40,opacity:0 }}
    animate={{ y:0, opacity:1}}
    transition={{ ease: "easeIn", duration: 1.5 }
    }>
    <div className='md:w-full'>
    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdg8vrd9rz-ie1dd5qXKa0sp6G6oU7bZrNYCPtRdASbUd3zcw/viewform" className='rounded-md' width='780' height="850" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    </div>
    <div className='md:w-full'>
    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSck-_Mqz9WNvcUdwq6w32XJmS67H6I9KQixWo4bJVGA6MyC_A/viewform" className='rounded-md' width='780'  height="850" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    </div>
</motion.div>
  )
}

export default OnlineG
