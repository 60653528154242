import React from "react";

function Naac() {


  return (
    <div className="md:px-14 px-3 flex justify-center items-center flex-col">
        <h1 className="text-2xl underline underline-offset-8 font-bold my-3 text-center">
          NAAC SELF STUDY REPORT(SSR)
        </h1>
        <embed
          className="md:w-[80%] w-[90%] rounded-md h-[40vh] lg:h-[90vh] my-5"
          src="/assets/naac/NAAC_SSR.pdf"
          type="application/pdf"
        />

        <h1 className="text-2xl underline underline-offset-8 font-bold my-3 text-center">
        Institutional Information for Quality Assessment(IIQA)
        </h1>
        <embed
          className="md:w-[80%] w-[90%] rounded-md h-[40vh] lg:h-[90vh] my-5"
          src="/assets/naac/IIQA.pdf"
          type="application/pdf"
        />

<h1 className="text-2xl underline underline-offset-8 font-bold my-3 text-center">
          AQAR
        </h1>
        <embed
          className="md:w-[80%] w-[90%] rounded-md h-[40vh] lg:h-[90vh] my-5"
          src="/assets/naac/AQAR.pdf"
          type="application/pdf"
        />

<h1 className="text-2xl underline underline-offset-8 font-bold my-3 text-center">
        IQAC
        </h1>
        <embed
          className="md:w-[80%] w-[90%] rounded-md h-[40vh] lg:h-[90vh] my-5"
          src="/assets/naac/IQAC.pdf"
          type="application/pdf"
        />
    </div>
  );
}

export default Naac;
