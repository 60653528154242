import React, { useState, useEffect } from 'react';

const PopupImage = ({ isOpen, onClose, imageUrl }) => {
    if (!isOpen) return null;
  
    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-90">
        <div className="relative">
          <button 
            onClick={onClose} 
            className="absolute top-0 right-0 mt-2 mr-2 text-black text-4xl">
            &times;
          </button>
          <img src='/assets/popup.jpg' alt="Popup" className="max-w-full max-h-screen w-[20rem] md:w-[30rem] " />
        </div>
      </div>
    );
  };
function Popup() {
    const [isPopupOpen, setIsPopupOpen] = useState(false); // Initially set to false
    const imageUrl = '/public/assets/popup.jpg'; 
  
    useEffect(() => {
      const timer = setTimeout(() => {
        setIsPopupOpen(true);
      }, 5000); // 5000 milliseconds = 5 seconds
  
      return () => clearTimeout(timer);
    }, []);
  
    const handleClose = () => {
      setIsPopupOpen(false);
    };
  
    return (
      <div className="App">
        <PopupImage isOpen={isPopupOpen} onClose={handleClose} imageUrl={imageUrl} />
      </div>
    );
}

export default Popup