import React from 'react'
import { Table } from "flowbite-react";
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
function Civillabo() {
    const data = [
        {
          imgelink:
            "/assets/Courses/engineering/civil/1.jpg",
        },
        {
          imgelink:
            "/assets/Courses/engineering/civil/2.jpg",
        },
        {
          imgelink:
            "/assets/Courses/engineering/civil/3.jpg",
        },
        {
          imgelink:
            "/assets/Courses/engineering/civil/4.jpg",
        },
        {
          imgelink:
            "/assets/Courses/engineering/civil/5.jpg",
        },        
        {
          imgelink:
            "/assets/Courses/engineering/civil/6.jpg",
        },
        {
          imgelink:
            "/assets/Courses/engineering/civil/7.jpg",
        },
        {
          imgelink:
            "/assets/Courses/engineering/civil/8.jpg",
        },
        {
          imgelink:
            "/assets/Courses/engineering/civil/9.jpg",
        },
        {
          imgelink:
            "/assets/Courses/engineering/civil/10.jpg",
        },        
        {
          imgelink:
            "/assets/Courses/engineering/civil/11.jpg",
        },
        {
          imgelink:
            "/assets/Courses/engineering/civil/12.jpg",
        },
        {
          imgelink:
            "/assets/Courses/engineering/civil/13.jpg",
        },
        {
          imgelink:
            "/assets/Courses/engineering/civil/14.jpg",
        },
        {
          imgelink:
            "/assets/Courses/engineering/civil/15.jpg",
        },      
          {
          imgelink:
            "/assets/Courses/engineering/civil/16.jpg",
        },
        {
          imgelink:
            "/assets/Courses/engineering/civil/17.jpg",
        },

      ];
     
      const [active, setActive] = React.useState(
        "/assets/Courses/engineering/civil/1.jpg",
      );
    
  return (
    <div className='md:px-20 px-8 py-10 font-change'>
              <h1 className='text-4xl underline underline-offset-8 font-bold text-orange-500  mb-8 text-center'>CIVIL ENGINEERING</h1>
    <div className='mb-14 mx-auto flex justify-center text-sm md:text-base'>
<Link to='/Aboutcivil' className='md:px-8 px-4 py-3 bg-blue-900 hover:bg-blue-800 text-white rounded-l-lg'>About US</Link>
<Link to='/Civilpospo' className='md:px-8 py-3 px-4 bg-blue-900 hover:bg-blue-800 text-white '>PO PSO PEO</Link>
<Link to='/Civillabo' className='md:px-8 py-3 px-4 bg-blue-600 text-white rounded-r-lg'>Laboratories</Link>
    </div>
    <h2 className='text-2xl md:text-3xl text-center font-bold my-6'>Laboratories</h2>
    <motion.div className="overflow-x-auto my-7 border border-gray-200 rounded-xl"
    initial={{ y:-40,opacity:0 }}
    animate={{ y:0, opacity:1}}
    transition={{ ease: "easeIn", duration: 1 }}>
      <Table striped>
        <Table.Head>
          <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg ">Sr.No.</Table.HeadCell>
          <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg">Name of Laboratory</Table.HeadCell>
          <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg">Specifications</Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y text-sm md:text-base font-semibold">
          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell >1</Table.Cell>
            <Table.Cell>Dynamics of Machinery Lab</Table.Cell>
            <Table.Cell>Universal Governor, Motorized Gyroscope, Static and Dynamic Balancing, Whirling of Shaft, Universal Vibration Apparatus</Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell >2</Table.Cell>
            <Table.Cell>CAD/CAM/CAE Lab</Table.Cell>
            <Table.Cell>Computers.</Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell>3</Table.Cell>
            <Table.Cell>Material Testing Lab</Table.Cell>
            <Table.Cell>Electronic Universal Testing Machine, Hardness, Testing Machine, Torsion Testing Machine, Impact Testing Machine, Double Shear Test Apparatus, Mechanical Extensometer, Trinocular Microscope, Double Disc Polishing Machine, Jominy End Quench Apparatus and Muffle Furnace</Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell >4</Table.Cell>
            <Table.Cell>Thermal Engineering Lab</Table.Cell>
            <Table.Cell>Thermal Conductivity of Metal Rod, Thermal Conductivity of Insulating Powder, Unsteady State Heat Transfer, Heat Transfer by Natural Convection, Emissivity Measurement Apparatus, Heat Transferred through Composite Walls, Parallel & Counter Flow Heat Exchangers ,Three Cylinder Four Stroke Petrol Engine and Single Cylinder Diesel Engine.</Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell >5</Table.Cell>
            <Table.Cell>Turbo Machinery Lab</Table.Cell>
            <Table.Cell>Reciprocating compressor, Reciprocating pump Kaplan turbine Francis turbine, Pelton turbine and gear pump.</Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell>6</Table.Cell>
            <Table.Cell>Heating, Ventilation, Air Conditioning and Refrigeration Lab</Table.Cell>
            <Table.Cell>Refrigeration Cycle Test RIG, Ice Plant Test RIG, Window Air Conditioning Test RIG</Table.Cell>
          </Table.Row>
          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell>7</Table.Cell>
            <Table.Cell>Measurements and Automation Lab</Table.Cell>
            <Table.Cell>Profile Projector, Monochromatic Light Unit, Sine Center, Gear Tooth Vernier, Dial Indicator , LVDT, Potentiometers etc.</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </motion.div>

<motion.div className='md:w-[60%] w-[90%] mx-auto'
initial={{ y:-40,opacity:0 }}
animate={{ y:0, opacity:1}}
transition={{ ease: "easeIn", duration: 1 }}>
    <h2 className='text-2xl md:text-3xl text-center font-bold my-6'>Laboratories Photos</h2>
      <div className="grid gap-4">
      <div>
        <img
          className="h-auto w-full max-w-full rounded-lg object-cover object-center md:h-[480px]"
          src={active}
          alt=""
        />
      </div>
      <div className="grid grid-cols-5 gap-4">
        {data.map(({ imgelink }, index) => (
          <div key={index}>
            <img
              onClick={() => setActive(imgelink)}
              src={imgelink}
              className="h-20 max-w-full cursor-pointer rounded-lg object-cover object-center"
              alt="gallery-image"
            />
          </div>
        ))}
      </div>
    </div>
</motion.div>

    </div>
  )
}

export default Civillabo