import { useState } from "react";
import React from 'react'
import { Modal} from "flowbite-react";
function Developer() {
    const [openModal, setOpenModal] = useState(false);
  return (
    <ul class="flex items-center justify-end gap-4">
              
              
    {/* devlopers info */}

        <button onClick={() => setOpenModal(true)} className=''>Developed & Maintained by</button>
        <Modal
   show={openModal}
   position='center'
   onClose={() => setOpenModal(false)}
   
 >
   <Modal.Header className='bg-[#25252e] text-white'><p className='text-white'>Developed & Maintained by</p></Modal.Header>
   <Modal.Body className='bg-gray-200'>
   <div class="p-4 md:p-5 italic text-cente">
             <p class="text-start leading-relaxed text-gray-500 dark:text-gray-400 ">
                 <h3 class="font-bold my-2">Developed by </h3>
                 <p className='text-black'><a href='https://www.linkedin.com/in/pranay-nandiwadekar/' target="_blank">Mr. Pranay Nandiwadekar</a></p>
                 <p className='text-black'><a href='https://www.linkedin.com/in/omkar-pawar-6b8b1b1a1/' target="_blank">Mr. Omkar Pawar</a></p>
                 <h3 class=" font-bold my-2">Maintained by </h3>
                 <p className='text-black'>Metropolitan Institute of Technology & Management (MITM)</p>
             </p>
         </div>
   </Modal.Body>
   <Modal.Footer className='bg-gray-800'>
     <button className='bg-gray-700 border border-white px-7 py-2 rounded-xl text-white hover:scale-110 duration-300' onClick={() => setOpenModal(false)}>
       Okay
     </button>
   </Modal.Footer>
 </Modal>
       </ul>
  )
}

export default Developer