import React from 'react'

function Agnipath() {
  return (
    <div  className="md:px-14 px-3 flex justify-center items-center flex-col">
    <h1 className="text-2xl underline underline-offset-8 font-bold my-3 text-center">
    UGC's Agnipath Yojana
    </h1>
    <embed
      className="md:w-[80%] w-[90%] rounded-md h-[40vh] lg:h-[90vh] my-5"
      src="/assets/statutary/AgnipathYojana.pdf"
      type="application/pdf"
    />
    </div>
  )
}

export default Agnipath