import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

function ScrollTop() {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
      const handleScroll = () => {
        const scrollTop = window.pageYOffset;
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;
        const footerThreshold = 100; // Adjust this value according to your footer's height
  
        // Calculate the distance from the top of the viewport to the bottom of the page
        const distanceToBottom = documentHeight - (scrollTop + windowHeight);
  
        // Show the button when the distance to the bottom is less than the footer threshold
        setIsVisible(distanceToBottom < footerThreshold);
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };
    return (
      <motion.button
        className={`fixed left-[50%] bottom-0 my-3  bg-white/20 text-white px-1 py-1 rounded-full shadow-md ${
          isVisible ? 'visible' : 'invisible'
        }`}
        onClick={scrollToTop}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: isVisible ? 1 : 0 }}
        transition={{ duration: 0.3 }}
      >
                <img  src="/assets/svg/top.svg" className='w-10 h-10 ' alt="" />
      </motion.button>
    );
}

export default ScrollTop