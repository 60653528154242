import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
function Civilpospo() {
  return (
    <div className='md:px-20 px-8 py-10 font-change'>
    <h1 className='text-4xl underline underline-offset-8 font-bold text-orange-500  mb-8 text-center'>DEGREE IN CIVIL ENGINEERING</h1>
  <div className='mb-14 mx-auto flex justify-center text-sm md:text-base'>
<Link to='/AboutDcivil' className='md:px-8 px-4 py-3 bg-blue-900 hover:bg-blue-800 text-white rounded-l-lg'>About US</Link>
<Link to='/Dcivilpospo' className='md:px-8 py-3 px-4 bg-blue-600 text-white '>PO PSO PEO</Link>
<Link to='/Dcivillabo' className='md:px-8 py-3 px-4 bg-blue-900 hover:bg-blue-800 text-white rounded-r-lg'>Laboratories</Link>
  </div>

  <br />
  <motion.div
  initial={{ y:-40,opacity:0 }}
  animate={{ y:0, opacity:1}}
  transition={{ ease: "easeIn", duration: 1 }}>
      <h2 className='text-2xl md:text-3xl text-justify font-bold '>Program Outcomes (PO)</h2>
      <br />
      <p className='font-semibold text-lg '>Students graduating from Degree in Civil Engineering will demonstrate:</p>
      <ul className='list-decimal list-inside md:text-lg  text-base text-justify font-semibold text-black/70'>
      <br />
      <li>An ability to solve problems in mathematics and science, and to apply this knowledge towards finding solutions to civil engineering problems.</li>
      <br />
      <li>An ability to design and conduct experiments and to analyze and interpret the results.</li>
      <br />
      <li>An ability to plan, and design civil engineering systems, components or processes as per relevant codes of practice, user requirements and constraints. </li>                
      <br />
      <li>An ability to communicate effectively through verbal, written and graphical means in project-oriented assignments. </li>               
      <br />
      <li>An ability to function effectively and to give directions to a multidisciplinary team.</li>
      <br />            
      <li>An ability to develop attitudes, techniques and skills to succeed in competitive professional environment including higher studies in a global context.</li>
      <br />
      <li>An ability to be aware of historical and contemporary issues and to demonstrate the understanding of social impact of engineering solutions. </li>
      <br />
      <li>An ability to demonstrate the knowledge of professional standards and ethical responsibilities.  </li>
      <br />
      <li> An ability to develop the spirit of innovation and entrepreneurial leadership focused towards the design and implementation of sustainable civil engineering systems.       
10.An ability to recognize the need for lifelong learning and to engage in it. </li>
      <br />
  </ul>

  <h2 className='text-2xl md:text-3xl text-justify font-bold'>Programme specific outcomes (PSOs)</h2>
      <br />
      <ul className='md:text-lg text-base text-justify font-semibold text-black/70'>
      <br />
      <li><span className='text-black font-bold'>PSO1:</span> &ensp;The Students of this program with proficiency in mathematics and physical sciences will excel in the core areas of civil engineering such as structural, environmental and water resources engineering.</li>
      <br />
      <li><span className='text-black font-bold'>PSO2:</span> &ensp;To develop and design sustainable and smart infrastructure considering the global environmental challenges.</li>
      <br />
      <li><span className='text-black font-bold'>PSO3:</span> &ensp;To Understand modern management and construction techniques to complete projects within the stipulated period and funds.</li>
      <br />
  </ul>

  <h2 className='text-2xl md:text-3xl text-justify font-bold'>Program Educational Objectives (PEOs)</h2>
      <br />
      <ul className='md:text-lg text-base text-justify font-semibold text-black/70'>
      <br />
      <li><span className='text-black font-bold'>PEO1:</span> &ensp;Function successfully in a professional environment through use of appropriate technology towards the development of safe, sustainable, economical and environmentally sound solutions to Civil Engineering problems.</li>
      <br />
      <li><span className='text-black font-bold'>PEO2:</span> &ensp;Demonstrate strong communication and leadership skills and contribute at individual as well as multidisciplinary team levels.</li>
      <br />
      <li><span className='text-black font-bold'>PEO3:</span> &ensp;Engage in enrichment of knowledge and skills through life-long learning.</li>
      <br />
      <li><span className='text-black font-bold'>PEO4:</span> &ensp;Demonstrate a sense of ethical and societal responsibility in professional career.</li>
      <br />
  </ul>
</motion.div>
</div>
  )
}

export default Civilpospo