import React, { useState, useEffect } from 'react';
import Slider from '../view/Carousel';
import Text from '../view/text';
import News from '../view/News';
import Links from '../view/Links';
import Info from '../view/Info';
import NumCount from '../view/numCount';
import Event from '../view/Event';
import Faq from '../view/Faq';
import Markee from '../view/Markee';
import Statutary from '../view/Statutary';

function Index() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const image = document.getElementById('scrollImage');
      if (image) {
        const rect = image.getBoundingClientRect();
        const inViewport = rect.top >= 0 && rect.bottom <= window.innerHeight;
        setIsScrolled(inViewport);
      }
    };

    // Trigger initial check when the component mounts
    handleScroll();

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <div className='bg-all'>
        <Markee />
        <Slider />
        <div className='bg-[#f2f0f0] h-[1px] w-full shadow-2xl my-4'></div>
        <Text />
        <div className='bg-[#f2f0f0] h-[1px] w-full shadow-2xl my-4'></div>
        <News />
        <Links />
        <div className='bg-[#f2f0f0] h-[1px] w-full shadow-2xl mt-10'></div>
        <Info />
        <Statutary />
        <NumCount />
        <Event />
        <div className='bg-[#f2f0f0] h-[1px] w-full shadow-2xl mt-16'></div>
        <Faq />
        <img
          id="scrollImage"
          src="/assets/logos/bg.png"
          className={isScrolled ? 'w-full h-[15%] transition-all ease-in-out duration-1000' : 'w-full h-[15%] filter grayscale transition-all ease-in-out duration-1000'}
          alt="no"
        />
      </div>
    </>
  );
}

export default Index;
