import React from 'react'
import { motion } from 'framer-motion'
function Fstructure() {
  return (
    <motion.div className="md:px-14 px-3 flex justify-center items-center flex-col mt-8"
    initial={{ y:-40,opacity:0 }}
    animate={{ y:0, opacity:1}}
    transition={{ ease: "easeIn", duration: 1 }}
    >
    <h1 className="text-2xl underline underline-offset-8 font-bold my-3 text-center">
      First year Degree Engineering
    </h1>
    <embed
      className="md:w-[80%] w-[90%] rounded-md h-[40vh] lg:h-[90vh] my-5"
      src="/assets/admission/FYDegree.pdf"
      type="application/pdf"
    />

    <h1 className="text-2xl underline underline-offset-8 font-bold my-3 text-center">
    First year Diploma Engineering
    </h1>
    <embed
      className="md:w-[80%] w-[90%] rounded-md h-[40vh] lg:h-[90vh] my-5"
      src="/assets/admission/FYDiploma.pdf"
      type="application/pdf"
    />

<h1 className="text-2xl underline underline-offset-8 font-bold my-3 text-center">
      Direct Second year Degree Engineering
    </h1>
    <embed
      className="md:w-[80%] w-[90%] rounded-md h-[40vh] lg:h-[90vh] my-5"
      src="/assets/admission/DSYDegree.pdf"
      type="application/pdf"
    />

<h1 className="text-2xl underline underline-offset-8 font-bold my-3 text-center">
  Direct Second year Diploma Engineering
    </h1>
    <embed
      className="md:w-[80%] w-[90%] rounded-md h-[40vh] lg:h-[90vh] my-5"
      src="/assets/admission/DSYDiploma.pdf"
      type="application/pdf"
    />
</motion.div>
  )
}

export default Fstructure