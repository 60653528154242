import React from 'react'
import { motion } from 'framer-motion'
function Contact() {
  return (
    <section className="bg-blue-50 dark:bg-slate-800 font-change " id="contact">
    <motion.div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8 lg:py-20"
        initial={{ y:-40,opacity:0 }}
        animate={{ y:0, opacity:1}}
        transition={{ ease: "easeIn", duration: 1 }}>
        <div className="mb-4">
            <div className="mb-6 max-w-3xl text-center sm:text-center md:mx-auto md:mb-12">
                <h2
                    className="font-heading mb-4 font-bold tracking-tight text-gray-900 dark:text-white text-3xl sm:text-5xl">
                    Contact Us
                </h2>
            </div>
        </div>

        <div className="flex  justify-center ">
            <div className="">
                <div className="h-full pr-6 md:w-auto pl-2 ">
                <div className=' flex flex-col xl:flex-row '>
                    <ul className="mb-6 md:mb-0  w-auto px-6 md:w-[70%] ">
                        <li className="flex">
                            <div className="flex h-10 w-10 items-center justify-center rounded bg-blue-900 text-gray-50">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" className="h-6 w-12">
                                    <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"></path>
                                    <path
                                        d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z">
                                    </path>
                                </svg>
                            </div>
                            <div className="ml-4 mb-4">
                                <h3 className="mb-2 text-lg font-bold leading-6 text-gray-900 dark:text-white">College Address
                                </h3>
                                <p className="text-gray-600 dark:text-slate-400">Village Sukalwad, Near Sindhudurg Railway Station, Oros, Tal. Malvan, Dist. Sindhudurg, Maharashtra, 416534.</p>
                            </div>
                        </li>
                        <li className="flex">
                            <div className="flex h-10 w-10 items-center justify-center rounded bg-blue-900 text-gray-50">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" className="h-6 w-6">
                                    <path
                                        d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2">
                                    </path>
                                    <path d="M15 7a2 2 0 0 1 2 2"></path>
                                    <path d="M15 3a6 6 0 0 1 6 6"></path>
                                </svg>
                            </div>
                            <div className="ml-4 mb-4">
                                <h3 className="mb-2 text-lg leading-6 font-bold text-gray-900 dark:text-white">Contact
                                </h3>
                                <p className="text-gray-600 dark:text-slate-400"> 02362-299195</p>
                                <p className="text-gray-600 dark:text-slate-400">02362-299197</p>
                                <p className="text-gray-600 dark:text-slate-400">admission.mitm@gmail.com</p>
                            </div>
                        </li>
                        <li className="flex">
                            <div className="flex h-10 w-10 items-center justify-center rounded bg-blue-900 text-gray-50">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" className="h-6 w-6">
                                    <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
                                    <path d="M12 7v5l3 3"></path>
                                </svg>
                            </div>
                            <div className="ml-4 mb-4">
                                <h3 className="mb-2 text-lg font-bold leading-6 text-gray-900 dark:text-white">Working
                                    hours</h3>
                                <p className="text-gray-600 dark:text-slate-400">Monday - Friday: 10:00 am - 05:00 pm</p>
                            </div>
                        </li>
                    </ul>
                    <iframe className='rounded-xl mb-7 w-[90%] md:[80%] ml-2  ' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d30665.96729050449!2d73.680632!3d16.104575!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc0103e550c59cd%3A0x9b6c107617920c34!2sMetropolitan%20Institute%20of%20Technology%20%26%20Management!5e0!3m2!1sen!2sus!4v1714278022343!5m2!1sen!2sus" width="600" height="310" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                    </div>
                    {/* <div className='bg-[#a4a4a4] h-[1px] w-full shadow-2xl my-4 '></div> */}
                <div className=' flex flex-col xl:flex-row mt-8 xl:space-x-[3.5rem]'>
                <div className=''>
                    <ul className="mb-6 md:mb-0 md:w-auto ">
                        {/* <li className="flex">
                            <div className="flex h-10 w-10 items-center justify-center rounded bg-blue-900 text-gray-50">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" className="h-6 w-12">
                                    <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"></path>
                                    <path
                                        d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z">
                                    </path>
                                </svg>
                            </div>
                            <div className="ml-4 mb-4 px-6">
                                <h3 className="mb-2 text-lg font-bold leading-6 text-gray-900 dark:text-white">Corporate Office Address
                                </h3>
                                <p className="text-gray-600 dark:text-slate-400">A-303 Shelton Sapphire Plot no 18 & 19, Sector 15, CBD Belapur, Navi Mumbai, Maharashtra 400614</p>
                            </div>
                        </li> */}
                        {/* <li className="flex">
                            <div className="flex h-10 w-10 items-center justify-center rounded bg-blue-900 text-gray-50">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" className="h-6 w-6">
                                    <path
                                        d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2">
                                    </path>
                                    <path d="M15 7a2 2 0 0 1 2 2"></path>
                                    <path d="M15 3a6 6 0 0 1 6 6"></path>
                                </svg>
                            </div>
                            <div className="ml-4 mb-4">
                                <h3 className="mb-2 text-lg leading-6 font-bold text-gray-900 dark:text-white">Contact
                                </h3>
                                <p className="text-gray-600 dark:text-slate-400"> 02362-299195</p>
                                <p className="text-gray-600 dark:text-slate-400">02362-299197</p>
                                <p className="text-gray-600 dark:text-slate-400">admission.mitm@gmail.com</p>
                            </div>
                        </li>
                        <li className="flex">
                            <div className="flex h-10 w-10 items-center justify-center rounded bg-blue-900 text-gray-50">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" className="h-6 w-6">
                                    <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
                                    <path d="M12 7v5l3 3"></path>
                                </svg>
                            </div>
                            <div className="ml-4 mb-4">
                                <h3 className="mb-2 text-lg font-bold leading-6 text-gray-900 dark:text-white">Working
                                    hours</h3>
                                <p className="text-gray-600 dark:text-slate-400">Monday - Friday: 10:00 - 17:00</p>
                            </div>
                        </li> */}
                    </ul>
                    </div>
                       

                    {/* <iframe className='rounded-xl mb-7 w-[90%] md:[80%] ml-2' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d60340.06200269064!2d72.98360242019018!3d19.052572333062205!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c3a83fe3b6a5%3A0xf93e7752534b8d0!2sFourth%20Dimension%20Realty%20-%20Shelton%20Sapphire!5e0!3m2!1sen!2sin!4v1715238395073!5m2!1sen!2sin" width="600" height="310"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                    </div>                    
                <div >
                    </div>



                </div>
                
              
            </div>
        </div>
    </motion.div>
</section>
  )
}

export default Contact