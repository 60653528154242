import React ,{useState}from 'react'
import "../styles/faq.css"
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
function Faq() {
  function scroll(){
    window.scrollTo(0,0);
  }
  const media = [
    {
      type: "video",
      link: "/assets/gallery/4.mp4",
    },
    {
      type: "video",
      link: "/assets/gallery/3.mp4",
    },
    {
      type: "image",
      link: "assets/events/republic_day/2.jpg",

    },
    {
      type: "image",
      link: "assets/events/republic_day/10.jpg",
    },
    {
      type: "video",
      link: "/assets/gallery/1.mp4",
    },
    {
      type: "image",
      link:
        "assets/events/convocation/2024/13.jpg",
    },
    {
      type: "image",
      link:
        "assets/events/convocation/2024/2.jpg",
    },
    {
      type: "image",
      link:
        "/assets/events/rex/2024/2.jpg",
    },   
     {
      type: "image",
      link:
      "assets/events/sports/2024/11.jpg"
    },    
     {
      type: "image",
      link:
      "assets/events/tarang/2024/9.jpg"
    },
  ];

  const [activeMedia, setActiveMedia] = React.useState(media[0]);

  const handleMediaClick = (media) => {
    setActiveMedia(media);
  };
  return (
    
<section className="flex flex-col lg:flex-row justify-center items-center font-change mt-28 mx-2 lg:px-[6rem]">
    
    {/* faq start here */}
    <motion.div id="faq" className="faq-body mx-auto p-4 lg:p-0 lg:w-[50em] lg:mt-[-11rem] "
    initial={{ y:-100,opacity:0 }}
    animate={{ y:0, opacity:1}}
    transition={{ ease: "easeIn", duration: 2 }}
    >
  <div className="faq-header">
    <h3 className="text-[2em] font-semibold flex items-center space-x-2">
      <span>FAQ' </span>
      <span className="text-blue-600">s </span>
      <img src="assets/svg/qmark.png" className="w-9 h-9 mt-2" alt=""/>
      <button type="button" className="w-[5rem] h-[1.8rem] mt-4 bg-blue-950 text-xs text-white px-2 py-1 rounded-lg hover:bg-blue-800">View all</button>
    </h3>
    <h3 className="text-slate-500 my-2">FREQUENTLY ASKED QUESTIONS</h3>
    <div className="w-[7.5em] h-[0.17em] ml-[0.2em] bg-blue-600"></div>
  </div>
  <div className="faq-list text-slate-800">
    <div className="border-b border-gray-200 pb-6">
      <details>
        <summary className="faqfont">What are the graduation requirements?</summary>
        <p className="faq-content leading-[1.4rem] text-[#9e9e9e] text-[1rem]">Please visit the Office of college also here &ensp;<Link to="/Rd" onClick={scroll} className="text-blue-600">graduation requirements</Link></p>
      </details>
    </div>
    <div className="border-b border-gray-200 pb-6">
      <details>
        <summary className="faqfont">Whom to contact for degree admissions?</summary>
        <div className="leading-[1.4rem] text-[#9e9e9e] text-[1rem] flex flex-col">
          <p className="font-semibold text-black">Office of Academics (degree)</p>
          <p>+91 02362299197</p>
          <p><a href="mailto:admission.mitm@gmail.com" className="font-semibold text-[#9e9e9e]">admission.mitm@gmail.com</a></p>
        </div>
      </details>
    </div>
    <div className="border-b border-gray-200 pb-6">
      <details>
        <summary className="faqfont">Whom to contact for diploma admissions?</summary>
        <div className="leading-[1.4rem] text-[#9e9e9e] text-[1rem] flex flex-col">
          <p className="font-semibold text-black">Office of Academics (diploma)</p>
          <p>+91 02362299197</p>
          <p><a href="mailto:admission.mitm@gmail.com" className="font-semibold text-[#9e9e9e]">@admin.mitm.ac.in</a></p>
        </div>
      </details>
    </div>
    <div className="border-b border-gray-200 pb-6">
      <details>
        <summary className="faqfont">Contact for queries related to GATE?</summary>
        <div className="leading-[1.4rem] text-[#9e9e9e] text-[1rem] flex flex-col">
          <p className="font-semibold text-black">Chairman, GATE</p>
          <p>+91 02362299197</p>
          <p><a href="#" className="font-semibold text-[#9e9e9e]">@admin.mitm.ac.in</a></p>
        </div>
      </details>
    </div>
    <div>
      <details>
        <summary className="faqfont">Whom to contact for Faculty Recruitment ?</summary>
        <div className="leading-[1.4rem] text-[#9e9e9e] text-[1rem] flex flex-col">
          <p className="font-semibold text-black">Establishment Unit(1)</p>
          <p>+91 02362299197</p>
          <p><a href="#" className="font-semibold text-[#9e9e9e]">@admin.mitm.ac.in</a></p>
        </div>
      </details>
    </div>
    <div className="border-b border-gray-200 pb-6">
      <details>
        <summary className="faqfont">How to pay fees online?</summary>
        <p className="faq-content leading-[1.4rem] text-[#9e9e9e] text-[1rem]">Please visit the <Link onClick={scroll} to="/Payments" className="text-blue-600">ePAY webpage.</Link></p>
      </details>
    </div>
  </div>
</motion.div>


{/* gallery start here */}
<motion.div className='mx-3 mb-8'
    initial={{ y:-100,opacity:0 }}
    animate={{ y:0, opacity:1}}
    transition={{ ease: "easeIn", duration: 2 }}
>
<div className="gallary-header ">
      <h3 className=" text-[2em] font-semibold flex space-x-2"><span>P&V </span> <span className="text-blue-600">GALLERY </span><img src="assets/svg/gallary.png" className="mx-2 w-9 h-8 mt-2" alt=""/><button type="button" className="w-[5rem] h-[1.8rem] mt-[1rem] bg-blue-950  text-xs  text-white px-2 py-1 rounded-lg hover:bg-blue-800">View all</button></h3>
      <h3 className="text-slate-500 my-[0.4em]">MULTI-HUED REFLECTIONS</h3>
      <div className="w-[7.5em] h-[0.17em] ml-[0.2em] bg-blue-600"></div>
    </div>

    <div className="grid gap-4 mt-3">
      <div>
        {activeMedia.type === "image" ? (
          <img
            className="h-auto w-full max-w-full rounded-lg object-cover object-center md:h-[480px]"
            src={activeMedia.link}
            alt=""
          />
        ) : (
          <video
            controls
            className="h-auto w-full max-w-full rounded-lg object-cover object-center md:h-[480px]"
          >
            <source src={activeMedia.link} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </div>
      <div className="grid grid-cols-5 gap-4">
        {media.map((item, index) => (
          <div key={index}>
            {item.type === "image" ? (
              <img
                onClick={() => handleMediaClick(item)}
                src={item.link}
                className="h-20 max-w-full cursor-pointer rounded-lg object-cover object-center"
                alt="gallery-image"
              />
            ) : (
              <video
                onClick={() => handleMediaClick(item)}
                className="h-20 max-w-full cursor-pointer rounded-lg object-cover object-center"
              >
                <source src={item.link} type="video/mp4" />
                Your browser does not support the video tag.


              </video>
            )}
          </div>
        ))}
      </div>
    </div>
    </motion.div>
</section>
  )
}

export default Faq