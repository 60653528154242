import React from 'react'
import { Table } from 'flowbite-react'
import { motion } from 'framer-motion'

function Helpline() {
  return (
    <motion.section className="md:px-32 px-3 py-6"
    initial={{ y:-40,opacity:0 }}
          animate={{ y:0, opacity:1}}
          transition={{ ease: "easeIn", duration: 1 }}>
    <h1 className='text-3xl underline underline-offset-8 font-bold  my-5 text-center'>Helpline Numbers for Examinations</h1>
    <div className="overflow-x-auto my-7 border border-gray-200 rounded-xl">
    <Table>
      <Table.Head >
        <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg">Cordinator</Table.HeadCell>
        <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg">Contact</Table.HeadCell>
        <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg">E-mail</Table.HeadCell>
      </Table.Head>
      <Table.Body className="divide-y font-bold">
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>Prof. V. Kushe</Table.Cell>
          <Table.Cell><a href='tel:+91 94238 33155'>+91 94238 33155</a></Table.Cell>
          <Table.Cell><a href='mailto:abc@gmail.com'>vishalkushe7@gmail.com</a> </Table.Cell>
        </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>Main Office</Table.Cell>
          <Table.Cell><a href='tel:+91 02362299197'>+91 02362299197</a></Table.Cell>
          <Table.Cell><a href='mailto:admission.mitm@gmail.com'>admission.mitm@gmail.com</a> </Table.Cell>
          </Table.Row>       
        
      </Table.Body>
    </Table>
  </div>


  </motion.section>
  )
}

export default Helpline