import React from "react";
import { Table } from "flowbite-react";
import { motion } from "framer-motion";
function Rd() {
  return (
    <motion.section className="md:px-32 px-3 py-6" 
    initial={{ y:-40,opacity:0 }}
    animate={{ y:0, opacity:1}}
    transition={{ ease: "easeIn", duration: 1 }}
    >

        {/* 1st year degree */}
 <h1 className='text-xl underline underline-offset-8 font-bold  my-3 text-center'>Documents Required for First Year Engineering Degree</h1>
    <div className="overflow-x-auto my-7 border border-gray-200 rounded-xl">
    <Table>
      <Table.Head >
        <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg">Sr.No.</Table.HeadCell>
        <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg">First Year Degree</Table.HeadCell>
      </Table.Head>
      <Table.Body className="divide-y font-bold">
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>1</Table.Cell>
          <Table.Cell>SSC Marksheet</Table.Cell>
        </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>2</Table.Cell>
          <Table.Cell>HSC Marksheet</Table.Cell>
          </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>3</Table.Cell>
          <Table.Cell>12 th School/College Leaving Certificate</Table.Cell>
        </Table.Row>       
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>4</Table.Cell>
          <Table.Cell>Domicile and Nationality Certificate</Table.Cell>
        </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>5</Table.Cell>
          <Table.Cell>Aadhar Card (Photocopy)</Table.Cell>
        </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>6</Table.Cell>
          <Table.Cell>Caste /Tribe Certificate for SC/ST/OBC/VJ/DT-NT (A/B/C/D) / SEBC Categories</Table.Cell>
          </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>7</Table.Cell>
          <Table.Cell>Caste Validity Certificate for OBC/VJ/DT-NT (A/B/C/D) / SEBC Categories (If Applicable)</Table.Cell>
        </Table.Row>       
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>8</Table.Cell>
          <Table.Cell>Non Creamy Layer certificate valid upto 31 st March, 2024 (If Applicable)</Table.Cell>
        </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>9</Table.Cell>
          <Table.Cell>Migration certificate (Other than M.S. Board/University) (If Applicable)</Table.Cell>
          </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>10</Table.Cell>
          <Table.Cell>Defence Certificate (If Applicable)</Table.Cell>
        </Table.Row>       
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>11</Table.Cell>
          <Table.Cell>Physically Handicapped Certificate (If Applicable)</Table.Cell>
        </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>12</Table.Cell>
          <Table.Cell>Gap certificate (If Applicable)</Table.Cell>
        </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>13</Table.Cell>
          <Table.Cell>03 photographs (If Applicable)</Table.Cell>
          </Table.Row>
      </Table.Body>
    </Table>
  </div>
  
  {/* DSE  */}
  <h1 className='text-xl underline underline-offset-8 font-bold  my-3 text-center'>Documents Required for Direct Second Year Engineering Degree</h1>
    <div className="overflow-x-auto my-7 border border-gray-200 rounded-xl">
    <Table>
      <Table.Head >
        <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg">Sr.No.</Table.HeadCell>
        <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg">Second Year Degree</Table.HeadCell>
      </Table.Head>
      <Table.Body className="divide-y font-bold">
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>1</Table.Cell>
          <Table.Cell>Diploma Marksheet</Table.Cell>
        </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>2</Table.Cell>
          <Table.Cell>SSC Marksheet</Table.Cell>
          </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>3</Table.Cell>
          <Table.Cell>HSC Marksheet</Table.Cell>
        </Table.Row>       
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>4</Table.Cell>
          <Table.Cell>Diploma College Leaving Certificate</Table.Cell>
        </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>5</Table.Cell>
          <Table.Cell>Domicile and Nationality Certificate</Table.Cell>
        </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>6</Table.Cell>
          <Table.Cell>MSBTE Migration Certificate</Table.Cell>
          </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>7</Table.Cell>
          <Table.Cell>Mobile Number Link Aadhar Card (Photocopy)</Table.Cell>
        </Table.Row>       
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>8</Table.Cell>
          <Table.Cell>Caste /Tribe Certificate for SC/ST/OBC/VJ/DT-NT (A/B/C/D) / SEBC Categories (If Applicable)</Table.Cell>
        </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>9</Table.Cell>
          <Table.Cell>Caste Validity Certificate for OBC/VJ/DT-NT (A/B/C/D) / SEBC Categories (If Applicable)</Table.Cell>
          </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>10</Table.Cell>
          <Table.Cell>Non Creamy Layer certificate valid upto 31 st March, 2024 (If Applicable)</Table.Cell>
        </Table.Row>       
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>11</Table.Cell>
          <Table.Cell>Migration certificate (Other than M.S. Board/University) (If Applicable)</Table.Cell>
        </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>12</Table.Cell>
          <Table.Cell>Defence Certificate (If Applicable)</Table.Cell>
        </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>13</Table.Cell>
          <Table.Cell>Physically Handicapped Certificate (If Applicable)</Table.Cell>
          </Table.Row>
          <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>14</Table.Cell>
          <Table.Cell>Gap certificate (If Applicable)</Table.Cell>
          </Table.Row>
          <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>15</Table.Cell>
          <Table.Cell>03 photographs (If Applicable)</Table.Cell>
          </Table.Row>
      </Table.Body>
    </Table>
  </div>

  {/* 1st year diploma  */}
  <h1 className='text-xl underline underline-offset-8 font-bold  my-3 text-center'>Documents Required for First Year Engineering Diploma</h1>
    <div className="overflow-x-auto my-7 border border-gray-200 rounded-xl">
    <Table>
      <Table.Head >
        <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg">Sr.No.</Table.HeadCell>
        <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg">First Year Engineering Diploma</Table.HeadCell>
      </Table.Head>
      <Table.Body className="divide-y font-bold">
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>1</Table.Cell>
          <Table.Cell>SSC Marksheet</Table.Cell>
        </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>2</Table.Cell>
          <Table.Cell>8th, 9th Marklist</Table.Cell>
          </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>3</Table.Cell>
          <Table.Cell>Leaving Certificate</Table.Cell>
        </Table.Row>       
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>4</Table.Cell>
          <Table.Cell>Domicile and Nationality Certificate</Table.Cell>
        </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>5</Table.Cell>
          <Table.Cell>Mobile Number Link Aadhar Card (Photocopy)</Table.Cell>
        </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>6</Table.Cell>
          <Table.Cell>Caste /Tribe Certificate for SC/ST/OBC/VJ/DT-NT (A/B/C/D) / SEBC Categories (If Applicable)</Table.Cell>
          </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>7</Table.Cell>
          <Table.Cell>Caste Validity Certificate for OBC/VJ/DT-NT (A/B/C/D) / SEBC Categories (If Applicable)</Table.Cell>
        </Table.Row>       
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>8</Table.Cell>
          <Table.Cell>Non Creamy Layer certificate valid upto 31 st March, 2024 (If Applicable)</Table.Cell>
        </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>9</Table.Cell>
          <Table.Cell>Defence Certificate (If Applicable)</Table.Cell>
          </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>10</Table.Cell>
          <Table.Cell>Physically Handicapped Certificate (If Applicable)</Table.Cell>
        </Table.Row>       
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>11</Table.Cell>
          <Table.Cell>Gap certificate (If Applicable)</Table.Cell>
        </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>12</Table.Cell>
          <Table.Cell>03 photographs (If Applicable)</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  </div>

  {/* DSE Diploma  */}
  <h1 className='text-xl underline underline-offset-8 font-bold  my-3 text-center'>Documents Required for Direct Second Year Engineering Diploma</h1>
    <div className="overflow-x-auto my-7 border border-gray-200 rounded-xl">
    <Table>
      <Table.Head >
        <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg">Sr.No.</Table.HeadCell>
        <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg">Direct Second Year Engineering Diploma</Table.HeadCell>
      </Table.Head>
      <Table.Body className="divide-y font-bold">
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>1</Table.Cell>
          <Table.Cell>SSC Marksheet</Table.Cell>
        </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>2</Table.Cell>
          <Table.Cell>HSC Marklist(Science/ITI/MCVC)</Table.Cell>
          </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>3</Table.Cell>
          <Table.Cell>Leaving Certificate</Table.Cell>
        </Table.Row>       
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>4</Table.Cell>
          <Table.Cell>Domicile and Nationality Certificate</Table.Cell>
        </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>5</Table.Cell>
          <Table.Cell>Mobile Number Link Aadhar Card (Photocopy)</Table.Cell>
        </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>6</Table.Cell>
          <Table.Cell>Caste /Tribe Certificate for SC/ST/OBC/VJ/DT-NT (A/B/C/D) / SEBC Categories (If Applicable)</Table.Cell>
          </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>7</Table.Cell>
          <Table.Cell>Caste Validity Certificate for OBC/VJ/DT-NT (A/B/C/D) / SEBC Categories (If Applicable)</Table.Cell>
        </Table.Row>       
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>8</Table.Cell>
          <Table.Cell>Non Creamy Layer certificate valid upto 31 st March, 2024 (If Applicable)</Table.Cell>
        </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>9</Table.Cell>
          <Table.Cell>Defence Certificate (If Applicable)</Table.Cell>
          </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>10</Table.Cell>
          <Table.Cell>Physically Handicapped Certificate (If Applicable)</Table.Cell>
        </Table.Row>       
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>11</Table.Cell>
          <Table.Cell>Gap certificate (If Applicable)</Table.Cell>
        </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>12</Table.Cell>
          <Table.Cell>03 photographs (If Applicable)</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  </div>

  {/* FE bsc.it  */}
  <h1 className='text-xl underline underline-offset-8 font-bold  my-3 text-center'>Documents Required for First Year BS.C Course</h1>
    <div className="overflow-x-auto my-7 border border-gray-200 rounded-xl">
    <Table>
      <Table.Head >
        <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg">Sr.No.</Table.HeadCell>
        <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg"> First Year BS.C Course</Table.HeadCell>
      </Table.Head>
      <Table.Body className="divide-y font-bold">
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>1</Table.Cell>
          <Table.Cell>SSC Marksheet</Table.Cell>
        </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>2</Table.Cell>
          <Table.Cell>HSC Marksheet</Table.Cell>
          </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>3</Table.Cell>
          <Table.Cell>12 th School/College Leaving Certificate</Table.Cell>
        </Table.Row>       
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>4</Table.Cell>
          <Table.Cell>Domicile and Nationality Certificate</Table.Cell>
        </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>5</Table.Cell>
          <Table.Cell>Aadhar Card (Photocopy)</Table.Cell>
        </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>6</Table.Cell>
          <Table.Cell>Caste /Tribe Certificate for SC/ST/OBC/VJ/DT-NT (A/B/C/D) / SEBC Categories (If Applicable)</Table.Cell>
          </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>7</Table.Cell>
          <Table.Cell>Caste Validity Certificate for OBC/VJ/DT-NT (A/B/C/D) / SEBC Categories (If Applicable)</Table.Cell>
        </Table.Row>       
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>8</Table.Cell>
          <Table.Cell>Non Creamy Layer certificate valid upto 31 st March, 2024 (If Applicable)</Table.Cell>
        </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>9</Table.Cell>
          <Table.Cell>Migration certificate (Other than M.S. Board/University) (If Applicable)</Table.Cell>
          </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>10</Table.Cell>
          <Table.Cell>Defence Certificate (If Applicable)</Table.Cell>
        </Table.Row>       
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>11</Table.Cell>
          <Table.Cell>Physically Handicapped Certificate (If Applicable)</Table.Cell>
        </Table.Row>
        <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>12</Table.Cell>
          <Table.Cell>Gap certificate (If Applicable)</Table.Cell>
        </Table.Row>       
         <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>13</Table.Cell>
          <Table.Cell>03 photographs (If Applicable)</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  </div>
    </motion.section>
  );
}

export default Rd;
