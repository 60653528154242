import { motion } from "framer-motion";
import "./calendar.css";

const data = [
  {
    id: 1,
    name: "John Deere Aptitude Test conducted",
    date: "03/02/2023",
    mode: "Online",
    participant: "Diploma Mechanical",
    place: "Computer Lab",
  },
  {
    id: 1,
    name: "International Placement Session",
    date: "06/02/2023",
    mode: "Offline",
    participant: "All Degree and Diploma Students",
    place: "Seminar Hall",
  },
  {
    id: 1,
    name: "EDP Awareness Visit",
    date: "21/02/2023",
    mode: "Offline",
    participant: "Last Year Degree and Diploma Students",
    place: "Kankavli",
  },
  {
    id: 1,
    name: "John Deere Campus Drive",
    date: "23/02/2023",
    mode: "Off-Campus Drive",
    participant: "Last Year Mechanical Diploma Students ",
    place: "Ashokrao Mane Polytechnic College, Kolhapur",
  },
  {
    id: 1,
    name: "Current Position of Market for Campus Placement Talk",
    date: "24/02/2023",
    mode: "Offline",
    participant: "All Degree and Diploma Students",
    place: "Seminar Hall",
  },
  {
    id: 1,
    name: "Laxmi Organics Campus Drive",
    date: "02/03/2023",
    mode: "Off-Campus Drive",
    participant: "BE Mechanical Students",
    place: "Gharda Enguneering College, Lavel",
  },
  {
    id: 1,
    name: "KSPG Automotive, Pune",
    date: "02/03/2023",
    mode: "Off-Campus Drive",
    participant: "Last Year Mechanical Diploma Students",
    place: "YBP, Sawantwadi",
  },
  {
    id: 1,
    name: "Java by Kiran",
    date: "18/03/2023",
    mode: "Campus Drive",
    participant: "BE Computer Engineering, B.Sc.CS, B.Sc.IT, BCA",
    place: "MITM, Sindhudurg",
  },
  {
    id: 1,
    name: "OAK Technology Pvt. Ltd.",
    date: "19/03/2023",
    mode: "Off-Campus Drive",
    participant: "BE Mech.& Last Year Mechanical Diploma Students",
    place: "GP, Malvan",
  },
  {
    id: 1,
    name: "Bajaj Auto",
    date: "20/03/2023",
    mode: "Online Campus Drive",
    participant: "Last Year Mechanical Diploma Students",
    place: "Computer Lab",
  },
  {
    id: 1,
    name: "Q-Spider",
    date: "10/04/2023",
    mode: "Online Campus Drive",
    participant: "BE Computer Engineering",
    place: "Computer Lab",
  },
  {
    id: 1,
    name: "TATA Motors, Pune",
    date: "27/05/2023",
    mode: "Walk in Interview",
    participant: "TE Mechanical Engineering",
    place: "Pune",
  },
  {
    id: 1,
    name: "JAN's Pvt. Ltd.",
    date: "06/06/2023",
    mode: "Job Fair",
    participant: "BE Civil Engineering",
    place: "Kudal",
  },
  {
    id: 1,
    name: "NYATI Unitree, Pune",
    date: "10/06/2023",
    mode: "Walk in Interview",
    participant: "BE Civil Engineering",
    place: "Yerwada, Pune",
  },
  {
    id: 1,
    name: "Tata Motors and Rudra Motors",
    date: "13/06/2023",
    mode: "Off-Campus Drive",
    participant: "BE Mech., ITI, Last Year Mech. Diploma students",
    place: "MITM, Sindhudurg",
  },
  {
    id: 1,
    name: "TPO Summit Mumbai Edition",
    date: "30/06/2023",
    mode: "Offline",
    participant: "All TPO",
    place: "SPJIMR, Bhavan's College, Andheri",
  },
];

const Calendar = () => {
  return (
    <motion.div className="table-container overflow-x-auto mx-auto my-6 font-change"
    initial={{ y:-40,opacity:0 }}
          animate={{ y:0, opacity:1}}
          transition={{ ease: "easeIn", duration: 1 }}>
      <table className="min-w-full divide-y ">
        <thead>
          <tr>
            <th className="px-6 py-3 text-left text-md font-medium text-white uppercase tracking-wider">
              Sr. No.
            </th>
            <th className="px-6 py-3 text-left text-md font-medium text-white uppercase tracking-wider">
              Placement Activity
            </th>
            <th className="px-6 py-3 text-left text-md font-medium text-white uppercase tracking-wider">
              Date
            </th>
            <th className="px-6 py-3 text-left text-md font-medium text-white uppercase tracking-wider">
              Mode
            </th>
            <th className="px-6 py-3 text-left text-md font-medium text-white uppercase tracking-wider">
              Participant
            </th>
            <th className="px-6 py-3 text-left text-md font-medium text-white uppercase tracking-wider">
              Place
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {data.map((company, index) => (
            <tr
              key={company.id}
              className={index % 2 === 0 ? "bg-blue-100" : "bg-blue-50"}
            >
              <td className="px-6 py-4 whitespace-normal break-words">
                {index + 1}
              </td>
              <td className="px-6 py-4 whitespace-normal break-words">
                {company.name}
              </td>
              <td className="px-6 py-4 whitespace-normal break-words">
                {company.date}
              </td>
              <td className="px-6 py-4 whitespace-normal break-words">
                {company.mode}
              </td>
              <td className="px-6 py-4 whitespace-normal break-words">
                {company.participant}
              </td>
              <td className="px-6 py-4 whitespace-normal break-words">
                {company.place}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </motion.div>
  );
};

export default Calendar;
