import { motion } from 'framer-motion';
import React from 'react'

function CentralLib() {
    const data = [
        {
          imgelink:
            "/assets/facilities/centrallib/lab1.jpeg",
        },
        {
          imgelink:
            "/assets/facilities/centrallib/lab2.jpeg",
        },
        {
          imgelink:
            "/assets/facilities/centrallib/lab3.jpeg",
        },
        {
          imgelink:
            "/assets/facilities/centrallib/lab4.jpeg",
        },
        {
          imgelink:
            "/assets/facilities/centrallib/lab5.jpeg",
        },
      ];
     
      const [active, setActive] = React.useState(
        "/assets/facilities/centrallib/lab2.jpeg"
      );
  return (
    <motion.div className='md:px-52 px-8 py-10 font-change'
    initial={{ y:-40,opacity:0 }}
          animate={{ y:0, opacity:1}}
          transition={{ ease: "easeIn", duration: 1 }}>
        <h1 className='text-4xl  font-bold mb-8 text-center underline underline-offset-8'>Central Library </h1>
    <div className="grid gap-4 md:w-[80%] mx-auto ">
      <div>
        <img
          className="h-auto  max-w-full rounded-lg object-cover object-center "
          src={active}
          alt=""
        />
      </div>
      <div className="grid grid-cols-5 gap-4">
        {data.map(({ imgelink }, index) => (
          <div key={index}>
            <img
              onClick={() => setActive(imgelink)}
              src={imgelink}
              className=" max-w-full cursor-pointer rounded-lg object-cover object-center"
              alt="gallery-image"
            />
          </div>
        ))}
      </div>
    </div>
    <div className='bg-[#f2f0f0] h-[1px] w-full shadow-2xl mt-10'></div>
        <div className='font-semibold text-center'>
          <br />
          <h2 className='text-2xl md:text-3xl  font-bold '>Vision</h2>
          <br />
          <p className="md:text-lg text-base text-justify text-black/70">To provide student centered learning environment where student can learn beyond the classroom and also to deliver information and scholarly resources conveniently to Students and Staff.</p>
          <br />
          <h2 className='text-2xl md:text-3xl font-bold '>Mission</h2>
          <br />
          <p className="md:text-lg text-base text-justify text-black/70">To Provide comprehensive resources and services in support of teaching and learning needs of academic community.To facilitate access to the information so as to help the students and staff to help in enhancing their knowledge base.Putting technology for the flexible delivery of services and resources to users regardless of location within the campus.</p>
    </div>
    </motion.div>
  )
}

export default CentralLib