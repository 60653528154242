import { motion } from 'framer-motion'
import React from 'react'
import { Link } from 'react-router-dom'

function Extcpospo() {
  return (
    <div className='md:px-20 px-8 py-10 font-change'>
            <h1 className='text-4xl underline underline-offset-8 font-bold text-orange-500  mb-8 text-center'>ELECTRONICS & TELECOMUNICATION ENGINEERING</h1>
    <div className='mb-14 mx-auto flex justify-center text-sm md:text-base'>
<Link to='/Aboutextc' className='md:px-8 px-4 py-3 bg-blue-900 hover:bg-blue-800 text-white rounded-l-lg'>About US</Link>
<Link to='/Extcpospo' className='md:px-8 py-3 px-4 bg-blue-600 text-white '>PO PSO PEO</Link>
<Link to='/Extclabo' className='md:px-8 py-3 px-4 bg-blue-900 hover:bg-blue-800 text-white rounded-r-lg'>Laboratories</Link>
    </div>

    <br />
    <motion.div
    initial={{ y:-40,opacity:0 }}
    animate={{ y:0, opacity:1}}
    transition={{ ease: "easeIn", duration: 1 }}>
        <h2 className='text-2xl md:text-3xl text-justify font-bold '>Program Outcomes (PO)</h2>
        <br />
        <p className='font-semibold text-lg '>Students graduating from Electronics and Telecommunication Engineering must demonstrate: </p>
        <ul className='list-decimal list-inside md:text-lg  text-base text-justify font-semibold text-black/70'>
        <br />
        <li>An ability to apply  knowledge of Mathematics, Science and  Engineering </li>
        <br />
        <li>An ability to design  and conduct experiments, as well as to analyze and interpret data, in the areas of Digital Design, Microprocessor, VLSI, Communication System and  Digital Signal Processing </li>
        <br />
        <li>An ability to design electronic circuits and VLSI components to meet desired needs within realistic constraints such as economic, environmental, social, political, ethical, health and safety </li>                
        <br />
        <li>An ability to function on multidisciplinary teams. </li>               
        <br />
        <li>An ability to identify, formulate and solve engineering problems.</li>
        <br />            
        <li>An ability to understand the professional and  ethical responsibility.</li>
        <br />
        <li>Ability to communicate effectively.</li>
        <br />
        <li>An ability to understand the impact of engineering solutions in a global, economic,   environmental and societal context</li>
        <br />
        <li>Recognition of the need for, and an ability to engage in life-long learning.</li>
        <br />
        <li>Ability to solve contemporary issues</li>
        <br />
        <li>An ability to use the techniques, skills, and modern engineering tools necessary for Electronics and Telecommunication Engineering.</li>
        <br />
    </ul>

    <h2 className='text-2xl md:text-3xl text-justify font-bold'>Programme specific outcomes (PSOs)</h2>
        <br />
        <ul className='md:text-lg text-base text-justify font-semibold text-black/70'>
        <br />
        <li><span className='text-black font-bold'>PSO1:</span> &ensp;Ability to analyze, formulate, synthesize data and technical concepts to solve real life problems.</li>
        <br />
        <li><span className='text-black font-bold'>PSO2:</span> &ensp;Ability to apply knowledge and skills for diverse domains with creativity, commitment and social consciousness.</li>
        <br />
        <li><span className='text-black font-bold'>PSO3:</span> &ensp;To explore multidisciplinary fields and professional capabilities to approach local and global needs.</li>
        <br />
        <li><span className='text-black font-bold'>PSO4:</span> &ensp;To prepare students to use modern tools effectively to solve real life problems.</li>
        <br />
    </ul>

    <h2 className='text-2xl md:text-3xl text-justify font-bold'>Program Educational Objectives (PEOs)</h2>
        <br />
        <ul className='md:text-lg text-base text-justify font-semibold text-black/70'>
        <br />
        <li><span className='text-black font-bold'>PEO1:</span> &ensp;Apply technical expertise in their professional career for contemporary problem solving in the field of Electronics and Telecommunication Engineering.</li>
        <br />
        <li><span className='text-black font-bold'>PEO2:</span> &ensp; Respond to the growing and changing needs of society through lifelong learning.</li>
        <br />
        <li><span className='text-black font-bold'>PEO3:</span> &ensp;Demonstrate leadership, commitment and maintain ethics in professional career.</li>
        <br />
        <li><span className='text-black font-bold'>PEO4:</span> &ensp;Demonstrate effective communication skills and the ability to work efficiently at individual  level and as part of  a team.</li>
        <br />
    </ul>
  </motion.div>
  </div>
  )
}

export default Extcpospo