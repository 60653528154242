import React from 'react';
import {
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
  } from "@material-tailwind/react";
import { motion } from 'framer-motion';

function Shivjayanti() {
    const data = [
        {
          label: "2024",
          value: "2024",
          images: [
            {
              imageLink:
                "/assets/events/shivjayanti/1.jpg",
            },
            {
              imageLink:
              "/assets/events/shivjayanti/5.jpg",
            },
            {
              imageLink:
              "/assets/events/shivjayanti/11.jpg",
            },
            {
              imageLink:
              "/assets/events/shivjayanti/0.jpg",
            },
            {
              imageLink:
              "/assets/events/shivjayanti/6.jpg",
            },
            {
                imageLink:
                "/assets/events/shivjayanti/7.jpg",
              },
              {
                imageLink:
                "/assets/events/shivjayanti/8.jpg",
              },
              {
                imageLink:
                "/assets/events/shivjayanti/9.jpg",
              },
              {
                imageLink:
                "/assets/events/shivjayanti/10.jpg",
              },
              {
                imageLink:
                "/assets/events/shivjayanti/2.jpg",
              },
              {
                imageLink:
                "/assets/events/shivjayanti/12.jpg",
              },
              {
                imageLink:
                "/assets/events/shivjayanti/13.jpg",
              },
              {
                imageLink:
                "/assets/events/shivjayanti/14.jpg",
              },
              {
                imageLink:
                "/assets/events/shivjayanti/15.jpg",
              },
            
            
    
          ],
        },
      ];
  return (
    <motion.div className='md:p-16 p-6 font-change'
    initial={{ y:-40,opacity:0 }}
          animate={{ y:0, opacity:1}}
          transition={{ ease: "easeIn", duration: 1 }}>
        <h1 className='text-4xl  font-bold mb-8 text-center underline underline-offset-8'>Shiv Jayanti</h1>
        <p className='text-base font-semibold md:text-xl my-4 text-black/80'>Shiv Jayanti in MITM.</p>

    <Tabs value="2024">
    <TabsHeader className='z-[5]'>
      {data.map(({ label, value }) => (
        <Tab key={value} value={value} className='bg-gray-200'>
          {label}
        </Tab>
      ))}
    </TabsHeader>
    <TabsBody className="grid grid-cols-1 gap-4 ">
      {data.map(({ value, images }) => (
        <TabPanel
          className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:grid-cols-3"
          key={value}
          value={value}
        >
          {images?.map(({ imageLink }, index) => (
            <div key={index}>
              <img
                className=" w-full max-w-full rounded-lg object-cover object-center"
                src={imageLink}
                alt="image-photo"
              />
            </div>
          ))}
        </TabPanel>
      ))}
    </TabsBody>
  </Tabs>
  </motion.div>
  )
}

export default Shivjayanti