import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
function Dmechpospo() {
  return (
    <div className='md:px-20 px-8 py-10 font-change'>
       <h1 className='text-4xl underline underline-offset-8 font-bold text-orange-500  mb-8 text-center'>DIPLOMA IN MECHANICAL ENGINEERING</h1>
    <div className='mb-14 mx-auto flex justify-center text-sm md:text-base'>
<Link to='/AboutDmech' className='md:px-8 px-4 py-3 bg-blue-900 hover:bg-blue-800 text-white rounded-l-lg'>About US</Link>
<Link to='/Dmechpospo' className='md:px-8 py-3 px-4 bg-blue-600 text-white '>PO PSO PEO</Link>
<Link to='/Dmechlabo' className='md:px-8 py-3 px-4 bg-blue-900 hover:bg-blue-800 text-white rounded-r-lg'>Laboratories</Link>
    </div>

    <br />
    <motion.div
    initial={{ y:-40,opacity:0 }}
    animate={{ y:0, opacity:1}}
    transition={{ ease: "easeIn", duration: 1 }}>
        <h2 className='text-2xl md:text-3xl text-justify font-bold '>Program Outcomes (PO)</h2>
        <br />
        <p className='font-semibold text-lg '>Students graduating from Mechanical Engineering will demonstrate:</p>
        <ul className='list-decimal list-inside md:text-lg  text-base text-justify font-semibold text-black/70'>
        <br />
        <li>An ability to apply knowledge of Mathematics, Science and Engineering.</li>
        <br />
        <li>An ability to design and conduct experiments, as well as to analyze and interpret data, in different areas of Design Engineering, Heat Power, Industrial Engineering, Manufacturing and related Management.</li>
        <br />
        <li>Ability to design a system or process to meet rapidly changing computing problems and information system environment considering the constraints such as economic, environmental, social, political, ethical, health and safety.</li>                
        <br />
        <li>An ability to design a system, component or process to meet desired needs within realistic constraints such as economic, environmental, social, political, ethical, health and safety, manufacturability and sustainability.</li>               
        <br />
        <li>An ability to function on multidisciplinary teams.</li>
        <br />            
        <li>An ability to identify, formulate and solve Mechanical Engineering problems.</li>
        <br />
        <li>An understanding of professional and ethical responsibility.</li>
        <br />
        <li>Ability to communicate effectively.</li>
        <br />
        <li> The broad education necessary to understand the impact of engineering solutions in a global, economic, environmental and societal context.</li>
        <br />
        <li>Recognition of the need for, and an ability to engage in life-long learning.</li>
        <br />
        <li>Ability to solve contemporary issues</li>
        <br />
        <li>An ability to use techniques, skills and modern software and machine tools necessary in the practice of Mechanical Engineering profession.</li>
        <br />
    </ul>

    <h2 className='text-2xl md:text-3xl text-justify font-bold'>Programme specific outcomes (PSOs)</h2>
        <br />
        <ul className='md:text-lg text-base text-justify font-semibold text-black/70'>
        <br />
        <li><span className='text-black font-bold'>PSO1:</span> &ensp;Ability to analyze, formulate, synthesize data and technical concepts to solve real life problems.</li>
        <br />
        <li><span className='text-black font-bold'>PSO2:</span> &ensp;Ability to apply knowledge and skills for diverse domains with creativity, commitment and social consciousness.</li>
        <br />
        <li><span className='text-black font-bold'>PSO3:</span> &ensp;To explore multidisciplinary fields and professional capabilities to approach local and global needs.</li>
        <br />
        <li><span className='text-black font-bold'>PSO4:</span> &ensp;To prepare students to use modern tools effectively to solve real life problems.</li>
        <br />
    </ul>

    <h2 className='text-2xl md:text-3xl text-justify font-bold'>Program Educational Objectives (PEOs)</h2>
        <br />
        <ul className='md:text-lg text-base text-justify font-semibold text-black/70'>
        <br />
        <li><span className='text-black font-bold'>PEO1:</span> &ensp;Apply technical expertise to interpret, analyze and solve complex and emerging technical problems in the field of Design Engineering, Heat Power, Industrial Engineering, Manufacturing and related Management.</li>
        <br />
        <li><span className='text-black font-bold'>PEO2:</span> &ensp;Demonstrate strong leadership and communication skills and ability to function effectively as an individual as well as part of a team.</li>
        <br />
        <li><span className='text-black font-bold'>PEO3:</span> &ensp;Demonstrate a sense of moral and ethical standards in their professional career.</li>
        <br />
        <li><span className='text-black font-bold'>PEO4:</span> &ensp;Engage in lifelong learning.</li>
        <br />
    </ul>
  </motion.div>
  </div>
  )
}

export default Dmechpospo