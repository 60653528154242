import React from "react";
import { Link } from "react-router-dom";
import "../navbar_items/styles/items.css";
function Course(props) {
  return (
    <>
        <div className="group inline-block text-base">
          <button className="outline-none focus:outline-none  px-3 py-2 text-start xlc:text-center rounded-sm flex items-center min-w-[6rem]  hover:bg-blue-800">
            <span className="pr-1 font-semibold  flex-1 ">Courses</span>
            <span>
            <svg
              className="fill-current h-4 w-4 transform group-hover:-rotate-180 xlc:hidden block
        transition duration-150 ease-in-out"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </span>
          </button>
          <ul
            className="bg-white border rounded-sm transform scale-0 group-hover:scale-100 absolute 
  transition duration-300 ease-in-out origin-top w-[50%] xlc:w-[10%] xlc:min-w-32 mt-1 z-10"
          >
            <li className="rounded-sm relative px-3 py-3 rounded-t bg-blue-100 text-black  hover:bg-blue-800 hover:text-white shadow-lg">
              <button className="w-full text-left flex items-center outline-none focus:outline-none">
                <span className="pr-1 flex-1">Engineering</span>
                <span className="mr-auto">
                  <svg
                    className="fill-current h-4 w-4
            transition duration-150 ease-in-out"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </span>
              </button>
              <ul
                className="bg-white border rounded-sm absolute top-0 right-0 
  transition duration-300 ease-in-out origin-top-left
  md:min-w-[25rem] min-w-[10rem] 
  "
              >
                <li className="px-1 py-3 rounded-t bg-blue-100 text-black  hover:bg-blue-800 hover:text-white shadow-lg">
                  <Link to="/Computer" onClick={props.onclick} className="px-3 py-3">
                    Computer Engineering
                  </Link>
                </li>
                <li className="px-1 py-3 rounded-t bg-blue-100 text-black  hover:bg-blue-800 hover:text-white shadow-lg">
                  <Link onClick={props.onclick} to="/Extc" className="px-3 py-3">
                    Electronics & Telecommunication Engineering
                  </Link>
                </li>
                <li className="px-1 py-3 rounded-t bg-blue-100 text-black  hover:bg-blue-800 hover:text-white shadow-lg">
                  <Link onClick={props.onclick} to="/Mechanical" className="px-3 py-3">
                    Mechanical Engineering
                  </Link>
                </li>
                <li className="px-1 py-3 rounded-t bg-blue-100 text-black  hover:bg-blue-800 hover:text-white shadow-lg">
                  <Link onClick={props.onclick} to="Civil" className="px-3 py-3">
                    Civil Engineering
                  </Link>
                </li>
              </ul>
            </li>

            <li className="rounded-sm relative px-3 py-3 rounded-t bg-blue-100 text-black  hover:bg-blue-800 hover:text-white shadow-lg">
              <button className="w-full text-left flex items-center outline-none focus:outline-none">
                <span className="pr-1 flex-1">Diploma</span>
                <span className="mr-auto">
                  <svg
                    className="fill-current h-4 w-4
            transition duration-150 ease-in-out"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </span>
              </button>
              <ul
                className="bg-white border rounded-sm absolute top-0 right-0 
  transition duration-150 ease-in-out origin-top-left
  md:min-w-[25rem] min-w-[10rem] 
  "
              >
                <li className="px-1 py-3 rounded-t bg-blue-100 text-black  hover:bg-blue-800 hover:text-white shadow-lg">
                  <Link onClick={props.onclick} to="/Dmechanical" className="px-3 py-3">
                    Mechanical Engineering
                  </Link>
                </li>
                <li className="px-1 py-3 rounded-t bg-blue-100 text-black  hover:bg-blue-800 hover:text-white shadow-lg">
                  <Link onClick={props.onclick} to="/Dcivil" className="px-3 py-3">
                    Civil Engineering
                  </Link>
                </li>
              </ul>
            </li>

            <li className="rounded-sm relative px-3 py-3 rounded-t bg-blue-100 text-black  hover:bg-blue-800 hover:text-white shadow-lg">
              <button className="w-full text-left flex items-center outline-none focus:outline-none">
                <span className="pr-1 flex-1">B.Sc.</span>
                <span className="mr-auto">
                  <svg
                    className="fill-current h-4 w-4
            transition duration-150 ease-in-out"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </span>
              </button>
              <ul
                className="bg-white border rounded-sm absolute top-0 right-0 
  transition duration-150 ease-in-out origin-top-left
  md:min-w-[25rem] min-w-[10rem] 
  "
              >
                <li className="px-1 py-3 rounded-t bg-blue-100 text-black  hover:bg-blue-800 hover:text-white shadow-lg">
                  <Link onClick={props.onclick} to="/Bsccs" className="px-3 py-3">
                    Computer Science
                  </Link>
                </li>
                <li className="px-1 py-3 rounded-t bg-blue-100 text-black  hover:bg-blue-800 hover:text-white shadow-lg">
                  <Link onClick={props.onclick} to="Bscit" className="px-3 py-3">
                    Information technology
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
    </>
  );
}

export default Course;
