import { motion } from 'framer-motion';
import React from 'react'

function MajorR() {
    const data = [
        {
            imageLink:
              "/assets/T&p/MR/1.jpg",
          },
          {
            imageLink:
            "/assets/T&p/MR/2.jpg",
          },
          {
            imageLink:
            "/assets/T&p/MR/3.jpg",
          },
          {
            imageLink:
            "/assets/T&p/MR/4.jpg",
          },
          {
            imageLink:
            "/assets/T&p/MR/5.jpg",
          },
          {
            imageLink:
            "/assets/T&p/MR/6.jpg",
          },
          {
              imageLink:
              "/assets/T&p/MR/7.jpg",
            },
            {
              imageLink:
              "/assets/T&p/MR/8.jpg",
            },
            {
              imageLink:
              "/assets/T&p/MR/9.jpg",
            },
            {
              imageLink:
              "/assets/T&p/MR/10.jpg",
            },
            {
              imageLink:
              "/assets/T&p/MR/11.jpg",
            },
            {
              imageLink:
              "/assets/T&p/MR/12.jpg",
            },
      ];
  return (
    <motion.div className='md:p-16 p-6 font-change'
    initial={{ y:-40,opacity:0 }}
          animate={{ y:0, opacity:1}}
          transition={{ ease: "easeIn", duration: 1 }}>
    <h1 className='text-4xl  font-bold mb-8 text-center underline underline-offset-8'>Placement Opportunities</h1>
    <br />
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
      {data.map(({ imageLink }, index) => (
        <div key={index}>
          <img
            className=" w-full max-w-full rounded-lg object-cover object-center"
            src={imageLink}
            alt="gallery-photo"
          />
        </div>
      ))}
    </div>
    </motion.div>
  )
}

export default MajorR